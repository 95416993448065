import React, { useState } from 'react';
import { API } from "aws-amplify";
import Fuse from 'fuse.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUserGraduate, faLock, faUnlock, faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import { capitalizeFirstLetters, sortArrayOfObjects, isEmpty } from '../../../js/Helpers';
import { FileUpload } from '../../../js/upload';

export default function SectionBalancing({ schoolSubscribed, schoolInfo, scheduleName, scheduleType, currentDay, sections, subsections, teachers, courses, scheduleStructureData, scheduleInfo, toggleEditSection, pullNewSectionData }) {
	const [filterIDs, setFilterIDs] = useState([]);
	const [balancingInProgress, setBalancingInProgress] = useState(false);
	const [courseBeingBalanced, setCourseBeingBalanced] = useState(null);
	const [balancingStatus, setBalancingStatus] = useState(null);
	const [balancingMessage, setBalancingMessage] = useState(null);
	
	const fuzzySearch = (e) => {
		const search_value = e.target.value;

		const filtered_course_ids = [];
		
		// Search courses by name or code
		const courses_fuse = new Fuse(courses, {
			keys: ['name', 'course_code'],
			threshold: .1
		});

		const course_results = courses_fuse.search(search_value);
		const subset_course_results = course_results.map(result => result.item).slice(0,4);

		subset_course_results.forEach(course => {
			const course_id = course.course_id;

			filtered_course_ids.push(course_id);
		});

		setFilterIDs([...filtered_course_ids]);
	}

	const noSubscriptionAlert = (course_id) => {
		setBalancingStatus('error');
		setBalancingMessage("This feature is only available with a paid subscription. Please contact us at contact@edario.com to gain full access.");
		setCourseBeingBalanced(course_id);
	}

	const balanceCourse = async (course_id) => {
		/*alert("Sorry this feature is currently under maintenance. Please try again later!");*/
		setBalancingInProgress(true);
		setCourseBeingBalanced(course_id);

		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id, courses:[course_id]};
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/balance-course';
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(data),
		};
	
		try {
			await API.post(apiName, url, myInit);

			const fileUpload = new FileUpload(schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, schoolInfo.school_id);
			const running_processes = await fileUpload.getSchoolProcesses(schoolInfo.current_schedule_year_id);

			// Check if there are any running processes with the same data version id
			const process_index = running_processes.findIndex(process => process.process_type === 'balancecourse' && process.schedule_version_id == schoolInfo.current_schedule_version_id);

			// If process is already running, get progress of matches
			const process_info = (process_index !== -1) ? running_processes[process_index] : null;
			const progress_id = (process_info) ? process_info.progress_id : null;

			await fileUpload.checkUploading(schoolInfo.school_id, progress_id, (event) => {
				if(event.done) 
				{
					if(event.failure) 
					{
						setBalancingStatus('error');
						setBalancingMessage('There was an error while balancing. Please try again.');
						setBalancingInProgress(false);
					}
					else 
					{
						pullNewSectionData(schoolInfo.current_schedule_version_id, () => {
							setBalancingStatus('success');
							setBalancingMessage('This course has been balanced as much as possible!');
							setBalancingInProgress(false);
						});
					}
				}
			});
		} catch(error)
		{
			setBalancingStatus('error');
			setBalancingMessage('There was an error while balancing. Please try again.');
			setBalancingInProgress(false);
		}
	}

	const quarters = ['1', '2', '3', '4'];
	let num_days_in_cycle = parseInt(scheduleInfo.num_days_in_cycle);
	let days = [];
	for(var i = 1; i <= num_days_in_cycle; i++)
	{
		days.push(`${i}`);
	}

	const period_type = (scheduleType === 'daily') ? 'schedule_periods' : 'course_periods';
	const periods_to_show = (isEmpty(scheduleStructureData)) ? {} : (period_type === 'course_periods' ? scheduleStructureData.course_periods : scheduleStructureData.schedule_periods['1']);

	let sortable = [];
	for (const period in periods_to_show) {
		const period_info = periods_to_show[period];
		const period_id = period_info.period_id;
		const period_order = (period_type === 'course_periods') ? period_info.period_name : period_info.period_order;
		const period_name = period_info.period_name;

		sortable.push({period_id: period_id, period_order: parseInt(period_order), period_name: period_name});
	}

	const periodsToShow = sortArrayOfObjects(sortable, 'period_order', 'number', 'asc');
	const num_cols = Object.keys(periodsToShow).length;
	const row_style = {gridTemplateColumns :`repeat(${num_cols}, 1fr)`};
	const summary_row_style = {gridTemplateColumns:`100px 1fr`, columnGap:'20px'};
	const period_row_style = {display:'grid', width:'100%', gridTemplateColumns:` 35px repeat(${num_cols}, 1fr)`, textAlign:'center', rowGap:'10px'};

	const summary_stats = Object.keys(periodsToShow).reduce((results, period_id) => {	
		const course_period_id = (scheduleType === 'daily') ? scheduleStructureData.schedule_structure[currentDay].find(day_sp => day_sp.schedule_period_id === period_id).course_period_id : period_id;
		const course_period_sections = sections.filter(section => section.course_periods.includes(course_period_id) && section.is_lab === '0' && subsections.findIndex(subsection => subsection.subsection_id === section.section_id) === -1);

		const num_sections = course_period_sections.length;
		const total_students = course_period_sections.reduce((results, main_section) => {
			const section_subsections = ('subsections' in main_section) ? main_section.subsections : [];
											
			const num_students = (section_subsections.length === 0) ? main_section.student_list.length : section_subsections.reduce((total_students, subsection_id) => {
				const subsection_num_students = sections.find(section => section.section_id === subsection_id).student_list.length;
				return total_students + subsection_num_students;
			}, main_section.student_list.length);
			
			const main_section_quarter_days = main_section.quarter_days;
			let logged_quarters = [];
			main_section_quarter_days.forEach(quarter_day => {
				const quarter = quarter_day.quarter;
				
				if(!logged_quarters.includes(quarter))
				{
					results[quarter] += num_students;
					logged_quarters.push(quarter);
				}
			});
			return results;
		}, {'1':0, '2':0, '3':0, '4':0});

		results[course_period_id] = {'sections': num_sections, 'students': total_students};
		return results;
	}, {});

	return (
		<>
			<h4 className='report-schedule-name'>{scheduleName}</h4>
			<h1 className='report-name'>Section Balancing</h1>
			<div className='student-summary-statistics-container'>
				<div className='dark-blue student-summary-statistics-header'>Section & Student Balance</div>
				<div className='student-summary-statistics-inner-container'>
					<div className='mb-row mb-schedule-row' style={summary_row_style}>
						<div></div>
						<div style={period_row_style}>
							<div></div>
							{Object.keys(periodsToShow).map((period_id, index) => {
								const period_name = periodsToShow[period_id].period_name;
								
								return (
									<div className='mb-inner-box' key={index} style={{textAlign:'center', marginBottom:'10px'}}><div>{period_name}</div></div>
								)
							})}
						</div>
					</div>
					<div className='mb-row mb-schedule-row' style={summary_row_style}>
						<div className='section-balancing-summary-row-box'>Sections</div>
						<div style={period_row_style}>
							<div></div>
							{Object.keys(periodsToShow).map((period_id, index) => {
								const course_period_id = (scheduleType === 'daily') ? scheduleStructureData.schedule_structure[currentDay].find(day_sp => day_sp.schedule_period_id === period_id).course_period_id : period_id;
								const num_sections = summary_stats[course_period_id].sections;

								return (
									<div key={index} className={num_sections === 0 ? 'gray-text' : ''}>{num_sections === 0 ? "--" : num_sections}</div>
								)
							})}
						</div>
					</div>
					<div className='mb-row mb-schedule-row' style={summary_row_style}>
						<div className='section-balancing-summary-row-box'>Students</div>
						<div style={period_row_style}>
							{quarters.map(quarter => {
								return (
									<React.Fragment key={quarter}>
										<div>Q{quarter}</div>
										{Object.keys(periodsToShow).map((period_id, index) => {
											const course_period_id = (scheduleType === 'daily') ? scheduleStructureData.schedule_structure[currentDay].find(day_sp => day_sp.schedule_period_id === period_id).course_period_id : period_id;
											const students_by_quarter = summary_stats[course_period_id].students[quarter];

											return (
												<div key={index} className={students_by_quarter === 0 ? 'gray-text' : ''}>{students_by_quarter === 0 ? "--" : students_by_quarter}</div>
											)
										})}
									</React.Fragment>
								)
							})}
						</div>
					</div>
				</div>
			</div>
			<div className='student-summary-statistics-container'>
				<div className='student-summary-statistics-header green'>Lab Balance</div>
				<div className='student-summary-statistics-inner-container'>
					<div className='mb-row mb-schedule-row' style={summary_row_style}>
						<div></div>
						<div style={period_row_style}>
							<div className='small-text'>Days</div>
							{Object.keys(periodsToShow).map((period_id, index) => {
								const period_name = periodsToShow[period_id].period_name;
								
								return (
									<div className='mb-inner-box' key={index} style={{textAlign:'center', marginBottom:'10px'}}><div>{period_name}</div></div>
								)
							})}
						</div>
					</div>
					<div className='mb-row mb-schedule-row' style={summary_row_style}>
						<div className='section-balancing-summary-row-box'># Labs</div>
						<div style={period_row_style}>
							{days.map(day => {
								return (
									<React.Fragment key={day}>
										<div>{day}</div>
										{Object.keys(periodsToShow).map((period_id, index) => {
											const course_period_id = (scheduleType === 'daily') ? scheduleStructureData.schedule_structure[currentDay].find(day_sp => day_sp.schedule_period_id === period_id).course_period_id : period_id;
											
											const num_labs_during_day = sections.reduce((count, section) => {
												if(!section.course_periods.includes(course_period_id)) return count;
												
												const is_lab = section.is_lab;
												if(is_lab === "0") return count;

												const day_included = (section.quarter_days.findIndex(quarter_day => quarter_day.day === day) !== -1);
												if(!day_included) return count;

												count++;
												return count;
											}, 0);

											return (
												<div key={index} className={num_labs_during_day === 0 ? 'gray-text' : ''}>{num_labs_during_day === 0 ? "--" : num_labs_during_day}</div>
											)
										})}
									</React.Fragment>
								)
							})}
						</div>
					</div>
					<div className='mb-row mb-schedule-row' style={summary_row_style}>
						<div className='section-balancing-summary-row-box'>Students in Lab</div>
						<div style={period_row_style}>
							{days.map(day => {
								return (
									<React.Fragment key={day}>
										<div>{day}</div>
										{Object.keys(periodsToShow).map((period_id, index) => {
											const course_period_id = (scheduleType === 'daily') ? scheduleStructureData.schedule_structure[currentDay].find(day_sp => day_sp.schedule_period_id === period_id).course_period_id : period_id;
											
											const num_students_in_labs_during_day = sections.reduce((count, section) => {
												if(!section.course_periods.includes(course_period_id)) return count;
												
												const is_lab = section.is_lab;
												if(is_lab === "0") return count;

												const day_included = (section.quarter_days.findIndex(quarter_day => quarter_day.day === day) !== -1);
												if(!day_included) return count;

												const num_students = section.student_list.length;
												count += num_students;
												return count;
											}, 0);

											return (
												<div key={index} className={num_students_in_labs_during_day === 0 ? 'gray-text' : ''}>{num_students_in_labs_during_day === 0 ? "--" : num_students_in_labs_during_day}</div>
											)
										})}
									</React.Fragment>
								)
							})}
						</div>
					</div>
				</div>
			</div>
			<h1 className='student-summary-secondary-header'>Balancing by Course</h1>
			<div className='reports-search-bar-container'>
				<input className='reports-search-bar' placeholder={`Search for a course or course code...`} defaultValue='' onChange={(e) => fuzzySearch(e)}/>
				<FontAwesomeIcon className='reports-search-bar-icon' icon={faSearch}/>
			</div>
			<div className='reports-main-container'>
				<div className='reports-main-inner-container'>
					{courses.map((course_info, i) => {
						const course_id = course_info.course_id;
						const course_max_class_size = course_info.max_class_size;

						if(filterIDs.length > 0 && !filterIDs.includes(course_id)) return null;

						const sections_with_course = sections.filter(section => section.course_id === course_id);

						return (
							<div className='conflict-matrix-course-container' key={i}>
								<div className='section-balancing-title-container'>
									<h2>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</h2>
									{/* <div style={{textAlign:'right'}}>
										{!balancingInProgress &&
											<div className='section-balancing-btn btn purple-btn' onClick={!schoolSubscribed ? () => noSubscriptionAlert(course_id) : () => balanceCourse(course_id)}>Balance this Course</div>
										}
										{(!balancingInProgress && courseBeingBalanced === course_id) &&
											<div className={balancingStatus === 'error' ? 'red-text' : 'green-text'} style={{maxWidth:'430px'}}>{balancingMessage}</div>
										}
									</div> */}
								</div>
								<div>Max Class Size: {course_max_class_size}</div>
								{(balancingInProgress && courseBeingBalanced === course_id) ?
									(
										<div className='mb-message-container'>
											<div className='loader'></div>
											<div className='medium-text' style={{marginTop:'5px'}}>Balancing {capitalizeFirstLetters(course_info.name)}...</div>
										</div>
									) :
									(
										<div className='mb-row mb-schedule-row' style={row_style} key={i}>
											{Object.keys(periodsToShow).map((period_id, index) => {
												const period_name = periodsToShow[period_id].period_name;
												
												return (
													<div className='mb-inner-box' key={index} style={{textAlign:'center'}}><div>{period_name}</div></div>
												)
											})}
											{Object.keys(periodsToShow).map((period_id, index) => {
													const course_period_id = (scheduleType === 'daily') ? scheduleStructureData.schedule_structure[currentDay].find(day_sp => day_sp.schedule_period_id === period_id).course_period_id : period_id;
													const course_period_sections = sections_with_course.filter(section => section.course_periods.includes(course_period_id));

													const course_period_sections_sorted_by_quarter = course_period_sections.sort((section_A, section_B) => {
														const section_A_min_quarter = section_A.quarter_days.reduce((result, quarter_day) => {
															if(quarter_day.quarter < result) result = quarter_day.quarter;
															return result;
														}, '999');

														const section_B_min_quarter = section_B.quarter_days.reduce((result, quarter_day) => {
															if(quarter_day.quarter < result) result = quarter_day.quarter;
															return result;
														}, '999');

														if(section_A_min_quarter < section_B_min_quarter) return -1;
														return 1;
													});

													return (
														<div className='mb-inner-box mb-schedule-inner-box' key={index}>
															{course_period_sections_sorted_by_quarter.map((section_info, index) => {
																let section_id = section_info.section_id;

																// If section is subsection, get main section info
																const section_with_subsection_index = subsections.findIndex(subsection_section => subsection_section.subsection_id === section_id);
																if(section_with_subsection_index !== -1) return null;
																
																const section_type = section_info.section_type;
																const course_id = section_info.course_id;
																const teacher_id = section_info.teacher_id;
																const secondary_teachers = section_info.secondary_teachers;
																const classroom_id = section_info.classroom_id;
																const data_id = (teacher_id) ? teacher_id : classroom_id;
																const num_students = section_info.student_list.length;
																const is_lab = section_info.is_lab;
																const is_locked = section_info.locked;

																const quarter_days = section_info.quarter_days;
																const quarter_1_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '1') !== -1) ? true : false;
																const quarter_2_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '2') !== -1) ? true : false;
																const quarter_3_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '3') !== -1) ? true : false;
																const quarter_4_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '4') !== -1) ? true : false;

																const course_info = courses.find(course => course.course_id === course_id);
																const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
																const teacher_full_name = (teacher_info) ? `${teacher_info.name}, ${teacher_info.first_name}` : null;
																
																const section_color = (is_lab === '1') ? 'green' : 'dark-blue';
																
																// Get num students from all subsections too
																const section_subsections = ('subsections' in section_info) ? section_info.subsections : [];

																const total_num_students = (section_subsections.length === 0) ? num_students : section_subsections.reduce((total_students, subsection_id) => {
																	const subsection_num_students = sections.find(section => section.section_id === subsection_id).student_list.length;
																	return total_students + subsection_num_students;
																}, num_students);
																
																return (
																	<div className='mb-section section-balancing-section' key={`${index}-${section_id}`}>
																		<div className={`mb-section-top-bar ${section_color}`} style={{padding:'1px 5px 2px 5px'}}>
																			<div>{is_lab === '1' ? "Lab" : (is_locked === '1') ? <FontAwesomeIcon className='bright-yellow-text' icon={faLock}/> : <FontAwesomeIcon className='opaque' icon={faUnlock}/>}</div>
																			{is_locked === '0' ?
																				(
																					<div className='mb-section-more-info' onClick={() => toggleEditSection({section_id:section_id, data_id:data_id, section_type:section_type})}><FontAwesomeIcon icon={faEllipsisH}/></div>
																				):
																				(
																					<div></div>
																				)
																				
																			}
																		</div>
																		<div className='mb-section-content'>
																			<div className='section-balancing-subsection-container'>
																				<div className='tooltip' data-tooltip-text={`${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})`}>
																					<div className='mb-section-name ellipsis'>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
																				</div>
																				<div className='tooltip' data-tooltip-text={teacher_full_name}>
																					<div className='mb-section-name ellipsis dark-gray-text'>{teacher_full_name}</div>
																				</div>
																				{secondary_teachers.map((secondary_teacher_id, i) => {
																					const secondary_teacher_info = teachers.find(teacher => teacher.teacher_id === secondary_teacher_id);
																					const secondary_teacher_full_name = (secondary_teacher_info) ? `${secondary_teacher_info.name}, ${secondary_teacher_info.first_name}` : null;

																					return (
																						<div className='tooltip' data-tooltip-text={secondary_teacher_full_name} key={`secondary-teacher-${i}`}>
																							<div className='mb-section-name ellipsis dark-gray-text'>{secondary_teacher_full_name}</div>
																						</div>
																					)
																				})}
																				<div className='mb-section-quarter-day-container'>
																					<div className={`mb-section-quarter-day ${quarter_1_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q1</div>
																					<div className={`mb-section-quarter-day ${quarter_2_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q2</div>
																					<div className={`mb-section-quarter-day ${quarter_3_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q3</div>
																					<div className={`mb-section-quarter-day ${quarter_4_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q4</div>
																				</div>
																				<div className='mb-section-subname-container'>
																					<div className='mb-section-subname'></div>
																					<div className='mb-section-students'><FontAwesomeIcon icon={faUserGraduate}/> {num_students}</div>
																				</div>
																			</div>
																			{section_subsections.map(subsection_id => {
																				const subsection_section_info = sections.find(section => section.section_id === subsection_id);
																				const subsection_course_id = subsection_section_info.course_id;
																				const subsection_teacher_id = subsection_section_info.teacher_id;
																				const subsection_num_students = subsection_section_info.student_list.length;
																				const subsection_secondary_teachers = subsection_section_info.secondary_teachers;

																				const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
																				const subsection_teacher_info = teachers.find(teacher => teacher.teacher_id === subsection_teacher_id);
																				
																				const subsection_teacher_full_name = (subsection_teacher_id) ? `${subsection_teacher_info.name}, ${subsection_teacher_info.first_name}` : null;

																				// Check if subsection is placeholder inclusion course (for schools without separate ICR codes)
																				const subsection_is_inclusion = subsection_section_info.is_inclusion;
																				let is_placeholder_inclusion = false;
																				if(scheduleInfo.separate_ICR_codes === '0' && course_id === subsection_course_id && subsection_is_inclusion === '1') is_placeholder_inclusion = true;

																				return (
																					<div className='section-balancing-subsection-container' key={subsection_id}>
																						{is_placeholder_inclusion ?
																							(
																								<div className='orange-text'>Inclusion</div>
																							):
																							(
																								<div className='tooltip' data-tooltip-text={subsection_course_info && `${capitalizeFirstLetters(subsection_course_info.name)} (${subsection_course_info.course_code})`}	>
																									<div className='mb-section-name ellipsis blue-text'>
																										{subsection_course_info &&
																											<span>{capitalizeFirstLetters(subsection_course_info.name)} ({subsection_course_info.course_code})</span>
																										}
																									</div>
																								</div>
																							)
																						}
																						<div className='tooltip' data-tooltip-text={subsection_teacher_full_name}>
																							<div className='mb-section-name ellipsis dark-gray-text'>{subsection_teacher_full_name}</div>
																						</div>
																						{subsection_secondary_teachers.map((secondary_teacher_id, i) => {
																							const secondary_teacher_info = teachers.find(teacher => teacher.teacher_id === secondary_teacher_id);
																							const secondary_teacher_full_name = (secondary_teacher_info) ? `${secondary_teacher_info.name}, ${secondary_teacher_info.first_name}` : null;

																							return (
																								<div className='tooltip' data-tooltip-text={secondary_teacher_full_name} key={`subsection-secondary-teacher-${i}`}>
																									<div className='mb-section-name ellipsis dark-gray-text'>{secondary_teacher_full_name}</div>
																								</div>
																							)
																						})}
																						<div className='mb-section-subname-container'>
																							<div className='mb-section-subname'></div>
																							<div className='mb-section-students'><FontAwesomeIcon icon={faUserGraduate}/> {subsection_num_students}</div>
																						</div>
																					</div>
																				)
																			})}
																			<div className='section-balancing-total-students'>Total Students: {total_num_students}</div>
																		</div>
																	</div>
																)
															})}
														</div>
													)
												})}
										</div>
									)
								}
							</div>
						)
					})}
				</div>
			</div>
		</>
	);
}