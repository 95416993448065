import React, { useState , useEffect } from 'react';
import { API } from "aws-amplify";
import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import Dropdown from '../../Dropdown';

import { isEmpty, capitalizeFirstLetters, sortArrayOfObjects, cloneObj, recalculateMatchPercent, calculateConflicts, timesAreOverlapping } from '../../../js/Helpers';

import '../../../css/hsms/edit_modals.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit, faPlusCircle, faTimes, faChalkboard, faUserGraduate, faCheck, faChevronLeft, faChevronRight, faLock, faAppleAlt, faBook, faCar, faDog, faEgg, faFish, faGuitar, faHorseHead, faIgloo, faFighterJet, faKey, faBolt, faMotorcycle, faPizzaSlice, faChessQueen, faRocket, faHandScissors, faTree, faUmbrella, faVolleyballBall, faWater, faFireExtinguisher, faYinYang, faMusic, faGlobeAmericas, faRadiation, faBroom, faUnlock, } from '@fortawesome/free-solid-svg-icons';
import { faSave, faHandPointer, faCalendar } from '@fortawesome/free-regular-svg-icons';

export default function EditStudentSchedule({ schoolInfo, scheduleType, currentViewType, currentDay, studentID, setStudentID, toggleEditStudentSchedule, scheduleInfo, scheduleStructureData, lunchInfo, classrooms, courses, teachers, students, setStudents, sections, setSections, subsections, labels, viewTypeOptions, dayOptions, conflicts, setConflicts, setMatchPercent }) {
	const icons = {'A': faAppleAlt, 'B': faBook, 'C': faCar, 'D': faDog, 'E': faEgg, 'F': faFish, 'G': faGuitar, 'H': faHorseHead, 'I': faIgloo, 'J': faFighterJet, 'K': faKey, 'L': faBolt, 'M': faMotorcycle, 'N': faMusic, 'O': faGlobeAmericas, 'P': faPizzaSlice, 'Q': faChessQueen, 'R': faRocket, 'S': faHandScissors, 'T': faTree, 'U': faUmbrella, 'V': faVolleyballBall, 'W': faWater, 'X': faFireExtinguisher, 'Y': faYinYang, 'Z': faRadiation};

	const [isLoading, setIsLoading] = useState(true);

	const [currentStudent, setCurrentStudent] = useState(null);
	const [previousStudentID, setPreviousStudentID] = useState(null);
	const [nextStudentID, setNextStudentID] = useState(null);

	const [studentSections, setStudentSections] = useState([]);
	const [changedSections, setChangedSections] = useState({added_sections:[], deleted_sections:[]});
	const [studentRequests, setStudentRequests] = useState([]);
	const [highlightedRequests, setHighlightedRequest] = useState([]);

	const [studentCurrentViewType, setStudentCurrentViewType] = useState(currentViewType);
	const [studentCurrentDay, setStudentCurrentDay] = useState(currentDay);
	const [currentCoursePeriodID, setCurrentCoursePeriodID] = useState(null);

	const [addNewRequestOpen, setAddNewRequestOpen] = useState(false);
	const [addNewAlternateOpen, setAddNewAlternateOpen] = useState(false);

	const [newCourseRequestResults, setNewCourseRequestResults] = useState([]);
	const [newAlternateResults, setNewAlternateResults] = useState([]);
	
	const [changesMade, setChangesMade] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	
	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const handleExitWithoutSaving = async () => {
		let exit_without_saving = true;
		
		if(changesMade)
		{
			const options =  {
				title: "Exit without saving?",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes, exit without saving",
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you really want to exit without saving?</p>
					</div>
				)
			}
		
			exit_without_saving = await swal(options);
		}
		
		if(exit_without_saving) toggleEditStudentSchedule(null);
	}

	const handleMoveToNewStudent = async (next_prev) => {
		let save_before_exiting = false;
		
		if(changesMade)
		{
			const options =  {
				title: `Move to ${next_prev} student without saving?`,
				dangerMode: true,
				buttons: {
					cancel: {
						text: `Move to ${next_prev} student without saving`,
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: `Save, then move to ${next_prev} student`,
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you want to move to {next_prev} student without saving?</p>
					</div>
				)
			}
		
			save_before_exiting = await swal(options);
		}
		
		// If user wants to save, do that first
		if(save_before_exiting) await saveStudentSchedule(false);
		
		// Change the student ID
		if(next_prev === 'next')
		{
			setStudentID(nextStudentID);
		}
		else
		{
			setStudentID(previousStudentID);
		}
	}

	const toggleRequestHighlight = (request_course_id) => {
		const highlight_index = highlightedRequests.findIndex(course_id => course_id === request_course_id);

		if(highlight_index !== -1)
		{
			highlightedRequests.splice(highlight_index, 1);
		}
		else
		{
			highlightedRequests.push(request_course_id);
		}

		setHighlightedRequest([...highlightedRequests]);
	}

	const handleHoverOverHighlight = (e, hover_type, request_course_id) => {
		if(hover_type === 'mouse-in')
		{
			const request_info = studentRequests.find(request => request.course_id === request_course_id);
			const request_color = request_info.color;

			e.target.closest('.request-icon').classList.add(request_color);
		}
		else if(hover_type === 'mouse-out')
		{
			e.target.closest('.request-icon').className = 'request-icon';
		}
	}

	const changeStudentCurrentViewType = (new_view_type) => {
		const new_current_day = (new_view_type === 'day') ? '1' : null;
		setStudentCurrentDay(new_current_day);
		setStudentCurrentViewType(new_view_type);
	}
	
	const changeStudentCurrentDay = (new_day) => {
		setStudentCurrentDay(new_day);
	}

	const handleNewCourse = (course_id, selected_option) => {
		swal.close();

		if(selected_option)
		{
			const priority = (selected_option === 'alternate') ? '2' : '1';
			studentRequests.push({request_id: 'new', course_id:course_id, priority:priority, matched:'1', data_origin:'2'});
			setStudentRequests([...studentRequests]);
		}
	}

	const lockAllSections = () => {
		const copyStudentSections = JSON.parse(JSON.stringify(studentSections));
		for(const student_section of copyStudentSections)
		{
			const student_section_id = student_section.section_id;
			toggleLockSection(student_section_id, '1');
		}
	}

	const unlockAllSections = () => {
		const copyStudentSections = JSON.parse(JSON.stringify(studentSections));
		for(const student_section of copyStudentSections)
		{
			const student_section_id = student_section.section_id;
			toggleLockSection(student_section_id, '0');
		}
	}

	const toggleLockSection = (section_id, lock_value = null) => {
		const section_info = sections.find(section => section.section_id === section_id);
		if(!section_info) return;

		const section_span_id = section_info.span_id;
		const affected_sections = (section_span_id) ? studentSections.reduce((results, section) => {
			const temp_section_id = section.section_id;
			const temp_span_id = section.span_id;
			if(temp_span_id === section_span_id) results.push(temp_section_id);
			return results;
		}, []) : [section_id];

		affected_sections.forEach(affected_section_id => {
			const locked_index = currentStudent.student_locked_sections.findIndex(section => section === affected_section_id);

			if(locked_index !== -1 && lock_value !== '1')
			{
				currentStudent.student_locked_sections.splice(locked_index, 1);
			}
			else if(locked_index === -1 && lock_value !== '0')
			{
				currentStudent.student_locked_sections.push(affected_section_id);
			}
		});

		setCurrentStudent({...currentStudent});
		setChangesMade(true);
	}

	const clearAllSections = () => {
		const copyStudentSections = JSON.parse(JSON.stringify(studentSections));
		for(const student_section of copyStudentSections)
		{
			const student_section_id = student_section.section_id;
			const section_is_locked = currentStudent.student_locked_sections.findIndex(section => section === student_section_id) !== -1;
			if(section_is_locked) continue;

			removeSectionFromSchedule(student_section_id);
		}
	}

	const addSectionToSchedule = async (section_id) => {
		const student_section_index = studentSections.findIndex(section => section.section_id === section_id);
		
		if(student_section_index === -1)
		{
			const section_info = sections.find(section => section.section_id === section_id);
			const section_course_id = section_info.course_id;
			const section_span_id = section_info.span_id;
			const section_is_subsection = (subsections.findIndex(subsection => subsection.subsection_id === section_id) !== -1) ? true : false;

			// IS CLASS OVERFILLED ////
			let allow_overfill = true;

			// Check passed section
			const section_course_info = (section_course_id) ? courses.find(course => course.course_id === section_course_id) : null;
			const section_max_class_size = (section_course_info) ? parseInt(section_course_info.max_class_size) : 0;
			const section_num_students = (section_info) ? section_info.student_list.length : 0;

			// Check overall section
			const main_section_id = (section_is_subsection) ? subsections.find(subsection => subsection.subsection_id === section_id).section_id : section_id;
			const main_section_info = (main_section_id === section_id) ? section_info : sections.find(section => section.section_id === main_section_id);
			const main_section_subsections = (main_section_info) ? main_section_info.subsections : [];
			const main_section_course_id = (main_section_info) ? main_section_info.course_id : null;
			const main_section_course_info = (main_section_course_id) ? courses.find(course => course.course_id === main_section_course_id) : null;
			const total_max_class_size = (main_section_course_info) ? parseInt(main_section_course_info.max_class_size) : 0;

			let total_num_students = (main_section_subsections.length === 0) ? section_info.student_list.length : main_section_subsections.reduce((total_students, subsection_id) => {
				const subsection_num_students = sections.find(section => section.section_id === subsection_id).student_list.length;
				return total_students + subsection_num_students;
			}, main_section_info.student_list.length);

			if(section_max_class_size === section_num_students || total_max_class_size === total_num_students)
			{
				const options =  {
					title: "Too many students in this section already!",
					dangerMode: true,
					buttons: {
						cancel: {
							text: "Cancel",
							value: false,
							visible: true,
							className: 'gray-btn'
						},confirm: {
							text: "Yes, Overfill",
							value: true,
							visible: true,
							className: 'red-btn'
						},
					},
					content: (
						<div>
							<p>This section is already at full capacity. Are you sure you want to overfill the section?</p>
						</div>
					)
				}
			
				allow_overfill = await swal(options);
			}

			if(!allow_overfill) return;

			// See if course is included in requests 
			// If not, add it
			const request_index = studentRequests.findIndex(request_info => request_info.course_id === section_course_id);
			if(request_index === -1)
			{
				const course_info = courses.find(course => course.course_id === section_course_id);
				const course_name = course_info.name;
				const course_code = course_info.course_code;

				const options =  {
					title: "Add New Course as Request?",
					dangerMode: false,
					className: 'swal-wide',
					button: false,
					content: (
						<div style={{margin:'20px', textAlign:'left'}}>
							<div>You are adding a section of <span className='highlight'>{course_name} ({course_code})</span> into this student's schedule, but it is not currently in their course request list. What would you like to do with this new course?</div>
							<div className='add-course-as-request-option-container'>
								<div className='add-course-as-request-option'>Option 1</div>
								<div className='add-course-as-request-header'>Add as request</div>
								<div className='add-course-as-request-text'>{course_name} ({course_code}) will be treated like a primary course request for this student in any future scheduling runs</div>
								<div className="btn blue-btn add-course-as-request-btn" onClick={() => handleNewCourse(section_course_id, 'request')}>Add as Request</div>
							</div>
							<div className='add-course-as-request-option-container'>
								<div className='add-course-as-request-option'>Option 2</div>
								<div className='add-course-as-request-header'>Add as an alternate</div>
								<div className='add-course-as-request-text'>{course_name} ({course_code}) will be treated like an alternate course request for this student in any future scheduling runs</div>
								<div className="btn purple-btn add-course-as-request-btn" onClick={() => handleNewCourse(section_course_id, 'alternate')}>Add as Alternate</div>
							</div>
							<div className='add-course-as-request-option-container'>
								<div className='add-course-as-request-option'>Option 3 (not recommended)</div>
								<div className='add-course-as-request-header'>Add section, but don't add course request</div>
								<div className='add-course-as-request-text'>A section of {course_name} ({course_code}) will be added into the student's schedule, but the request won't be saved. This course will not be added to this student's schedule in future scheduling runs.</div>
								<div className='red-text' style={{margin:'15px 0px 0px 0px'}}><strong>Warning</strong>: this course will never show up in this student's requests, so when looking at their schedule it may be hard to understand why it's even in their schedule</div>
								<div className="btn gray-btn add-course-as-request-btn" onClick={() => handleNewCourse(section_course_id, false)}>Don't Add as Request</div>
							</div>
						</div>
					)
				}
			
				swal(options);
			}

			// Remove any other sections with this course from student schedule
			studentSections.forEach(section => {
				const other_section_id = section.section_id;
				const other_section_course_id = section.course_id;
				const other_section_is_locked = currentStudent.student_locked_sections.includes(other_section_id);

				if(other_section_course_id === section_course_id && !other_section_is_locked) removeSectionFromSchedule(section.section_id);
			});

			// Add this section AND any spanned sections to student section list
			let affected_sections = [section_info];
			if(section_span_id)
			{
				const spanned_sections = sections.filter(section => section.span_id === section_span_id && section.section_id !== section_id);
				spanned_sections.forEach(spanned_section => {
					const spanned_section_id = spanned_section.section_id;
					const spanned_section_course_id = spanned_section.course_id;
					const spanned_section_is_subsection = (subsections.findIndex(subsection => subsection.subsection_id === spanned_section_id) !== -1) ? true : false;

					if(section_course_id === spanned_section_course_id && section_is_subsection === spanned_section_is_subsection) affected_sections.push(spanned_section);
				});
			}

			affected_sections.forEach(affected_section_info => {
				const affected_section_id = affected_section_info.section_id;

				// Check if section was deleted during this session
				const deleted_index = changedSections.deleted_sections.findIndex(deleted_section => deleted_section === section_id);
				if(deleted_index !== -1)
				{
					changedSections.deleted_sections.splice(deleted_index, 1);
				}
				else
				{
					changedSections.added_sections.push(affected_section_id);
				}

				studentSections.push(affected_section_info);
			});

			setStudentRequests([...studentRequests]);
			setStudentSections([...studentSections]);
			setChangedSections({...changedSections});
			setChangesMade(true);
		}
	}

	const removeSectionFromSchedule = (section_id) => {
		const student_section_index = studentSections.findIndex(section => section.section_id === section_id);
		
		if(student_section_index !== -1)
		{
			const section_info = studentSections[student_section_index];
			const section_course_id = section_info.course_id;
			const section_span_id = section_info.span_id;
			const section_is_subsection = (subsections.findIndex(subsection => subsection.subsection_id === section_id) !== -1) ? true : false;

			// Remove section AND any spanned sections from student section list
			let affected_sections = [section_info];
			if(section_span_id)
			{
				const spanned_sections = sections.filter(section => section.span_id === section_span_id && section.section_id !== section_id);
				spanned_sections.forEach(spanned_section => {
					const spanned_section_id = spanned_section.section_id;
					const spanned_section_course_id = spanned_section.course_id;
					const spanned_section_is_subsection = (subsections.findIndex(subsection => subsection.subsection_id === spanned_section_id) !== -1) ? true : false;

					if(section_course_id === spanned_section_course_id && section_is_subsection === spanned_section_is_subsection) affected_sections.push(spanned_section);
				});
			}

			affected_sections.forEach(affected_section_info => {
				const affected_section_id = affected_section_info.section_id;
				const affected_student_section_index = studentSections.findIndex(section => section.section_id === affected_section_id);

				// Check if this section was added during this session
				const added_index = changedSections.added_sections.findIndex(added_section => added_section === affected_section_id);
				if(added_index !== -1)
				{
					changedSections.added_sections.splice(added_index, 1);
				}
				else
				{
					changedSections.deleted_sections.push(affected_section_id);
				}
				
				if(affected_student_section_index !== -1) studentSections.splice(affected_student_section_index, 1);
			});

			setStudentRequests([...studentRequests]);
			setStudentSections([...studentSections]);
			setChangedSections({...changedSections});
			setChangesMade(true);
		}
	}

	const addLegendCourseToSchedule = (course_period_id, course_id) => {
		let min_num_students = 999999;
		const section_of_course_with_fewest_students = sections.reduce((result, main_section) => {
			const section_course_id = main_section.course_id;
			const section_course_periods = main_section.course_periods;

			if(section_course_id !== course_id || !section_course_periods.includes(course_period_id)) return result;

			const section_subsections = main_section.subsections;
			const num_students_in_section = (section_subsections.length === 0) ? main_section.student_list.length : section_subsections.reduce((total_students, subsection_id) => {
				const subsection_num_students = sections.find(section => section.section_id === subsection_id).student_list.length;
				return total_students + subsection_num_students;
			}, main_section.student_list.length);

			if(num_students_in_section < min_num_students) result = main_section.section_id;

			return result;
		}, null);

		addSectionToSchedule(section_of_course_with_fewest_students);
	}

	const fuzzySearch = (e, dataType, alternate_bool) => {
		const search_value = e.target.value;
		
		if(dataType === 'courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const course_results = results.map(result => result.item);
			
			if(alternate_bool)
			{
				setNewAlternateResults(course_results);
			}
			else
			{
				setNewCourseRequestResults(course_results);
			}
		}
	}

	const addNewRequest = (course_id, priority) => {
		const request_index = studentRequests.findIndex(request_info => request_info.course_id === course_id);
		
		if(request_index === -1)
		{
			studentRequests.push({request_id: 'new', course_id:course_id, priority:priority, data_origin:'2'});
		}
		else
		{
			studentRequests[request_index].is_deleted = '0';
			studentRequests[request_index].priority = priority;
		}

		if(priority === '1') 
		{
			setNewCourseRequestResults([]);
			setAddNewRequestOpen(false);
		}
		else
		{
			setNewAlternateResults([]);
			setAddNewAlternateOpen(false);
		}

		setStudentRequests([...studentRequests]);
	}

	const removeRequest = (course_id, permanently_remove) => {
		// Remove from student requests
		const request_index = studentRequests.findIndex(request_info => request_info.course_id === course_id);
		if(request_index !== -1) 
		{
			if(permanently_remove) 
			{
				studentRequests.splice(request_index, 1);
			}
			else
			{
				studentRequests[request_index].is_deleted = '1';
			}
		}
		setStudentRequests([...studentRequests]);

		// Remove from student sections
		const student_section_index = studentSections.findIndex(section_info => section_info.course_id === course_id);
		if(student_section_index !== -1)
		{
			const student_section = studentSections[student_section_index];
			const student_section_id = student_section.section_id;

			removeSectionFromSchedule(student_section_id);
		}

		setChangesMade(true);
	}

	const saveStudentSchedule = async (close_save_screen) => {
		setIsSaving(true);

		// Update whether request is matched
		const student_requests = studentRequests.map(student_request => {
			const request_course_id = student_request.course_id;
			const match_index = studentSections.findIndex(section => section.course_id === request_course_id);
			const matched = (match_index !== -1) ? '1' : '0';

			student_request.matched = matched;

			return student_request;
		});

		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, student_id:studentID, changed_sections:changedSections, student_requests:student_requests, student_locked_sections: currentStudent.student_locked_sections};

		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/save-student-schedule';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };

		try {
			// SAVE STUDENT SCHEDULE
			await API.post(apiName, url, myInit);

			// Update student info
			const student_info = students.find(student => student.student_id === studentID);
			student_info.student_requests = student_requests;
			student_info.lunch_schedule_period_id = currentStudent.lunch_schedule_period_id;
			student_info.student_locked_sections = currentStudent.student_locked_sections;
			
			// Remove student from sections
			changedSections.deleted_sections.forEach(section_id => {
				//remove from student list
				const section_info = sections.find(section => section.section_id === section_id);
				const student_list = section_info.student_list;

				const student_index = student_list.findIndex(student => student === studentID);
				if(student_index !== -1) section_info.student_list.splice(student_index, 1);
			});

			// Add student to sections
			changedSections.added_sections.forEach(section_id => {
				//add to student list
				const section_info = sections.find(section => section.section_id === section_id);
				const student_list = section_info.student_list;

				const student_index = student_list.findIndex(student => student === studentID);
				if(student_index === -1) section_info.student_list.push(studentID);
			});

			// Update state
			setSections([...sections]);
			setStudents([...students]);
			setIsSaving(false);

			// Update conflicts
			const conflicts_to_check = ['student_double_booked', 'student_conflicting_periods', 'student_student_restriction', 'teacher_student_restriction', 'course_exceeds_capacity', 'inclusion_too_many_students', 'inclusion_over_half'];
			const affected_sections = [...changedSections.added_sections, ...changedSections.deleted_sections];
			calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:affected_sections, conflicts_to_check:conflicts_to_check, update_all:false}).then(conflicts_response => {
				const added_conflicts = conflicts_response.added_conflicts;
				const deleted_conflicts = conflicts_response.deleted_conflicts;
	
				// Remove any deleted conflicts, add any added conflicts
				let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
				new_conflicts = [...new_conflicts, ...added_conflicts];
				setConflicts([...new_conflicts]);
			});

			// Recalcuate match percent
			recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}).then(match_percent => {
				setMatchPercent(match_percent);
			});

			if(close_save_screen) toggleEditStudentSchedule(null);
		} catch(e)
		{
			console.log(e.response);
		}
	}

	//////////////////////////////
	///// DO ON STATE CHANGE /////
	//////////////////////////////
	useEffect(() => {
		if(!studentID) return;

		// Set student info
		const student_index = students.findIndex(student => student.student_id === studentID);
		const next_student_index = ((student_index + 1) < students.length) ? student_index + 1 : null;
		const previous_student_index = (student_index === 0) ? null : student_index - 1;

		const next_student_id = (next_student_index in students) ? students[next_student_index].student_id : null;
		const previous_student_id = (previous_student_index in students) ? students[previous_student_index].student_id : null;

		const student_info = cloneObj(students[student_index]);
		setCurrentStudent({...student_info});

		// Set current student sections
		const student_course_sections = sections.filter(section => section.course_periods.length > 0 && section.student_list.includes(studentID));
		setStudentSections(student_course_sections);

		// Set student requests
		setStudentRequests([...student_info.student_requests]);

		setNextStudentID(next_student_id);
		setPreviousStudentID(previous_student_id);
		setChangedSections({added_sections:[], deleted_sections:[]});
		setChangesMade(false);
		setIsLoading(false);
	}, [studentID, students, sections]);

	useEffect(() => {
		const colors = ['bright-red-text', 'orange-text', 'bright-yellow-text', 'bright-green-text', 'blue-text', 'pastel-dark-purple-text', 'dark-brown-text', 'yellow-text', 'turquoise-text', 'green-text', 'dark_blue-text', 'dark-blue-grey-text', 'purple-text','pastel-light-green-text', 'pastel-light-turquoise-text', 'pastel-light-orange-text', 'bright-blue-text',  'pastel-turquoise-text', 'pastel-light-blue-text', 'pastel-light-purple-text', 'pastel-light-yellow-text', 'pastel-green-text', 'pastel-yellow-text', 'pastel-orange-text', 'gray-text', 'bright-purple-text', 'pastel-blue-text', 'pastel-brown-text', 'dark-gray-text'];
		
		const every_request_has_an_icon = studentRequests.reduce((results, student_request) => {
			if(! ('request_icon' in student_request)) results = false;
			return results;
		}, true);

		// Skip if there are no requests or if every request already has an icon
		if(studentRequests.length === 0 || every_request_has_an_icon) return;

		// Collect all current icons
		let all_request_icons = studentRequests.reduce((temp_results, temp_request) => {
			if('request_icon' in temp_request) temp_results.push(temp_request.request_icon);
			return temp_results;
		}, []);

		// Get the max alphabet letter for icon
		let max_request_icon = (all_request_icons.length === 0) ? null : all_request_icons.sort().pop();

		let index = 0;
		const new_student_requests = studentRequests.reduce((results, student_request) => {
			if(!('request_icon' in student_request))
			{

				// Get the next letter of the alphabet
				const new_request_icon = (!max_request_icon) ? 'A' : String.fromCharCode(max_request_icon.charCodeAt(max_request_icon.length - 1) + 1);

				// Set the new max as the one just calculated
				max_request_icon = new_request_icon;
				
				const new_color = colors[index];
				
				student_request.request_icon = new_request_icon;
				student_request.color = new_color;
			}
			
			results.push(student_request);
			index++;

			return results;
		}, []);

		setStudentRequests([...new_student_requests]);
	}, [studentRequests]);

	const period_type = (scheduleType === 'daily' || studentCurrentViewType === 'day') ? 'schedule_periods' : 'course_periods';
	const periods_to_show = (isEmpty(scheduleStructureData)) ? {} : (period_type === 'course_periods' ? scheduleStructureData.course_periods : scheduleStructureData.schedule_periods);

	let sortable = [];
	for (const period in periods_to_show) {
		const period_info = periods_to_show[period];
		const period_id = period_info.period_id;
		const period_order = (period_type === 'course_periods') ? period_info.period_name : period_info.period_order;
		const period_name = period_info.period_name;

		sortable.push({period_id: period_id, period_order: parseInt(period_order), period_name: period_name});
	}

	const periodsToShow = sortArrayOfObjects(sortable, 'period_order', 'number', 'asc');

	const num_cols = periodsToShow.length;
	const row_style = {gridTemplateColumns : `repeat(${num_cols}, 1fr)`, padding:'0px', margin:'0px 0px 10px 0px'};

	///////////////////////////////////
	//// Calculcate Student Match /////
	///////////////////////////////////
	// Calculate total quarters
	let total_quarters = 0;
	for(const day in scheduleStructureData.schedule_structure)
	{
		const logged_schedule_periods = [];

		// Get one lunch and base off total quarters off this lunch
		const lunches = lunchInfo.lunches;
		const first_lunch_info = Object.keys(lunches).reduce((result, lunch_id) => {
			if(result) return result;
			const lunch_info = lunches[lunch_id];
			const lunch_days = lunch_info.days;
			if(lunch_days.includes(day)) result = lunch_info;
			return result;
		}, null);
		const lunch_start_time = first_lunch_info.start_time;
		const lunch_end_time = first_lunch_info.end_time;

		// Go through day schedule periods and build the lunch path
		const day_periods = scheduleStructureData.schedule_structure[day];
		let lunch_logged = false;
		let previous_start_time = null;
		let previous_end_time = null;
		for(const day_period_info of day_periods)
		{
			const schedule_period_id = day_period_info.schedule_period_id;
			const schedule_period_info = scheduleStructureData.schedule_periods[schedule_period_id];
			const include_in_match = schedule_period_info.include_in_match === '1';

			if(!include_in_match) continue;
			if(logged_schedule_periods.includes(schedule_period_id)) continue;

			// Get this schedule period's start and end time
			 const schedule_period_start_time = schedule_period_info.start_time;
			 const schedule_period_end_time = schedule_period_info.end_time;

			 // If schedule period overlaps with lunch, and lunch has not been logged yet, count the lunch period
			 // Otherwise, if nothing has been logged yet, then this is the first schedule period, so count it
			 // Otherwise, if this schedule period overlaps with the previously logged start/end times, then skip it
			if(!lunch_logged && timesAreOverlapping(lunch_start_time, lunch_end_time, schedule_period_start_time, schedule_period_end_time))
			{
				logged_schedule_periods.push(schedule_period_id);
				total_quarters += 4;
				
				lunch_logged = true;
				previous_start_time = lunch_start_time;
				previous_end_time = lunch_end_time;
			}
			else if(logged_schedule_periods.length === 0) 
			{
				logged_schedule_periods.push(schedule_period_id);
				total_quarters += 4;

				previous_start_time = schedule_period_start_time;
				previous_end_time = schedule_period_end_time;
			}
			else
			{
				if(timesAreOverlapping(schedule_period_start_time, schedule_period_end_time, previous_start_time, previous_end_time)) continue;

				logged_schedule_periods.push(schedule_period_id);
				total_quarters += 4;
			}
		}
	}

	const num_student_requests = studentRequests.length;
	const matched_student_requests = studentRequests.reduce((results, request_info) => {
		const request_course_id = request_info.course_id;
		const section_match_index = sections.findIndex(section => section.course_periods.length > 0 && section.course_id === request_course_id && section.student_list.includes(studentID));
		const course_info = courses.find(course => course.course_id === request_course_id);
		const course_credits = parseFloat(course_info.credits);

		if(section_match_index !== -1) 
		{
			results.matched_requests++;
			results.matched_credits += course_credits;
		}

		results.total_credits += course_credits;

		return results;
	}, {'matched_requests':0, 'matched_credits':0, 'total_credits':0});

	// Calculate percent schedule full
	const schedule_match_info = Object.keys(scheduleStructureData.schedule_structure).reduce((results, day) => {
		const day_periods = scheduleStructureData.schedule_structure[day];
		
		for(const schedule_period in day_periods)
		{
			const schedule_period_id = schedule_period.schedule_period_id;
			const schedule_period_info = scheduleStructureData.schedule_periods[schedule_period_id];
			const include_in_match = (schedule_period_info) ? schedule_period_info['include_in_match'] === '1' : true;
			if(!include_in_match) continue;

			const course_period_id = day_periods[schedule_period_id];

			const quarter_1_bool = studentSections.findIndex(section => ((section.course_periods.includes(course_period_id) && section.is_lab == '0' &&  section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '1') !== -1) || (section.course_periods.includes(course_period_id) && section.is_lab == '1' &&  section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '1' && quarter_day.day === day) !== -1))) !== -1;
			const quarter_2_bool = studentSections.findIndex(section => ((section.course_periods.includes(course_period_id) && section.is_lab == '0' &&  section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '2') !== -1) || (section.course_periods.includes(course_period_id) && section.is_lab == '1' &&  section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '2' && quarter_day.day === day) !== -1))) !== -1;
			const quarter_3_bool = studentSections.findIndex(section => ((section.course_periods.includes(course_period_id) && section.is_lab == '0' && section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '3') !== -1) || (section.course_periods.includes(course_period_id) && section.is_lab == '1' && section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '3' && quarter_day.day === day) !== -1))) !== -1;
			const quarter_4_bool = studentSections.findIndex(section => ((section.course_periods.includes(course_period_id) && section.is_lab == '0' &&  section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '4') !== -1) || (section.course_periods.includes(course_period_id) && section.is_lab == '1' &&  section.quarter_days.findIndex(quarter_day => quarter_day.quarter === '4' && quarter_day.day === day) !== -1))) !== -1;

			if(quarter_1_bool) results.matched_quarters++;
			if(quarter_2_bool) results.matched_quarters++;
			if(quarter_3_bool) results.matched_quarters++;
			if(quarter_4_bool) results.matched_quarters++;
		}

		return results;
	}, {'matched_quarters': 0,});

	const percent_requests_matched = (num_student_requests === 0 || matched_student_requests.total_credits == 0) ? 100 : Math.min(Math.round(matched_student_requests.matched_requests/num_student_requests * 100), 100);
	const percent_schedule_full = (total_quarters !== 0) ? Math.min(Math.round(schedule_match_info.matched_quarters/total_quarters * 100), 100) : 0;

	// Collect regular vs alternate requests
	const student_requests_without_alternates = studentRequests.filter(request_info => request_info.priority === '1');
	const alternate_student_requests = studentRequests.filter(request_info => request_info.priority !== '1');

	// Collect all sections during the current course period
	const other_sections_during_period = (currentCoursePeriodID) ? sections.filter(section => section.course_periods.includes(currentCoursePeriodID) && section.section_type === '1') : [];
	const other_sections_during_period_with_course_name = (other_sections_during_period.length <= 0) ? {'requested_courses_during_period':[], 'non_requested_courses_during_period':[]} : other_sections_during_period.reduce((results, section) => {
		const sections_to_check = [section.section_id, ...section.subsections]; // Include subsections to sort ICR sections at the top as well

		// Get all main section info
		const course_info = courses.find(course => course.course_id === section.course_id);
		const course_name = (course_info) ? course_info.name : null;
		const course_code = (course_info) ? course_info.course_code : null;
		const new_section_info = {...section, ...{course_name:course_name, course_code:course_code}};

		// Check if any sections/subsections is a course this student requested
		let course_requested = false;
		sections_to_check.forEach(section_to_check => {
			const section_info = sections.find(temp_section => temp_section.section_id === section_to_check);
			const section_course_id = (section_info && ('course_id' in section_info)) ? section_info.course_id : null;
			const course_info = (section_course_id) ? courses.find(course => course.course_id === section_course_id) : null;
			
			course_requested = (course_info && studentRequests.findIndex(request => request.course_id === course_info.course_id && request.is_deleted === '0') !== -1) ? true : false;
		});

		// If course any section/subsection was requested, add main section to list of requested course (since subsections are skipped for display)
		// Otherwise, add it to non requested list
		if(course_requested)
		{
			results.requested_courses_during_period.push(new_section_info);
		}
		else
		{
			results.non_requested_courses_during_period.push(new_section_info);
		}

		return results;
	}, {'requested_courses_during_period':[], 'non_requested_courses_during_period':[]});

	const sorted_requested_courses_during_period = sortArrayOfObjects(other_sections_during_period_with_course_name.requested_courses_during_period, 'course_name', 'text', 'asc');
	const sorted_non_requested_courses_during_period = sortArrayOfObjects(other_sections_during_period_with_course_name.non_requested_courses_during_period, 'course_name', 'text', 'asc');
	const sorted_other_sections_during_period = [...new Set([...sorted_requested_courses_during_period ,...sorted_non_requested_courses_during_period])]

	//console.log(studentRequests);
	//console.log(studentSections);
	//console.log(changedSections);
	//console.log(currentStudent);

	return (
		<div className='modal-screen'>
			<div className='modal-screen-content'>
				{!isSaving &&
					<>
					<span className="data-modal-close" onClick={handleExitWithoutSaving}>&times;</span>
					<div className="modal-save-btn no-select" onClick={() => {saveStudentSchedule(true)}}><FontAwesomeIcon icon={faSave}/>Save</div>
					</>
				}
				<div className='full-width'>
					<div id='edit-section-data-content'>
						<FontAwesomeIcon className='edit-data-main-head-icon' icon={faEdit}/>
						<h1 className='edit-data-main-header'>Edit Student Schedule</h1>
						{isSaving &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
								<div>Saving...</div>
							</div>
						}
						{isLoading &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						}
						{(!isLoading && !isSaving) &&
							<div>
								<div className='edit-student-navigation-container'>
									<div className={`edit-student-navigation-btn blue-link ${!previousStudentID && 'opacity-50'}`} onClick={previousStudentID ? () => handleMoveToNewStudent('previous') : null}>
										<FontAwesomeIcon icon={faChevronLeft}/>
										<div>Previous Student</div>
									</div>
									<div className={`edit-student-navigation-btn blue-link ${!nextStudentID && 'opacity-50'}`} onClick={nextStudentID ? () => handleMoveToNewStudent('next') : null}>
										<div>Next Student</div>
										<FontAwesomeIcon icon={faChevronRight}/>
									</div>
								</div>
								<div className='edit-student-schedule-top-container'>
									<div className='edit-student-schedule-background-container'>
										<img style={{height:'40px'}} src={require("../../../images/logo-dark.png")} alt='Edario Logo' />
										<div>
											<div className='edit-student-schedule-student-name'>{currentStudent.last_name}, {currentStudent.first_name}</div>
											<div>{currentStudent.grade}th grade</div>
											<div className='medium-text' style={{margin:'5px 0px 0px 0px'}}>{scheduleInfo.current_schedule_year} Schedule</div>
											<div className='medium-text dark-gray-text' style={{margin:'5px 0px 0px 0px'}}>{schoolInfo.name}</div>
										</div>
									</div>
									<div className='edit-student-schedule-summary-statistics-container'>
										<div className='edit-student-stats-container'>
											<h4 className='edit-student-stats-heading'>Courses</h4>
											<div><span className='edit-student-stats-numerator'>{matched_student_requests.matched_requests}</span><span className='edit-student-stats-fraction-bar'>/</span><span className='edit-student-stats-denominator'>{num_student_requests}</span></div>
											<div className='medium-text'>requests matched</div>
										</div>
										<div className='edit-student-stats-container'>
											<h4 className='edit-student-stats-heading'>Credits</h4>
											<div><span className='edit-student-stats-numerator'>{matched_student_requests.matched_credits}</span><span className='edit-student-stats-fraction-bar'>/</span><span className='edit-student-stats-denominator'>{matched_student_requests.total_credits}</span></div>
											<div className='medium-text'>credits fulfilled</div>
										</div>
										<div className='percentage-container edit-student-percentage-container'>
											<svg viewBox="0 0 36 36">
												<path className='percentage-circle-default' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
												{percent_requests_matched != 0 &&
													<path className='percentage-circle-inner' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" stroke="#ff9f00" strokeDasharray={`${percent_requests_matched}, 100`}/>
												}
											</svg>
											<div className='percentage-text-container'>
												<div className='percent-text'>{percent_requests_matched}%</div>
												<div className='percent-subtext'>requests</div>
											</div>
										</div>
										<div className='percentage-container edit-student-percentage-container'>
											<svg viewBox="0 0 36 36">
												<path className='percentage-circle-default' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
												{percent_schedule_full != 0 &&
													<path className='percentage-circle-inner' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" stroke="#2ecc71" strokeDasharray={`${percent_schedule_full}, 100`}/>
												}
											</svg>
											<div className='percentage-text-container'>
												<div className='percent-text'>{percent_schedule_full}%</div>
												<div className='percent-subtext'>day full</div>
											</div>
										</div>
									</div>
								</div>
								<div className='mb-options-bar' style={{marginBottom:'10px'}}>
									<div className='mb-search-bar-outer-container'>
										<div className='edit-student-schedule-toolbar'>
											<div className='edit-student-schedule-toolbar-btn tooltip'  data-tooltip-text='Lock all sections' onClick={lockAllSections}>
												<FontAwesomeIcon icon={faLock} />
											</div>
											<div className='edit-student-schedule-toolbar-btn tooltip edit-student-schedule-toolbar-btn-border-right'  data-tooltip-text='Unlock all sections' onClick={unlockAllSections}>
												<FontAwesomeIcon icon={faUnlock} />
											</div>
											<div className='edit-student-schedule-toolbar-btn tooltip' data-tooltip-text='Clear all sections' onClick={clearAllSections}>
												<FontAwesomeIcon icon={faBroom} />
											</div>
										</div>
									</div>
									<div className='mb-change-view-container'>
										{(scheduleType !== 'daily') &&
											<>
											<div className='mb-dropdown-wrapper'><Dropdown data={viewTypeOptions} currentValue={studentCurrentViewType} handleChange={(new_view_type) => changeStudentCurrentViewType(new_view_type)} /></div>
											{(studentCurrentViewType === 'day') &&
												<div className='mb-dropdown-wrapper'><Dropdown data={dayOptions} currentValue={studentCurrentDay} handleChange={(new_day) => changeStudentCurrentDay(new_day)} /></div>
											}
											</>
										}
									</div>
								</div>
								<div className='mb-row mb-middle-header' style={row_style}>
									{periodsToShow.map((period_info, index) => {
										const period_name = period_info.period_name;
										
										return (
											<div className='mb-inner-box' style={{padding:'10px'}} key={index}><div>{period_name}</div></div>
										)
									})}
								</div>
								<div className='mb-row mb-schedule-row' style={row_style}>
									{periodsToShow.map((period_info, index) => {
										const period_id = period_info.period_id;
										const course_period_id = (period_type === 'course_periods') ? period_id : scheduleStructureData.schedule_structure[studentCurrentDay].find(structure_info => structure_info.schedule_period_id === period_id).course_period_id;
										const course_period_sections = studentSections.filter(section => section.course_periods.includes(course_period_id));

										const course_period_sections_sorted_by_quarter = course_period_sections.sort((section_A, section_B) => {
											const section_A_min_quarter = section_A.quarter_days.reduce((result, quarter_day) => {
												if(quarter_day.quarter < result) result = quarter_day.quarter;
												return result;
											}, '999');

											const section_B_min_quarter = section_B.quarter_days.reduce((result, quarter_day) => {
												if(quarter_day.quarter < result) result = quarter_day.quarter;
												return result;
											}, '999');

											if(section_A_min_quarter < section_B_min_quarter) return -1;
											return 1;
										});
										
										return (
											<div className={`mb-inner-box mb-schedule-inner-box edit-student-schedule-inner-row ${course_period_id === currentCoursePeriodID ? 'edit-student-schedule-inner-row-selected' : ''}`} key={index} data-courseperiodid={course_period_id} onClick={() => setCurrentCoursePeriodID(course_period_id)}>
												{course_period_sections_sorted_by_quarter.map((section_info, index) => {
													let section_id = section_info.section_id;

													let main_section_id = section_id;
													let main_section_info = section_info;

													let teacher_id = null;
													let teacher_info = null;

													// If section is subsection, get main section info
													const section_is_subsection_index = subsections.findIndex(subsection_section => subsection_section.subsection_id === section_id);
													const section_is_subsection = (section_is_subsection_index !== -1) ? true : false;
													if(section_is_subsection)
													{
														main_section_id = subsections[section_is_subsection_index].section_id;
														main_section_info = sections.find(section => section.section_id === main_section_id);
													}

													const student_section_is_locked = currentStudent.student_locked_sections.includes(section_id);
													const student_in_section = (studentSections.findIndex(section => section.section_id === main_section_id) !== -1) ? true : false;
													teacher_id = (student_in_section) ? main_section_info.teacher_id : null;
													teacher_info = (teacher_id) ? teachers.find(teacher => teacher.teacher_id === teacher_id) : null;

													const course_id = main_section_info.course_id;
													const classroom_id = main_section_info.classroom_id;
													const is_lab = main_section_info.is_lab;

													const quarter_days = main_section_info.quarter_days;
													const quarter_1_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '1') !== -1) ? true : false;
													const quarter_2_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '2') !== -1) ? true : false;
													const quarter_3_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '3') !== -1) ? true : false;
													const quarter_4_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '4') !== -1) ? true : false;
													const unique_days = quarter_days.reduce((results, quarter_day) => {
														const day = quarter_day = quarter_day.day;
														if(!results.includes(day)) results.push(day);
														return results;
													},[]);
													
													// Get num students from all subsections too
													const section_subsections = ('subsections' in main_section_info) ? main_section_info.subsections : [];
													
													let num_students = (section_subsections.length === 0) ? main_section_info.student_list.length : section_subsections.reduce((total_students, subsection_id) => {
														const subsection_num_students = sections.find(section => section.section_id === subsection_id).student_list.length;
														return total_students + subsection_num_students;
													}, main_section_info.student_list.length);

													// If this section (or any of it's subsections were added during this session, increment number of students by 1 to include this student)
													const newly_added_section = (changedSections.added_sections.includes(main_section_id) || section_subsections.filter(subsection_id => changedSections.added_sections.includes(subsection_id)).length > 0);
													const newly_deleted_section = (changedSections.deleted_sections.includes(main_section_id) || section_subsections.filter(subsection_id => changedSections.deleted_sections.includes(subsection_id)).length > 0);
													if(newly_added_section) num_students++;
													if(newly_deleted_section) num_students--;
													
													const course_info = courses.find(course => course.course_id === course_id);
													const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
													
													const section_color = (is_lab === '1') ? 'green' : 'dark-blue';
													
													return (
														<div className='mb-student-section' key={section_id} data-sectionid={section_id}>
															<div className={`mb-section-top-bar ${section_color}`}>
																<div>{is_lab === '1' ? "Lab" : <FontAwesomeIcon icon={faLock} className={student_section_is_locked ? 'bright-yellow-text' : 'opaque-link'} onClick={()=>toggleLockSection(section_id)}/>}</div>
																<div className='small-text opaque-link' onClick={student_section_is_locked ? ()=>{} :() => removeSectionFromSchedule(section_id)}>{student_section_is_locked ? '': <span>&times;</span>}</div>
															</div>
															<div className='mb-section-content'>
																<div className='tooltip' data-tooltip-text={`${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})`}>
																	<div className='mb-section-name ellipsis'>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
																</div>
																{section_subsections.map(subsection_id => {
																	const subsection_section_info = sections.find(section => section.section_id === subsection_id);
																	const subsection_course_id = subsection_section_info.course_id;
																	const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
																	const subsection_course_name = (subsection_course_info) ? `${capitalizeFirstLetters(subsection_course_info.name)} (${subsection_course_info.course_code})` : null;

																	if(!teacher_id)
																	{
																		const student_in_section = (studentSections.findIndex(section => section.section_id === subsection_id) !== -1) ? true : false;
																		teacher_id = (student_in_section) ? subsection_section_info.teacher_id : null;
																		teacher_info = (teacher_id) ? teachers.find(teacher => teacher.teacher_id === teacher_id) : null;
																	}

																	// Check if subsection is placeholder inclusion course (for schools without separate ICR codes)
																	const subsection_is_inclusion = subsection_section_info.is_inclusion;
																	let is_placeholder_inclusion = false;
																	if(scheduleInfo.separate_ICR_codes === '0' && course_id === subsection_course_id && subsection_is_inclusion === '1') is_placeholder_inclusion = true;

																	return (
																		<React.Fragment key={subsection_id}>
																		{is_placeholder_inclusion ?
																			(
																				<div className='orange-text'>Inclusion</div>
																			):
																			(
																				<div className='tooltip' data-tooltip-text={subsection_course_name}>
																					<div className='mb-section-name ellipsis blue-text'>{subsection_course_name}</div>
																				</div>
																			)
																		}
																		</React.Fragment>
																	)
																})}
																{teacher_info &&
																	<div className='dark-gray-text'>{teacher_info.name}, {teacher_info.first_name}</div>
																}
																<div className='mb-section-quarter-day-container'>
																	<div className={`mb-section-quarter-day ${quarter_1_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q1</div>
																	<div className={`mb-section-quarter-day ${quarter_2_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q2</div>
																	<div className={`mb-section-quarter-day ${quarter_3_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q3</div>
																	<div className={`mb-section-quarter-day ${quarter_4_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q4</div>
																</div>
																{(is_lab === '1') ?
																	(
																		<div className='mb-lab-days extra-small-text green-text'>{unique_days.length > 0 ? `Day(s) ${unique_days.map(day => (day))}` : 'No lab days'}</div>
																	):((scheduleType === 'daily' && unique_days.length > 0 && unique_days.length != scheduleInfo.num_days_in_cycle)) ?
																	(
																		<div className='mb-lab-days extra-small-text blue-text'>Day(s) {unique_days.map(day => (day))}</div>
																	):null
																}
																<div className='mb-section-subname-container'>
																	<div className='mb-section-subname'><FontAwesomeIcon icon={faChalkboard}/>{classroom_info ? classroom_info.classroom_name : '--'}</div>
																	<div className='mb-section-students'><FontAwesomeIcon icon={faUserGraduate}/> {num_students}</div>
																</div>
															</div>
														</div>
													)
												})}
											</div>
										)
									})}
								</div>
								<div className='mb-row mb-schedule-row' style={row_style}>
									{periodsToShow.map((period_info, index) => {
										const period_id = period_info.period_id;
										const course_period_id = (period_type === 'course_periods') ? period_id : scheduleStructureData.schedule_structure[studentCurrentDay].find(day_row => day_row.schedule_period_id === period_id).course_period_id;
										
										const courses_in_course_period = studentRequests.reduce((results, request) => {
											const request_is_deleted = request.is_deleted === '1';

											if(request_is_deleted) return results;

											const request_course_id = request.course_id;

											const labels_needed = currentStudent.student_labels.reduce((results, label_info) => {
												const label_course_id = label_info.course_id;
												if(label_course_id === request_course_id) results.push(label_info.label_id);
												return results;
											}, []);

											if(sections.findIndex(section => section.course_periods.includes(course_period_id) && section.course_id === request_course_id && labels_needed.every(label_id => section.labels.includes(label_id))) !== -1) results.push(request);
							
											return results;
										}, []);

										return (
											<div className='edit-student-schedule-legend-inner' key={index}>
												{courses_in_course_period.map((request_info, index) => {
													const request_course_id = request_info.course_id;
													const request_icon = request_info.request_icon;
													const fontawesome_icon = icons[request_icon];
													const request_color = request_info.color;

													const request_is_highlighted = highlightedRequests.findIndex(highlighted_course_id => highlighted_course_id === request_course_id) !== -1;

													return (
														<div className={`request-icon ${request_is_highlighted && request_color} `} key={index} onClick={() => addLegendCourseToSchedule(course_period_id, request_course_id)} onMouseEnter={!request_is_highlighted ? (e) => handleHoverOverHighlight(e, 'mouse-in', request_course_id) : ()=>{}} onMouseLeave={!request_is_highlighted ? (e) => handleHoverOverHighlight(e, 'mouse-out', request_course_id):()=>{}}>{fontawesome_icon && <FontAwesomeIcon icon={fontawesome_icon}/>}</div>
													)
												})}
											</div>
										)
									})}
								</div>
								<div id='edit-student-schedule-bottom-container'>
									<div id='edit-student-schedule-request-container'>
										<div className='edit-student-schedule-heading'>Course Requests</div>
										<div id='edit-student-schedule-student-request-list'>
											{student_requests_without_alternates.length === 0 &&
												<div className='edit-data-no-data-container'>No courses have been requested yet!</div>
											}
											{student_requests_without_alternates.map((request_info, index) => {
												const request_course_id = request_info.course_id;
												const request_icon = request_info.request_icon;
												const fontawesome_icon = icons[request_icon];
												const request_color = request_info.color;
												const is_inclusion = request_info.is_inclusion === '1';
												const match_index = studentSections.findIndex(section => section.course_id === request_course_id);
												const matched = (match_index !== -1);
												const data_origin = request_info.data_origin;
												const is_deleted = (request_info.is_deleted === '1') ? true : false;
												const course_info = courses.find(course => course.course_id === request_course_id);

												if(!course_info) return null;

												const course_labels = currentStudent.student_labels.filter(label => label.course_id === request_course_id);

												const request_is_highlighted = highlightedRequests.findIndex(highlighted_course_id => highlighted_course_id === request_course_id) !== -1;
												
												return (
													<div className='edit-student-schedule-student-request-row medium-text' key={index}>
														{request_icon &&
															<div className={`request-icon ${is_deleted && 'disabled-row'} ${request_is_highlighted && request_color} `} onClick={() => toggleRequestHighlight(request_course_id)} onMouseEnter={!request_is_highlighted ? (e) => handleHoverOverHighlight(e, 'mouse-in', request_course_id) : ()=>{}} onMouseLeave={!request_is_highlighted ? (e) => handleHoverOverHighlight(e, 'mouse-out', request_course_id):()=>{}}>{fontawesome_icon && <FontAwesomeIcon icon={fontawesome_icon}/>}</div>
														}
														<div className={`edit-student-schedule-student-requent-course-name ${is_deleted && 'disabled-row'}`}>
															<div>
																<div>
																	<span>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</span>
																	{is_inclusion &&
																		<span className='orange-text small-text'> - Inclusion</span>
																	}
																</div>
																{data_origin === '2' && <div className='small-text yellow-text'>Added in Edario</div>}
															</div>
															<div>
																{course_labels.map((course_label, i) => {
																	const label_id = course_label.label_id;
																	const label_info = labels.find(label => label.label_id === label_id);
																	
																	if(!label_info) return null;

																	const is_team = (label_info.is_team === '1') ? true : false;

																	return (
																		<div className={`edit-student-schedule-request-label ${is_team ? 'pastel-blue-text' : 'pastel-orange-text'}`} key={i}>{label_info.label}</div>
																	)
																})}
															</div>
														</div>
														{is_deleted ?
															(
																<>
																<div className='red-text small-text opacity-50'>Deleted</div>
																<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => removeRequest(request_course_id, true)}/>
																</>
															):
															(
																<>
																<div className={`${matched ? 'green-text' : 'gray-text'}`}>
																	{!matched ? "Not " : ''}Matched {matched ? <FontAwesomeIcon icon={faCheck} style={{marginLeft:'6px'}}/> : ''}
																	<div className={`small-text ${matched ? 'green-text' : 'gray-text'}`}>{course_info.credits} credits</div>
																</div>
																<div className="remove-data"><FontAwesomeIcon icon={faTimes} onClick={() => removeRequest(request_course_id, false)}/></div>
																</>
															)
														}
													</div>
												)	
											})}
											<div className='edit-data-add-new-btn blue-link align-right medium-text' onClick={() => setAddNewRequestOpen(!addNewRequestOpen)} style={{marginTop:'13px'}}>
												<FontAwesomeIcon icon={faPlusCircle}/>
												<div>Add New Request</div>
											</div>
											{addNewRequestOpen &&
												<div className='search-add-row-container'>
													<div className='search-add-search-container'>
														<div className='search-add-adding-input-container'>
															<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'courses', false)} placeholder='Search for course'/>
															<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
														</div>
														<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => setAddNewRequestOpen(false)}/>
													</div>
													{newCourseRequestResults.length !== 0 &&
														<div className='search-add-search-results'>
															<div className='search-add-did-you-mean'>Did you mean:</div>
															{newCourseRequestResults.map((course, i) => {
																return (
																	<div className='search-add-search-result' key={i}>
																		<div>{course.name} ({course.course_code})</div>
																		<div>
																			<div className='search-add-add-btn' onClick={() => addNewRequest(course.course_id, '1')}>Add</div>
																		</div>
																	</div>
																)
															})}
														</div>
													}
												</div>
											}
											<div className='edit-student-schedule-heading' style={{marginTop:'10px'}}>Alternates</div>
											{alternate_student_requests.length === 0 &&
												<div className='edit-data-no-data-container'>No alternates have been set yet!</div>
											}
											{alternate_student_requests.map((request_info, index) => {
												const request_course_id = request_info.course_id;
												const request_icon = request_info.request_icon;
												const fontawesome_icon = icons[request_icon];
												const request_color = request_info.color;
												const is_inclusion = request_info.is_inclusion === '1';
												const match_index = studentSections.findIndex(section => section.course_id === request_course_id);
												const matched = (match_index !== -1);
												const data_origin = request_info.data_origin;
												const is_deleted = (request_info.is_deleted === '1') ? true : false;
												const course_info = courses.find(course => course.course_id === request_course_id);

												if(!course_info) return null;

												const course_labels = currentStudent.student_labels.filter(label => label.course_id === request_course_id);

												const request_is_highlighted = highlightedRequests.findIndex(highlighted_course_id => highlighted_course_id === request_course_id) !== -1;
												
												return (
													<div className='edit-student-schedule-student-request-row medium-text' key={index}>
														{request_icon &&
															<div className={`request-icon ${request_is_highlighted && request_color}`} onClick={() => toggleRequestHighlight(request_course_id)} onMouseEnter={!request_is_highlighted ? (e) => handleHoverOverHighlight(e, 'mouse-in', request_course_id) : ()=>{}} onMouseLeave={!request_is_highlighted ? (e) => handleHoverOverHighlight(e, 'mouse-out', request_course_id):()=>{}} >{fontawesome_icon && <FontAwesomeIcon icon={fontawesome_icon} />}</div>
														}
														<div className={`edit-student-schedule-student-requent-course-name ${is_deleted && 'disabled-row'}`}>
															<div>
																<div>
																	<span>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</span>
																	{is_inclusion &&
																		<span className='orange-text small-text'> - Inclusion</span>
																	})</div>
																{data_origin === '2' && <div className='small-text yellow-text'>Added in Edario</div>}
															</div>
															<div>
																{course_labels.map((course_label, i) => {
																	const label_id = course_label.label_id;
																	const label_info = labels.find(label => label.label_id === label_id);
																	
																	if(!label_info) return null;

																	const is_team = (label_info.is_team === '1') ? true : false;

																	return (
																		<div className={`edit-student-schedule-request-label ${is_team ? 'pastel-blue-text' : 'pastel-orange-text'}`} key={i}>{label_info.label}</div>
																	)
																})}
															</div>
														</div>
														{is_deleted ?
															(
																<>
																<div className='red-text small-text opacity-50'>Deleted</div>
																<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => removeRequest(request_course_id, true)}/>
																</>
															):
															(
																<>
																<div className={`${matched ? 'green-text' : 'gray-text'}`}>
																	{!matched ? "Not " : ''}Matched {matched ? <FontAwesomeIcon icon={faCheck} style={{marginLeft:'6px'}}/> : ''}
																	<div className={`small-text ${matched ? 'green-text' : 'gray-text'}`}>{course_info.credits} credits</div>
																</div>
																<div className="remove-data"><FontAwesomeIcon icon={faTimes} onClick={() => removeRequest(request_course_id, false)}/></div>
																</>
															)
														}
													</div>
												)	
											})}
											<div className='edit-data-add-new-btn blue-link align-right medium-text' onClick={() => setAddNewAlternateOpen(!addNewAlternateOpen)} style={{marginTop:'13px'}}>
												<FontAwesomeIcon icon={faPlusCircle}/>
												<div>Add New Alternate</div>
											</div>
											{addNewAlternateOpen &&
												<div className='search-add-row-container'>
													<div className='search-add-search-container'>
														<div className='search-add-adding-input-container'>
															<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'courses', true)} placeholder='Search for course'/>
															<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
														</div>
														<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => setAddNewAlternateOpen(false)}/>
													</div>
													{newAlternateResults.length !== 0 &&
														<div className='search-add-search-results'>
															<div className='search-add-did-you-mean'>Did you mean:</div>
															{newAlternateResults.map((course, i) => {
																return (
																	<div className='search-add-search-result' key={i}>
																		<div>{course.name} ({course.course_code})</div>
																		<div>
																			<div className='search-add-add-btn' onClick={() => addNewRequest(course.course_id, '2')}>Add</div>
																		</div>
																	</div>
																)
															})}
														</div>
													}
												</div>
											}
										</div>
									</div>
									<div id='edit-student-schedule-other-courses-container'>
										<div className='edit-student-schedule-heading blue'>Other Courses During this Period</div>
										<div className='edit-student-schedule-other-courses-list'>
											{!currentCoursePeriodID &&
												<div id='edit-student-schedule-no-period-message'>
													<div><FontAwesomeIcon className='blue-text' style={{fontSize:'60px'}} icon={faHandPointer}/></div>
													<h3>No Period Selected Yet</h3>
													<div>Click on any period above to show other courses during that period</div>
												</div>
											}
											{(currentCoursePeriodID && sorted_other_sections_during_period.length === 0) &&
												<div id='edit-student-schedule-no-period-message'>
													<div><FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faCalendar}/></div>
													<h3>No Sections in this Period</h3>
													<div>This period does not have any sections placed yet</div>
												</div>
											}
											{sorted_other_sections_during_period.map(section_info => {
												const section_id = section_info.section_id;
												
												// Skip subsections, we will capture those as we go through the main sections
												const section_is_subsection_index = subsections.findIndex(subsection_section => subsection_section.subsection_id === section_id);
												if(section_is_subsection_index !== -1) return null;

												const section_subsections = ('subsections' in section_info) ? section_info.subsections : [];

												const course_id = section_info.course_id;
												const course_info = courses.find(course => course.course_id === course_id);
												const course_name = (course_info) ? course_info.name : null;
												const course_code = (course_info) ? course_info.course_code: null;
												const max_class_size = (course_info) ? course_info.max_class_size : null;

												const teacher_id = section_info.teacher_id;
												const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
												const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
												const teacher_last_name = (teacher_info) ? teacher_info.name : null;
												const secondary_teachers = section_info.secondary_teachers;

												const quarter_days = section_info.quarter_days;
												const quarter_1_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '1') !== -1) ? true : false;
												const quarter_2_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '2') !== -1) ? true : false;
												const quarter_3_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '3') !== -1) ? true : false;
												const quarter_4_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '4') !== -1) ? true : false;
												const unique_days = quarter_days.reduce((results, quarter_day) => {
													const day = quarter_day = quarter_day.day;
													if(!results.includes(day)) results.push(day);
													return results;
												},[]);

												let num_students = (section_subsections.length === 0) ? section_info.student_list.length : section_subsections.reduce((total_students, subsection_id) => {
													const subsection_section_info = sections.find(section => section.section_id === subsection_id);
													const subsection_num_students = (subsection_section_info && ('student_list' in subsection_section_info)) ? subsection_section_info.student_list.length : 0;
													return total_students + subsection_num_students;
												}, section_info.student_list.length);

												// Labels the student needs for this course
												const labels_needed = currentStudent.student_labels.reduce((results, label_info) => {
													const label_course_id = label_info.course_id;
													if(label_course_id === course_id) results.push(label_info.label_id);
													return results;
												}, []);

												const request_index = studentRequests.findIndex(request => request.course_id === course_id);
												const request_info = (request_index !== -1) ? studentRequests[request_index] : null;
												const request_icon = (request_info) ? request_info.request_icon : null;
												const fontawesome_icon = (request_icon) ? icons[request_icon] : null;
												const request_color = (request_info) ? request_info.color : '';

												const request_is_highlighted = highlightedRequests.findIndex(highlighted_course_id => highlighted_course_id === course_id) !== -1;

												// Check if this course was requested, and fulfills all needed labels
												const course_matches_request = studentRequests.findIndex(request => request.course_id === course_id && labels_needed.every(label_id => section_info.labels.includes(label_id))) !== -1;

												// Get all labels
												let section_all_labels = section_info.labels;		
												section_all_labels = section_all_labels.map(label_id => {
													const label_info = labels.find(label => label.label_id === label_id);
													return label_info;
												});

												const section_teams = section_all_labels.filter(label => label.is_team === '1');
												const sorted_section_teams = sortArrayOfObjects(section_teams, 'label', 'text', 'asc');
												
												const section_labels = section_all_labels.filter(label => label.is_team === '0');
												const sorted_section_labels = sortArrayOfObjects(section_labels, 'label', 'text', 'asc');

												// If this section (or any of it's subsections were added during this session, increment number of students by 1 to include this student)
												const newly_added_section = (changedSections.added_sections.includes(section_id) || section_subsections.filter(subsection_id => changedSections.added_sections.includes(subsection_id)).length > 0);
												const newly_deleted_section = (changedSections.deleted_sections.includes(section_id) || section_subsections.filter(subsection_id => changedSections.deleted_sections.includes(subsection_id)).length > 0);
												if(newly_added_section) num_students++;
												if(newly_deleted_section) num_students--;

												const other_course_row_style = {gridTemplateColumns: '0px 1fr 100px 65px auto'};
												
												return (
													<div className='edit-student-schedule-other-course-row medium-text' key={section_id}>
														<div className='edit-student-schedule-other-course-inner-row' style={other_course_row_style}>
															<div>{course_matches_request && <FontAwesomeIcon className={`edit-student-schedule-other-course-icon ${request_is_highlighted && request_color}`} icon={fontawesome_icon} />}</div>
															<div className='edit-student-schedule-other-course-info tooltip' data-tooltip-text={`${capitalizeFirstLetters(course_name)} (${course_code})`}>
																<div className='ellipsis' style={{maxWidth:'217px'}}> {capitalizeFirstLetters(course_name)} ({course_code})</div>
																<div className='dark-gray-text'>{teacher_last_name}, {teacher_first_name}</div>
																{secondary_teachers.map((secondary_teacher_id, i) => {
																	const secondary_teacher_info = teachers.find(teacher => teacher.teacher_id === secondary_teacher_id);
																	const secondary_teacher_full_name = (teacher_info) ? `${secondary_teacher_info.name}, ${secondary_teacher_info.first_name}` : null;

																	return (
																		<div className='dark-gray-text' key={`secondary-teacher=${i}`}>{secondary_teacher_full_name}</div>
																	)
																})}
																<div>
																	{sorted_section_teams.map((course_label, i) => {
																		const label_id = course_label.label_id;
																		const label_info = labels.find(label => label.label_id === label_id);
																		
																		if(!label_info) return null;

																		return (
																			<div className={`edit-student-schedule-request-label pastel-blue-text`} key={i}>{label_info.label}</div>
																		)
																	})}
																	{sorted_section_labels.map((course_label, i) => {
																		const label_id = course_label.label_id;
																		const label_info = labels.find(label => label.label_id === label_id);
																		
																		if(!label_info) return null;

																		return (
																			<div className={`edit-student-schedule-request-label pastel-orange-text`} key={i}>{label_info.label}</div>
																		)
																	})}
																</div>
															</div>
															<div>
																<div className='mb-section-quarter-day-container' style={{margin:'0px'}}>
																	<div className={`mb-section-quarter-day ${quarter_1_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q1</div>
																	<div className={`mb-section-quarter-day ${quarter_2_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q2</div>
																	<div className={`mb-section-quarter-day ${quarter_3_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q3</div>
																	<div className={`mb-section-quarter-day ${quarter_4_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q4</div>
																</div>
																{((scheduleType === 'daily' && unique_days.length > 0 && unique_days.length != scheduleInfo.num_days_in_cycle)) ?
																	(
																		<div className='mb-lab-days extra-small-text blue-text'>Day(s) {unique_days.map(day => (day))}</div>
																	):null
																}
															</div>
															<div>
																<FontAwesomeIcon className='medium-text' icon={faUserGraduate} style={{margin:'0px 8px 0px 0px'}}/><span>{num_students}/{max_class_size}</span>
															</div>
															<FontAwesomeIcon className='edit-student-schedule-add-section-to-schedule green-text cursor-pointer' icon={faPlusCircle} onClick={() => addSectionToSchedule(section_id)}/>
														</div>
														{section_subsections.map(subsection_id => {
															const subsection_section_info = sections.find(section => section.section_id === subsection_id);
															const subsection_course_id = (subsection_section_info) ? subsection_section_info.course_id : null;

															if(!subsection_course_id) return null;

															const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
															const subsection_course_name = (subsection_course_info) ? subsection_course_info.name : null;
															const subsection_course_code = (subsection_course_info) ? subsection_course_info.course_code : null;

															const subsection_teacher_id = subsection_section_info.teacher_id;
															const subsection_teacher_info = (subsection_teacher_id) ? teachers.find(teacher => teacher.teacher_id === subsection_teacher_id) : null;
															const subsection_teacher_first_name = (subsection_teacher_info) ? subsection_teacher_info.first_name : null;
															const subsection_teacher_last_name = (subsection_teacher_info) ? subsection_teacher_info.name : null;
															const subsection_secondary_teachers = subsection_section_info.secondary_teachers;

															// Check if subsection is placeholder inclusion course (for schools without separate ICR codes)
															const subsection_is_inclusion = subsection_section_info.is_inclusion;
															let is_placeholder_inclusion = false;
															if(scheduleInfo.separate_ICR_codes === '0' && course_id === subsection_course_id && subsection_is_inclusion === '1') is_placeholder_inclusion = true;

															// Figure out max class size (if inclusion then 10)
															const subsection_max_class_size = (subsection_is_inclusion) ? Math.min(10, .5 * max_class_size, subsection_course_info.max_class_size) : subsection_course_info.max_class_size;
															let subsection_num_students = subsection_section_info.student_list.length;

															const request_index = studentRequests.findIndex(request => request.course_id === subsection_course_id);
															const request_info = (request_index !== -1) ? studentRequests[request_index] : null;
															const request_icon = (request_info) ? request_info.request_icon : null;
															const fontawesome_icon = (request_icon) ? icons[request_icon] : null;
															const request_color = (request_info) ? request_info.color : '';

															const request_is_highlighted = highlightedRequests.findIndex(highlighted_course_id => highlighted_course_id === subsection_course_id) !== -1;

															// Labels the student needs for this course (if any)
															const labels_needed = currentStudent.student_labels.reduce((results, label_info) => {
																const label_course_id = label_info.course_id;
																if(label_course_id === subsection_course_id) results.push(label_info.label_id);
																return results;
															}, []);

															// Check if this course was requested, and fulfills all needed labels
															const subsection_course_matches_request = studentRequests.findIndex(request => request.course_id === subsection_course_id && labels_needed.every(label_id => subsection_section_info.labels.includes(label_id))) !== -1;

															// Get all labels
															let subsection_all_labels = subsection_section_info.labels;		
															subsection_all_labels = subsection_all_labels.map(label_id => {
																const label_info = labels.find(label => label.label_id === label_id);
																return label_info;
															});
															
															const subsection_teams = subsection_all_labels.filter(label => label.is_team === '1');
															const sorted_subsection_teams = sortArrayOfObjects(subsection_teams, 'label', 'text', 'asc');
															
															const subsection_labels = subsection_all_labels.filter(label => label.is_team === '0');
															const sorted_subsection_labels = sortArrayOfObjects(subsection_labels, 'label', 'text', 'asc');

															// If this section (or any of it's subsections were added/deleted during this session, increment number of students by 1 to adjust student number
															const newly_added_subsection = (changedSections.added_sections.includes(subsection_id));
															const newly_deleted_subsection = (changedSections.deleted_sections.includes(subsection_id));
															if(newly_added_subsection) subsection_num_students++;
															if(newly_deleted_subsection) subsection_num_students--;

															return (
																<div className='edit-student-schedule-other-course-inner-row subsection-row' key={subsection_id} style={other_course_row_style}>
																	<div>{subsection_course_matches_request && <FontAwesomeIcon className={`edit-student-schedule-other-course-icon ${request_is_highlighted && request_color}`} icon={fontawesome_icon} />}
																	</div>
																	<div className='edit-student-schedule-other-course-info'>
																		<div className={`${is_placeholder_inclusion ? 'orange-text' : 'blue-text'}`}>
																			{is_placeholder_inclusion ?
																				(
																					<div>Inclusion Subsection</div>
																				): 
																				(
																					<div>{capitalizeFirstLetters(subsection_course_name)} ({subsection_course_code})</div>
																				)
																			}
																		</div>
																		<div className='dark-gray-text'>{subsection_teacher_last_name}, {subsection_teacher_first_name}</div>
																		{subsection_secondary_teachers.map((secondary_teacher_id, i) => {
																			const secondary_teacher_info = teachers.find(teacher => teacher.teacher_id === secondary_teacher_id);
																			const secondary_teacher_full_name = (teacher_info) ? `${secondary_teacher_info.name}, ${secondary_teacher_info.first_name}` : null;

																			return (
																				<div className='dark-gray-text' key={`subsection-secondary-teacher=${i}`}>{secondary_teacher_full_name}</div>
																			)
																		})}
																		<div>
																			{sorted_subsection_teams.map((course_label, i) => {
																				const label_id = course_label.label_id;
																				const label_info = labels.find(label => label.label_id === label_id);
																				
																				if(!label_info) return null;

																				return (
																					<div className={`edit-student-schedule-request-label pastel-blue-text`} key={i}>{label_info.label}</div>
																				)
																			})}
																			{sorted_subsection_labels.map((course_label, i) => {
																				const label_id = course_label.label_id;
																				const label_info = labels.find(label => label.label_id === label_id);
																				
																				if(!label_info) return null;

																				return (
																					<div className={`edit-student-schedule-request-label pastel-orange-text`} key={i}>{label_info.label}</div>
																				)
																			})}
																		</div>
																	</div>
																	<div>
																		<div className='mb-section-quarter-day-container' style={{margin:'0px'}}>
																			<div className={`mb-section-quarter-day ${quarter_1_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q1</div>
																			<div className={`mb-section-quarter-day ${quarter_2_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q2</div>
																			<div className={`mb-section-quarter-day ${quarter_3_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q3</div>
																			<div className={`mb-section-quarter-day ${quarter_4_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q4</div>
																		</div>
																		{((scheduleType === 'daily' && unique_days.length > 0 && unique_days.length != scheduleInfo.num_days_in_cycle)) ?
																			(
																				<div className='mb-lab-days extra-small-text blue-text'>Day(s) {unique_days.map(day => (day))}</div>
																			):null
																		}
																	</div>
																	<div>
																		<FontAwesomeIcon icon={faUserGraduate} style={{margin:'0px 8px 0px 0px'}}/><span>{subsection_num_students}/{subsection_max_class_size}</span>
																	</div>
																	<FontAwesomeIcon className={`edit-student-schedule-add-section-to-schedule ${is_placeholder_inclusion ? 'orange-text' : 'green-text'} cursor-pointer`} icon={faPlusCircle} onClick={() => addSectionToSchedule(subsection_id)}/>
																</div>
															)
														})}
													</div>
												)
											})}
										</div>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}