import React, { useState , useEffect } from 'react';
import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import Dropdown from '../../Dropdown';

import { loadSpecificData, isEmpty, sortArrayOfObjects, recalculateMatchPercent, calculateConflicts } from '../../../js/Helpers';
import { saveDataToDatabase } from '../../../js/SaveFunctions';

import '../../../css/hsms/edit_modals.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle, faTimes, faSearch, faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';

export default function EditTeacherData({ dataID, toggleEditData, schoolInfo, scheduleStructureData, scheduleInfo, teachers, students, courses, classrooms, departments, sections, setDatabaseData, defaultDepartmentID = null, filteredResults = [], setFilteredResults = ()=>{} }) {
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState({});
	
	const [tctResults, setTctResults] = useState([]);
	const [teacherClassroomResults, setTeacherClassroomResults] = useState([]);
	const [studentRestrictionResults, setStudentRestrictionResults] = useState([]);
	
	const [addTctOpen, setAddTctOpen] = useState(false);
	const [addTeacherClassroomOpen, setAddTeacherClassroomOpen] = useState(false);
	const [addStudentRestrictionOpen, setAddStudentRestrictionOpen] = useState(false);
	
	const [changesMade, setChangesMade] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	
	const primary_secondary_options = [{value:'1', display:'Primary'}, {value:'2', display:'Back Up'}];
		
	const toggleAddNewConnection = (connection_type) => {
		if(connection_type === 'tct')
		{
			setAddTctOpen(!addTctOpen);
			setTctResults([]);
		}
		else if(connection_type === 'classroom_restriction')
		{
			setAddTeacherClassroomOpen(!addTeacherClassroomOpen);
			setTeacherClassroomResults([]);
		}
		else if(connection_type === 'student_restriction')
		{
			setAddStudentRestrictionOpen(!addStudentRestrictionOpen);
			setStudentRestrictionResults([]);
		}
	}
	
	const fuzzySearch = (e, dataType) => {
		const search_value = e.target.value;
		
		if(dataType === 'courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const course_results = results.map(result => result.item);
			
			setTctResults(course_results);
		}
		else if(dataType === 'classrooms')
		{
			const fuse = new Fuse(classrooms, {
				keys: ['classroom_name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const classroom_results = results.map(result => result.item);
			
			setTeacherClassroomResults(classroom_results);
		}
		else if(dataType === 'students')
		{
			const fuse = new Fuse(students, {
				keys: ['first_name', 'last_name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const student_results = results.map(result => result.item);
			
			setStudentRestrictionResults(student_results);
		}
	}
	
	const updateData = (change_type, data_id, data_key, tct_type = null) => {
		if(change_type === 'add')
		{
			const data_to_add = (data_key === 'teacher_can_teach') ? {course_id:data_id, type:'1'} : data_id;
			
			data[data_key].push(data_to_add)
		}
		else if(change_type === 'change')
		{
			if(data_key === 'teacher_can_teach')
			{
				data[data_key][data_id].type = tct_type;
			}
			else
			{
				data[data_key] = data_id;
			}
		}
		else if(change_type === 'remove')
		{
			data[data_key].splice(data_id, 1);
		}
		
		setAddTctOpen(false);
		setAddTeacherClassroomOpen(false);
		setAddStudentRestrictionOpen(false);
		
		setTctResults([]);
		setTeacherClassroomResults([]);
		setStudentRestrictionResults([]);
		
		setData({...data});
		setChangesMade(true);
	}
	
	const handleExitWithoutSaving = async () => {
		let exit_without_saving = true;
		
		if(changesMade)
		{
			const options =  {
				title: "Exit without saving?",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you really want to exit without saving?</p>
					</div>
				)
			}
		
			exit_without_saving = await swal(options);
		}
		
		if(exit_without_saving) toggleEditData();
	}
	
	const updateTeacher = () => {
		// Make sure teacher data is trimmed
		data.teacher_code = data.teacher_code.trim();
		data.first_name = data.first_name.trim();
		data.name = data.name.trim();

		const first_name = data.first_name;
		const name = data.name;
		
		if(!first_name || !name)
		{
			const options =  {
				title: "Missing Data",
				icon:"warning",
				buttons: {
					cancel: {
						text: "Okay",
						value: false,
						visible: true,
						className: 'blue-btn'
					}
				},
				content: (
					<div>
						<p>You are missing data! Please input all background info before saving.</p>
					</div>
				)
			}
		
			swal(options);
		}
		else
		{
			setIsSaving(true);
			
			saveDataToDatabase({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'teacher', data_to_save:data}).then(response_data => {
				// change data teacher to new database id
				data.teacher_id = response_data.new_data_id;
				
				if(dataID)
				{
					const index_of_specific_data = teachers.findIndex(teacher => teacher.teacher_id === dataID);
					teachers[index_of_specific_data] = data;

					const filter_results_index = filteredResults.findIndex(teacher => teacher.teacher_id === dataID);
					filteredResults[filter_results_index] = data;
				}
				else
				{
					teachers.push(data);
					filteredResults.push(data);
				}
				
				setDatabaseData([...teachers]);
				setFilteredResults([...filteredResults]);
				setIsSaving(false);
				toggleEditData();

				////////////////////////
				/// Update conflicts ///
				////////////////////////
				const affected_sections = sections.reduce((results, section) => {
					const section_teacher_id = section.teacher_id;
					if(section_teacher_id && section_teacher_id === data.teacher_id) results.push(section.section_id);
					return results;
				},[]);
				const conflicts_to_check = ['teacher_period_restriction', 'teacher_student_restriction'];

				if(affected_sections.length > 0) calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:affected_sections, conflicts_to_check:conflicts_to_check, update_all:false});

				// Recalculate match percent
				recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id});
			});
		}
	}
	
	/////////////////////////////
	///// FOCUS ON INPUT ////////
	/////////////////////////////
	useEffect(() => {
		if(addTctOpen) document.getElementById("search-tct-input").focus();
	}, [addTctOpen]);
	
	useEffect(() => {
		if(addTeacherClassroomOpen) document.getElementById("search-teacher-classroom-input").focus();
	}, [addTeacherClassroomOpen]);
	
	useEffect(() => {
		if(addStudentRestrictionOpen) document.getElementById("search-student-restriction-input").focus();
	}, [addStudentRestrictionOpen]);
	
	
	/////////////////////////
	///// DO ON LOAD ////////
	/////////////////////////
	useEffect(() => {
		if(dataID)
		{
			loadSpecificData({data_type:'teacher', data_id:dataID, data_version_id:schoolInfo.current_data_version_id, schedule_version_id:schoolInfo.current_schedule_version_id, school_id:schoolInfo.school_id}).then(specific_data => {
				const index_of_specific_data = teachers.findIndex(teacher => teacher.teacher_id === dataID);
				teachers[index_of_specific_data] = specific_data;

				setDatabaseData(teachers);
				setData(specific_data);
				setIsLoading(false);
			});
		}
		else
		{
			const departments = (defaultDepartmentID) ? [defaultDepartmentID] : [];

			setData({new: true, first_name: null, name: null, classroom_restrictions:[], departments:departments, period_restrictions:[], student_restrictions: [], teacher_can_teach: []});
			setIsLoading(false);
		}
	}, [dataID, defaultDepartmentID, schoolInfo.school_id, schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, teachers]);
	
	//console.log(data);
	//console.log(departments);
	const scheduleType = scheduleInfo.schedule_type;
	const period_type = (scheduleType === 'daily') ? 'schedule_periods' : 'course_periods';
	const periods_to_show = (isEmpty(scheduleStructureData)) ? {} : (period_type === 'course_periods' ? scheduleStructureData.course_periods : scheduleStructureData.schedule_periods['1']);

	let sortable = [];
	for (const period in periods_to_show) {
		const period_info = periods_to_show[period];
		const period_id = period_info.period_id;
		const period_order = (period_type === 'course_periods') ? period_info.period_name : period_info.period_order;
		const period_name = period_info.period_name;

		sortable.push({period_id: period_id, period_order: parseInt(period_order), period_name: period_name});
	}

	const periodsToShow = sortArrayOfObjects(sortable, 'period_order', 'number', 'asc');
	const availability_schedule_row_style = {gridTemplateColumns : `150px repeat(${Object.keys(scheduleStructureData.schedule_structure).length}, 1fr)`};
	
	return (
		<div className='modal-screen'>
			<div className='modal-screen-content'>
				{!isSaving &&
					<>
					<span className="data-modal-close" onClick={handleExitWithoutSaving}>&times;</span>
					<div className="modal-save-btn" onClick={updateTeacher}><FontAwesomeIcon icon={faSave}/>Save Teacher</div>
					</>
				}
				<div className='full-width'>
					<div className='edit-data-content'>
						<FontAwesomeIcon className='edit-data-main-head-icon' icon={faEdit}/>
						<h1 className='edit-data-main-header'>{dataID ? 'Edit' : 'Add New'} Teacher</h1>
						{isSaving &&
							<div className='magnetboard-screen-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
								<div>Saving...</div>
							</div>
						}
						{isLoading &&
							<div className='magnetboard-screen-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						}
						{(!isLoading && !isSaving) &&
							<form className='edit-data-form'>
								<div className='edit-data-grid-33' style={{gridTemplateColumns:'200px 1fr 1fr'}}>
									<div>
										<p className='modal-label'>Teacher Code:</p>
										<input type="text" className='edit-data-input' defaultValue={data.teacher_code} onChange={(e) => updateData('change', e.target.value, 'teacher_code')}/>
									</div>
									<div>
										<p className='modal-label'>Last Name:</p>
										<input type="text" className='edit-data-input' defaultValue={data.name} onChange={(e) => updateData('change', e.target.value, 'name')}/>
									</div>
									<div>
										<p className='modal-label'>First Name:</p>
										<input type="text" className='edit-data-input' defaultValue={data.first_name} onChange={(e) => updateData('change', e.target.value, 'first_name')}/>
									</div>
								</div>
								<div>
									<div className='data-modal-heading'>Departments</div>
									{departments.map((department_info, index) => {
										const department_id = department_info.department_id;
										const data_department_index = data.departments.indexOf(department_id);
										const data_department_bool = (data_department_index !== -1) ? true : false;
										
										if(data_department_bool)
										{
											return (
												<div className='option-btn option-btn-selected' key={index} onClick={(e) => updateData('remove', data_department_index, 'departments')}>{department_info.department}</div>
											)
										}
										else
										{
											return (
												<div className='option-btn' key={index} onClick={(e) => updateData('add', department_id, 'departments')}>{department_info.department}</div>
											)
										}
									})}
								</div>
								<div>
									<div className='data-modal-heading'>Courses this Teacher Teaches</div>
									<div>
										{data.teacher_can_teach.length > 0 &&
											<div className="edit-data-subheading edit-data-teacher-can-teach-row">
												<div><strong>Course Name</strong></div>
												<div>Primary/Back Up</div>
											</div>
										}
										{data.teacher_can_teach.length === 0 &&
											<div className='edit-data-no-data-container'>This teacher is not set to teach any courses yet!</div>
										}
										{data.teacher_can_teach.map((tct_info, index) => {
											const course_id = tct_info.course_id;
											const primary_secondary = tct_info.type;
											
											const course_info = courses.find(course => course.course_id === course_id);
											
											return (
												<div className='table-row-inv edit-data-teacher-can-teach-row' key={index}>
													<div>{course_info.name} ({course_info.course_code})</div>
													<div>
														<Dropdown data={primary_secondary_options} currentValue={primary_secondary} handleChange={(new_value) => updateData('change', index, 'teacher_can_teach', new_value)} />
													</div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'teacher_can_teach')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('tct')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Course for Teacher</div>
									</div>
									{addTctOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'courses')} placeholder='Search for course'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('tct')}/>
											</div>
											{tctResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{tctResults.map((course, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{course.name} ({course.course_code})</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', course.course_id, 'teacher_can_teach')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
								<div>
									<div className='data-modal-heading'>Teacher Classroom(s)</div>
									<div className='dark-gray-text edit-data-explanation-text'>Add classroom(s) that this teacher usually uses</div>
									<div className='edit-data-list'>
										{data.classroom_restrictions.length === 0 &&
											<div className='edit-data-no-data-container'>No classroom preferences set yet!</div>
										}
										{data.classroom_restrictions.map((classroom_id, index) => {
											const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
											
											return (
												<div className='table-row-inv edit-data-teacher-classroom-row' key={index}>
													<div>{classroom_info.classroom_name}</div>
													<div>{classroom_info.classroom_type}</div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'classroom_restrictions')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('classroom_restriction')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Teacher Classroom</div>
									</div>
									{addTeacherClassroomOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-teacher-classroom-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'classrooms')} placeholder='Search for classroom'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('classroom_restriction')}/>
											</div>
											{teacherClassroomResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{teacherClassroomResults.map((classroom, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{classroom.classroom_name}</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', classroom.classroom_id, 'classroom_restrictions')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
								<div>
									<div className='data-modal-heading'>Teacher Availability</div>
									<div className='edit-data-availability-screen'>
										<div className='edit-data-availability-select-sidebar'>
											<h3 className='edit-data-availability-sidebar-header'>Period Availability</h3>
											{periodsToShow.map(period_info => {
												const period_id = period_info.period_id;
												const period_name = period_info.period_name;
												const course_period_id = (scheduleType === 'daily') ? scheduleStructureData.schedule_structure[1].find(day_row => day_row.schedule_period_id === period_id).course_period_id : period_id;
												const course_period_index = data.period_restrictions.indexOf(course_period_id);
												
												const course_period_available = (course_period_index !== -1) ? false : true;
												
												return (
													<div className='edit-data-availability-period-option-container' key={period_id}>
														<div>{period_name}</div>
														{course_period_available &&
														<div onClick={() => updateData('add', course_period_id, 'period_restrictions')}>
															<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare}/>
														</div>
														}
														{!course_period_available &&
														<div onClick={() => updateData('remove', course_period_index, 'period_restrictions')}>
															<FontAwesomeIcon className='fas-checkbox-unchecked dark-gray-text' icon={faSquare}/>
														</div>
														}
													</div>
												)
											})}
										</div>
										<div className='edit-data-availability-display-screen'>
											<div className='edit-data-availability-schedule'>
												<div className='edit-data-availability-schedule-row' style={availability_schedule_row_style}>
													<div>Periods</div>
													{Object.keys(scheduleStructureData.schedule_structure).map((day_num, index) => {
														return (
															<div className='edit-data-availability-day-heading' key={index}>Day {day_num}</div>
														)
													})}
												</div>
												{Object.keys(scheduleStructureData.schedule_periods).map((schedule_period_id, index) => {
													const schedule_period_info = scheduleStructureData.schedule_periods[schedule_period_id];
													
													return (
														<div className='edit-data-availability-schedule-row' key={schedule_period_id} style={availability_schedule_row_style}>
															<div className='edit-data-availability-period-heading'>{schedule_period_info.period_name}</div>
															{Object.keys(scheduleStructureData.schedule_structure).map((day_num, index) => {
																const day_structure = scheduleStructureData.schedule_structure[day_num];
																const structure_row = day_structure.find(day_row => day_row.schedule_period_id === schedule_period_id);
																const course_period_id = (structure_row) ? structure_row.course_period_id : null;
																const course_period_name = (course_period_id) ? scheduleStructureData.course_periods[course_period_id].period_name : null;
																
																const unavailable = data.period_restrictions.includes(course_period_id) ? true : false;
																
																return (
																	<div className={`edit-data-availability-course-period ${unavailable ? 'edit-data-availability-course-period-unavailable' : ''}`} key={`${day_num}_${schedule_period_id}`}>
																		<div className='edit-data-availability-course-period-name'>{course_period_name}</div>
																		<div className='edit-data-availability-status-container'>
																			<div>{unavailable ? 'Unavailable' : 'Available'}</div>
																			<div className={`edit-data-availability-status ${unavailable ? 'red' : 'green'}`}></div>
																		</div>
																	</div>
																)
															})}
														</div>
													)
												})}
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className='data-modal-heading'>Teacher-Student Restrictions</div>
									<div className='dark-gray-text edit-data-explanation-text'>Add a student this teacher cannot be assigned to</div>
									<div className='edit-data-list'>
										{data.student_restrictions.length === 0 &&
											<div className='edit-data-no-data-container'>No restricted students!</div>
										}
										{data.student_restrictions.map((student_id, index) => {
											const student_info = students.find(student => student.student_id === student_id);
											
											return (
												<div className='table-row-inv edit-data-student-restriction-row' key={index}>
													<div>{student_info.last_name}, {student_info.first_name}</div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'student_restrictions')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('student_restriction')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Student Restriction</div>
									</div>
									{addStudentRestrictionOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-student-restriction-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'students')} placeholder='Search for student'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('student_restriction')}/>
											</div>
											{studentRestrictionResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{studentRestrictionResults.map((student, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{student.last_name}, {student.first_name}</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', student.student_id, 'student_restrictions')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
							</form>
						}
					</div>
				</div>
			</div>
		</div>
	);
}