import React, { useState } from 'react';
import Fuse from 'fuse.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUserGraduate, faChalkboard, faCheck } from '@fortawesome/free-solid-svg-icons';

import { capitalizeFirstLetters, sortArrayOfObjects, isEmpty } from '../../../js/Helpers';

export default function StudentSummary({ scheduleName, scheduleType, currentDay, sections, subsections, labels, teachers, students, courses, classrooms, scheduleStructureData, scheduleInfo, studentMatchPercentages, toggleEditStudentSchedule }) {
	const [filterIDs, setFilterIDs] = useState([]);
	const [maxStudentsToShow, setMaxStudentsToShow] = useState(50);
	
	const fuzzySearch = (e) => {
		const search_value = e.target.value;

		const filtered_student_ids = [];
		
		// Search courses by name or code
		const students_fuse = new Fuse(students, {
			keys: ['last_name', 'first_name'],
			threshold: .1
		});

		const student_results = students_fuse.search(search_value);
		const subset_student_results = student_results.map(result => result.item).slice(0,4);

		subset_student_results.forEach(student => {
			const student_id = student.student_id;
			filtered_student_ids.push(student_id);
		});

		setFilterIDs([...filtered_student_ids]);
	}

	const showMoreStudents = () => {
		const num_students_to_show = maxStudentsToShow + 50;
		setMaxStudentsToShow(num_students_to_show);
	}

	//////////////////////
	//// DO ON LOAD //////
	//////////////////////
	let student_counter = 1;

	const period_type = (scheduleType === 'daily') ? 'schedule_periods' : 'course_periods';
	const periods_to_show = (isEmpty(scheduleStructureData)) ? {} : (period_type === 'course_periods' ? scheduleStructureData.course_periods : scheduleStructureData.schedule_periods['1']);

	let sortable = [];
	for (const period in periods_to_show) {
		const period_info = periods_to_show[period];
		const period_id = period_info.period_id;
		const period_order = (period_type === 'course_periods') ? period_info.period_name : period_info.period_order;
		const period_name = period_info.period_name;

		sortable.push({period_id: period_id, period_order: parseInt(period_order), period_name: period_name});
	}

	const periodsToShow = sortArrayOfObjects(sortable, 'period_order', 'number', 'asc');
	const num_cols = Object.keys(periodsToShow).length;
	const row_style = {display:'grid', justifyContent:'center', gridTemplateColumns : `repeat(${num_cols}, 1fr)`};

	let summary_totals = {'partial': 0, 'full': 0, 'empty': 0, 'total':0};
	const summary_statistics = Object.keys(studentMatchPercentages).reduce((result, student_id) => {
		const student_info = students.find(student => student.student_id === student_id);

		if(!student_info) return result;
		const student_grade = student_info.grade;

		const student_match_statistics = studentMatchPercentages[student_id];
		const student_match_percent = student_match_statistics.match_percent;
		const student_percent_schedule_full = student_match_statistics.percent_schedule_full;

		if(!(student_grade in result)) result[student_grade] = {'partial': 0, 'full': 0, 'empty': 0};

		if(student_percent_schedule_full == 0) 
		{
			result[student_grade]['empty']++;
			summary_totals.empty++;
		}
		else if(student_match_percent == 1 || student_percent_schedule_full == 1)
		{
			result[student_grade]['full']++;
			summary_totals.full++;
		}
		else
		{
			result[student_grade]['partial']++;
			summary_totals.partial++;
		}

		summary_totals.total++;

		return result;
	}, {});
	
	//console.log(summary_statistics);

	return (
		<>
			<h4 className='report-schedule-name'>{scheduleName}</h4>
			<h1 className='report-name'>Student Summary</h1>
			<div className='student-summary-statistics-container'>
				<div className='dark-blue student-summary-statistics-header'>Summary Stats</div>
				<div className='student-summary-statistics-inner-container'>
					<div className='student-summary-statistics-row' style={{fontWeight:'bold'}}>
						<div>Grade</div>
						<div>No Requests</div>
						<div>Partial Schedules</div>
						<div>Full Schedules</div>
					</div>
					{Object.keys(summary_statistics).map(grade => {
						const summary_statistics_for_grade = summary_statistics[grade];

						const empty_schedules = summary_statistics_for_grade.empty;
						const partial_schedules = summary_statistics_for_grade.partial;
						const full_schedules = summary_statistics_for_grade.full;

						const total_schedules = empty_schedules + partial_schedules + full_schedules;

						return (
							<div className='student-summary-statistics-row' key={grade}>
								<div>{capitalizeFirstLetters(grade)}</div>
								<div className='student-summary-row-box'>
									<div>{empty_schedules}</div> 
									<div>({total_schedules === 0 ? 0 : Math.round(empty_schedules/total_schedules*100)}%)</div>
								</div>
								<div className='student-summary-row-box'>
									<div>{partial_schedules}</div>
									<div>({total_schedules === 0 ? 0 : Math.round(partial_schedules/total_schedules*100)}%)</div>
								</div>
								<div className='student-summary-row-box'>
									<div>{full_schedules}</div>
									<div>({total_schedules === 0 ? 0 : Math.round(full_schedules/total_schedules*100)}%)</div>
								</div>
							</div>
						)
					})}
					<div className='student-summary-statistics-row'>
						<div>Total</div>
						<div className='student-summary-row-box'>
							<div>{summary_totals.empty}</div> 
							<div>({summary_totals.total === 0 ? 0 : Math.round(summary_totals.empty/summary_totals.total*100)}%)</div>
						</div>
						<div className='student-summary-row-box'>
							<div>{summary_totals.partial}</div>
							<div>({summary_totals.total === 0 ? 0 : Math.round(summary_totals.partial/summary_totals.total*100)}%)</div>
						</div>
						<div className='student-summary-row-box'>
							<div>{summary_totals.full}</div>
							<div>({summary_totals.total === 0 ? 0 : Math.round(summary_totals.full/summary_totals.total*100)}%)</div>
						</div>
					</div>
				</div>
			</div>
			<h1 className='student-summary-secondary-header'>Student Schedules</h1>
			<div className='reports-search-bar-container'>
				<input className='reports-search-bar' placeholder={`Search for a student...`} defaultValue='' onChange={(e) => fuzzySearch(e)}/>
				<FontAwesomeIcon className='reports-search-bar-icon' icon={faSearch}/>
			</div>
			<div className='reports-main-container'>
				<div className='reports-main-inner-container'>
					{students.map((student_info, i) => {
						const student_id = student_info.student_id;
					
						if(filterIDs.length > 0 && !filterIDs.includes(student_id)) return null;
						if(student_counter > maxStudentsToShow) return null;
						student_counter++;

						const student_requests = student_info.student_requests;
						const student_requests_without_alternates = student_requests.filter(request_info => request_info.priority === '1');
						const alternate_student_requests = student_requests.filter(request_info => request_info.priority === '2');

						const student_lunch_schedule_period_id = student_info.lunch_schedule_period_id;
						const student_course_sections = sections.filter(section => section.student_list.includes(student_id));
						
						const student_match_statistics = (student_id in studentMatchPercentages) ? studentMatchPercentages[student_id] : null;
						const student_request_match_percent = (student_match_statistics) ? student_match_statistics.match_percent*100 : 0;
						const student_percent_schedule_full = (student_match_statistics) ? student_match_statistics.percent_schedule_full*100 : 0;

						const matched_student_requests = student_requests.reduce((results, request_info) => {
							const request_course_id = request_info.course_id;
							const section_match_index = sections.findIndex(section => section.course_periods.length > 0 && section.course_id === request_course_id && section.student_list.includes(student_id));
							const course_info = courses.find(course => course.course_id === request_course_id);
							const course_credits = parseFloat(course_info.credits);
					
							if(section_match_index !== -1) 
							{
								results.matched_requests++;
								results.matched_credits += course_credits;
							}
					
							results.total_credits += course_credits;
					
							return results;
						}, {'matched_requests':0, 'matched_credits':0, 'total_credits':0});

						return (
							<div className='student-summary-student-container' key={i}>
								<div className='edit-student-schedule-top-container' style={{marginTop:'25px'}}>
									<div className='edit-student-schedule-background-container'>
										<img style={{height:'40px'}} src={require("../../../images/logo-dark.png")} alt='Edario Logo' />
										<div>
											<div className='edit-student-schedule-student-name'>{student_info.last_name}, {student_info.first_name}</div>
											<div>{student_info.grade}th grade</div>
											<div className='medium-text' style={{margin:'5px 0px 0px 0px'}}>{scheduleInfo.current_schedule_year} Schedule</div>
										</div>
									</div>
									<div className='edit-student-schedule-summary-statistics-container'>
										<div className='edit-student-stats-container'>
											<h4 className='edit-student-stats-heading'>Courses</h4>
											<div><span className='edit-student-stats-numerator'>{matched_student_requests.matched_requests}</span><span className='edit-student-stats-fraction-bar'>/</span><span className='edit-student-stats-denominator'>{student_requests.length}</span></div>
											<div className='medium-text'>requests matched</div>
										</div>
										<div className='edit-student-stats-container'>
											<h4 className='edit-student-stats-heading'>Credits</h4>
											<div><span className='edit-student-stats-numerator'>{matched_student_requests.matched_credits}</span><span className='edit-student-stats-fraction-bar'>/</span><span className='edit-student-stats-denominator'>{matched_student_requests.total_credits}</span></div>
											<div className='medium-text'>credits fulfilled</div>
										</div>
										<div className='percentage-container edit-student-percentage-container'>
											<svg viewBox="0 0 36 36">
												<path className='percentage-circle-default' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
												{student_request_match_percent != 0 &&
													<path className='percentage-circle-inner' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" stroke="#ff9f00" strokeDasharray={`${student_request_match_percent}, 100`}/>
												}
											</svg>
											<div className='percentage-text-container'>
												<div className='percent-text'>{student_request_match_percent}%</div>
												<div className='percent-subtext'>requests</div>
											</div>
										</div>
										<div className='percentage-container edit-student-percentage-container'>
											<svg viewBox="0 0 36 36">
												<path className='percentage-circle-default' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
												{student_percent_schedule_full != 0 &&
													<path className='percentage-circle-inner' d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" stroke="#2ecc71" strokeDasharray={`${student_percent_schedule_full}, 100`}/>
												}
											</svg>
											<div className='percentage-text-container'>
												<div className='percent-text'>{student_percent_schedule_full}%</div>
												<div className='percent-subtext'>day full</div>
											</div>
										</div>
									</div>
								</div>
								<div className='blue-link medium-text' style={{marginBottom:'10px'}} onClick={() => toggleEditStudentSchedule(student_id)}>Edit Student Schedule</div>
								<div className='mb-row mb-middle-header student-summary-header' style={row_style}>
									{Object.keys(periodsToShow).map((period_id, index) => {
										const period_name = periodsToShow[period_id].period_name;
										
										return (
											<div className='mb-inner-box' style={{padding:'10px'}} key={index}><div>{period_name}</div></div>
										)
									})}
								</div>
								<div className='mb-row mb-schedule-row' style={row_style}>
									{Object.keys(periodsToShow).map((period_id, index) => {
										const course_period_id = (scheduleType === 'daily') ? scheduleStructureData.schedule_structure[currentDay].find(day_sp => day_sp.schedule_period_id === period_id).course_period_id : period_id;
										const course_period_sections = student_course_sections.filter(section => section.course_periods.includes(course_period_id));
										const is_student_lunch_period = (scheduleType === 'daily' && period_id === student_lunch_schedule_period_id) ? true : false;

										const course_period_sections_sorted_by_quarter = course_period_sections.sort((section_A, section_B) => {
											const section_A_min_quarter = section_A.quarter_days.reduce((result, quarter_day) => {
												if(quarter_day.quarter < result) result = quarter_day.quarter;
												return result;
											}, '999');

											const section_B_min_quarter = section_B.quarter_days.reduce((result, quarter_day) => {
												if(quarter_day.quarter < result) result = quarter_day.quarter;
												return result;
											}, '999');

											if(section_A_min_quarter < section_B_min_quarter) return -1;
											return 1;
										});
										
										return (
											<div className='mb-inner-box mb-schedule-inner-box' key={index} data-courseperiodid={course_period_id}>
												{is_student_lunch_period &&
													<div className='mb-student-section'>
														<div className='mb-section-top-bar pastel-yellow'>
															<div>Lunch</div>
														</div>
														<div className='mb-section-content'>
															<div className='tooltip' data-tooltip-text='Lunch'>
																<div className='mb-section-name ellipsis'>Lunch</div>
															</div>
														</div>
													</div>
												}
												{course_period_sections_sorted_by_quarter.map((section_info, index) => {
													let section_id = section_info.section_id;

													let main_section_id = section_id;
													let main_section_info = section_info;

													let teacher_id = null;
													let teacher_info = null;

													// If section is subsection, get main section info
													const section_is_subsection_index = subsections.findIndex(subsection_section => subsection_section.subsection_id === section_id);
													const section_is_subsection = (section_is_subsection_index !== -1) ? true : false;
													if(section_is_subsection)
													{
														main_section_id = subsections[section_is_subsection_index].section_id;
														main_section_info = sections.find(section => section.section_id === main_section_id);
													}

													const student_in_section = (student_course_sections.findIndex(section => section.section_id === main_section_id) !== -1) ? true : false;
													teacher_id = (student_in_section) ? main_section_info.teacher_id : null;
													teacher_info = (teacher_id) ? teachers.find(teacher => teacher.teacher_id === teacher_id) : null;

													const course_id = main_section_info.course_id;
													const classroom_id = main_section_info.classroom_id;
													const is_lab = main_section_info.is_lab;

													const quarter_days = main_section_info.quarter_days;
													const quarter_1_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '1') !== -1) ? true : false;
													const quarter_2_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '2') !== -1) ? true : false;
													const quarter_3_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '3') !== -1) ? true : false;
													const quarter_4_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '4') !== -1) ? true : false;
													
													// Get num students from all subsections too
													const section_subsections = ('subsections' in main_section_info) ? main_section_info.subsections : [];
													
													let num_students = (section_subsections.length === 0) ? main_section_info.student_list.length : section_subsections.reduce((total_students, subsection_id) => {
														const subsection_info = sections.find(section => section.section_id === subsection_id);
														if(!subsection_info) return total_students; 
															
														const subsection_num_students = sections.find(section => section.section_id === subsection_id).student_list.length;
														return total_students + subsection_num_students;
													}, main_section_info.student_list.length);
													
													const course_info = courses.find(course => course.course_id === course_id);
													const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
													
													const section_color = (is_lab === '1') ? 'green' : '';
													
													return (
														<div className='mb-student-section' key={section_id} data-sectionid={section_id}>
															<div className={`mb-section-top-bar ${section_color}`}>
																<div>{is_lab === '1' ? "Lab" : ''}</div>
															</div>
															<div className='mb-section-content'>
																<div className='tooltip' data-tooltip-text={`${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})`}>
																	<div className='mb-section-name ellipsis'>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
																</div>
																{section_subsections.map(subsection_id => {
																	const section_subsection_info = sections.find(section => section.section_id === subsection_id);
																	if(!section_subsection_info) return null;
																	const subsection_course_id = section_subsection_info.course_id;
																	const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);

																	if(!teacher_id)
																	{
																		const student_in_section = (student_course_sections.findIndex(section => section.section_id === subsection_id) !== -1) ? true : false;
																		teacher_id = (student_in_section) ? section_subsection_info.teacher_id : null;
																		teacher_info = (teacher_id) ? teachers.find(teacher => teacher.teacher_id === teacher_id) : null;
																	}

																	// Check if subsection is placeholder inclusion course (for schools without separate ICR codes)
																	const subsection_is_inclusion = section_subsection_info.is_inclusion;
																	let is_placeholder_inclusion = false;
																	if(scheduleInfo.separate_ICR_codes === '0' && course_id === subsection_course_id && subsection_is_inclusion === '1') is_placeholder_inclusion = true;

																	return (
																		<React.Fragment key={subsection_id}>
																		{is_placeholder_inclusion ?
																			(
																				<div className='orange-text'>Inclusion</div>
																			):
																			(
																				<div className='tooltip' data-tooltip-text={subsection_course_info && `${capitalizeFirstLetters(subsection_course_info.name)} (${subsection_course_info.course_code})`}	>
																					<div className='mb-section-name ellipsis blue-text'>
																						{subsection_course_info &&
																							<span>{capitalizeFirstLetters(subsection_course_info.name)} ({subsection_course_info.course_code})</span>
																						}
																					</div>
																				</div>
																			)
																		}
																		</React.Fragment>
																	)
																})}
																{teacher_info &&
																	<div className='dark-gray-text'>{teacher_info.name}, {teacher_info.first_name}</div>
																}
																<div className='mb-section-quarter-day-container'>
																	<div className={`mb-section-quarter-day ${quarter_1_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q1</div>
																	<div className={`mb-section-quarter-day ${quarter_2_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q2</div>
																	<div className={`mb-section-quarter-day ${quarter_3_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q3</div>
																	<div className={`mb-section-quarter-day ${quarter_4_bool ? 'mb-section-quarter-day-selected' : ''}`}>Q4</div>
																</div>
																<div className='mb-section-subname-container'>
																	<div className='mb-section-subname'><FontAwesomeIcon icon={faChalkboard}/>{classroom_info ? classroom_info.classroom_name : '--'}</div>
																	<div className='mb-section-students'><FontAwesomeIcon icon={faUserGraduate}/> {num_students}</div>
																</div>
															</div>
														</div>
													)
												})}
											</div>
										)
									})}
								</div>
								<div className='student-summary-request-container'>
									<div className='edit-student-schedule-heading'>Course Requests</div>
									<div id='edit-student-schedule-student-request-list'>
										{student_requests_without_alternates.length === 0 &&
											<div className='edit-data-no-data-container'>No courses have been requested yet!</div>
										}
										{student_requests_without_alternates.map((request_info, index) => {
											const request_course_id = request_info.course_id;
											const matched = student_course_sections.findIndex(student_section => student_section.course_periods.length > 0 && student_section.course_id === request_course_id) !== -1;
											const data_origin = request_info.data_origin;
											const is_deleted = (request_info.is_deleted === '1') ? true : false;
											const course_info = courses.find(course => course.course_id === request_course_id);

											if(!course_info) return null;

											const course_labels = student_info.student_labels.filter(label => label.course_id === request_course_id);
											
											return (
												<div className='student-summary-student-request-row medium-text' key={index}>
													<div className={`edit-student-schedule-student-requent-course-name ${is_deleted && 'disabled-row'}`}>
														<div>
															<div>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
															{data_origin === '2' && <div className='small-text yellow-text'>Added in Edario</div>}
														</div>
														<div>
															{course_labels.map((course_label, i) => {
																const label_id = course_label.label_id;
																const label_info = labels.find(label => label.label_id === label_id);
																
																if(!label_info) return null;

																const is_team = (label_info.is_team === '1') ? true : false;

																return (
																	<div className={`edit-student-schedule-request-label ${is_team ? 'pastel-blue-text' : 'pastel-orange-text'}`} key={i}>{label_info.label}</div>
																)
															})}
														</div>
													</div>
													{is_deleted ?
														(
															<div className='red-text small-text opacity-50'>Deleted</div>
														):
														(
															<div className={`${matched ? 'green-text' : 'gray-text'}`}>
																{!matched ? "Not " : ''}Matched {matched ? <FontAwesomeIcon icon={faCheck} style={{marginLeft:'6px'}}/> : ''}
																<div className={`small-text ${matched ? 'green-text' : 'gray-text'}`}>{course_info.credits} credits</div>
															</div>
														)
													}
												</div>
											)	
										})}
										<div className='edit-student-schedule-heading' style={{marginTop:'10px'}}>Alternates</div>
										{alternate_student_requests.length === 0 &&
											<div className='edit-data-no-data-container'>No alternates have been set yet!</div>
										}
										{alternate_student_requests.map((request_info, index) => {
											const request_course_id = request_info.course_id;
											const matched = student_course_sections.findIndex(student_section => student_section.course_periods.length > 0 && student_section.course_id === request_course_id) !== -1;
											const data_origin = request_info.data_origin;
											const is_deleted = (request_info.is_deleted === '1') ? true : false;
											const course_info = courses.find(course => course.course_id === request_course_id);

											if(!course_info) return null;

											const course_labels = student_info.student_labels.filter(label => label.course_id === request_course_id);
											
											return (
												<div className='student-summary-student-request-row medium-text' key={index}>
													<div className={`edit-student-schedule-student-requent-course-name ${is_deleted && 'disabled-row'}`}>
														<div>
															<div>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
															{data_origin === '2' && <div className='small-text yellow-text'>Added in Edario</div>}
														</div>
														<div>
															{course_labels.map((course_label, i) => {
																const label_id = course_label.label_id;
																const label_info = labels.find(label => label.label_id === label_id);
																
																if(!label_info) return null;

																const is_team = (label_info.is_team === '1') ? true : false;

																return (
																	<div className={`edit-student-schedule-request-label ${is_team ? 'pastel-blue-text' : 'pastel-orange-text'}`} key={i}>{label_info.label}</div>
																)
															})}
														</div>
													</div>
													{is_deleted ?
														(
															<div className='red-text small-text opacity-50'>Deleted</div>
														):
														(
															<div className={`${matched ? 'green-text' : 'gray-text'}`}>
																{!matched ? "Not " : ''}Matched {matched ? <FontAwesomeIcon icon={faCheck} style={{marginLeft:'6px'}}/> : ''}
																<div className={`small-text ${matched ? 'green-text' : 'gray-text'}`}>{course_info.credits} credits</div>
															</div>
														)
													}
												</div>
											)	
										})}
									</div>
								</div>
							</div>
						)
					})}
					{(student_counter > maxStudentsToShow) && 
						<div className='btn blue-btn load-more-btn' onClick={showMoreStudents}>Load More Students</div>
					}
				</div>
			</div>
		</>
	);
}