import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarTimes, faChalkboard, faBook, faUsers, faChalkboardTeacher, faExclamationTriangle, faUserGraduate } from '@fortawesome/free-solid-svg-icons';

import { capitalizeFirstLetters, getConflicts, updateConflicts } from '../../../js/Helpers';

export default function Conflicts({ schoolInfo, scheduleName, scheduleType, conflicts, setConflicts, sections, subsections, teachers, students, courses, classrooms, scheduleStructureData, toggleEditStudentSchedule, currentView, currentViewType, currentDay, changeCurrentView, changeCurrentViewType, changeCurrentDay, setSearchValue }) {
	const [currentPage, setCurrentPage] = useState('conflicts');

	const filterToData = (data_type, data_name, day = null) => {
		if(currentView !== data_type) changeCurrentView(data_type);
		if(scheduleType !== 'daily') 
		{
			if(day && currentViewType === 'period') changeCurrentViewType('day');
			if(day && currentDay !== day) changeCurrentDay(day);
			if(!day) changeCurrentViewType('period');
		}
		
		setSearchValue(data_name);
	}

	const toggleIgnoreConflict = (conflict_id) => {
		const conflict_index = conflicts.findIndex(conflict => conflict.conflict_id === conflict_id);
		if(conflict_index === -1) return;

		const is_deleted = conflicts[conflict_index].is_deleted;
		const new_is_deleted = (is_deleted === '0') ? '1' : '0';

		conflicts[conflict_index].is_deleted = new_is_deleted;

		setConflicts([...conflicts]);

		// Send to back end to take care of it
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, changes_made:[{change_made:'toggle_conflict', conflict_id:conflict_id, is_deleted:new_is_deleted}]};
		updateConflicts(data).then(status => {
			getConflicts(data).then(response_data => {
				const new_conflicts = response_data.conflicts;
				setConflicts([...new_conflicts]);
			});
		});
	}

	//////////////////////////////
	///// DO ON STATE CHANGE /////
	//////////////////////////////

	////////////////
	/// TEACHERS ///
	////////////////
	// conflicts
	const teachers_double_booked = conflicts.filter(conflict => conflict.type === 'teacher_double_booked');
	const teachers_conflicting_periods = conflicts.filter(conflict => conflict.type === 'teacher_conflicting_periods');

	// warnings
	const teachers_missing = conflicts.filter(conflict => conflict.type === 'teacher_missing');
	const teachers_period_restrictions = conflicts.filter(conflict => conflict.type === 'teacher_period_restriction');
	const teachers_too_many_sections = conflicts.filter(conflict => conflict.type === 'teacher_too_many_sections');
	const teachers_too_many_sections_in_a_row = conflicts.filter(conflict => conflict.type === 'teacher_too_many_sections_in_a_row');

	////////////////
	/// STUDENTS ///
	////////////////
	// conflicts
	const students_double_booked = conflicts.filter(conflict => conflict.type === 'student_double_booked');
	const students_conflicting_periods = conflicts.filter(conflict => conflict.type === 'student_conflicting_periods');

	// warnings
	const students_teacher_restrictions = conflicts.filter(conflict => conflict.type === 'teacher_student_restriction');
	const students_student_restrictions = conflicts.filter(conflict => conflict.type === 'student_student_restriction');

	//////////////////
	/// CLASSROOMS ///
	//////////////////
	// conflicts
	const classrooms_double_booked = conflicts.filter(conflict => conflict.type === 'classroom_double_booked');
	const classrooms_conflicting_periods = conflicts.filter(conflict => conflict.type === 'classroom_conflicting_periods');

	// warnings
	const classrooms_missing = conflicts.filter(conflict => conflict.type === 'classroom_missing');

	///////////////
	/// COURSES ///
	///////////////

	/// warnings
	const courses_exceed_capacity = conflicts.filter(conflict => conflict.type === 'course_exceeds_capacity');
	const courses_period_restrictions = conflicts.filter(conflict => conflict.type === 'course_period_restriction');
	const courses_outside_normal_room = conflicts.filter(conflict => conflict.type === 'course_outside_normal_room');
	const courses_inclusion_too_many_students = conflicts.filter(conflict => conflict.type === 'inclusion_too_many_students');
	const courses_inclusion_over_half = conflicts.filter(conflict => conflict.type === 'inclusion_over_half');

	/////////////////////////
	/// CALCULATE TOTALS ///
	/////////////////////////
	/// TOTAL Conflicts ///
	/// Calculate total num of each type of conflict (NON IGNORED)
	const num_teacher_conflicts = teachers_double_booked.filter(conflict => conflict.is_deleted === '0').length + teachers_conflicting_periods.filter(conflict => conflict.is_deleted === '0').length;
	const num_student_conflicts = students_double_booked.filter(conflict => conflict.is_deleted === '0').length + students_conflicting_periods.filter(conflict => conflict.is_deleted === '0').length;
	const num_classroom_conflicts = classrooms_double_booked.filter(conflict => conflict.is_deleted === '0').length + classrooms_conflicting_periods.filter(conflict => conflict.is_deleted === '0').length;
	const num_conflicts = num_teacher_conflicts + num_student_conflicts + num_classroom_conflicts;

	/// Calculate total num of each type of conflict (IGNORED)
	const num_ignored_teacher_conflicts = teachers_double_booked.filter(conflict => conflict.is_deleted === '1').length + teachers_conflicting_periods.filter(conflict => conflict.is_deleted === '1').length;
	const num_ignored_student_conflicts = students_double_booked.filter(conflict => conflict.is_deleted === '1').length + students_conflicting_periods.filter(conflict => conflict.is_deleted === '1').length;
	const num_ignored_classroom_conflicts = classrooms_double_booked.filter(conflict => conflict.is_deleted === '1').length + classrooms_conflicting_periods.filter(conflict => conflict.is_deleted === '1').length;
	const num_ignored_conflicts = num_ignored_teacher_conflicts + num_ignored_student_conflicts + num_ignored_classroom_conflicts;

	/// TOTAL Warnings ///
	/// Calculate total num of each type of warnings (NON IGNORED)
	const num_teacher_warnings = teachers_missing.filter(conflict => conflict.is_deleted === '0').length + teachers_period_restrictions.filter(conflict => conflict.is_deleted === '0').length + teachers_too_many_sections.filter(conflict => conflict.is_deleted === '0').length + teachers_too_many_sections_in_a_row.filter(conflict => conflict.is_deleted === '0').length;
	const num_student_warnings = students_teacher_restrictions.filter(conflict => conflict.is_deleted === '0').length + students_student_restrictions.filter(conflict => conflict.is_deleted === '0').length;
	const num_course_warnings = courses_exceed_capacity.filter(conflict => conflict.is_deleted === '0').length + courses_period_restrictions.filter(conflict => conflict.is_deleted === '0').length + courses_outside_normal_room.filter(conflict => conflict.is_deleted === '0').length + courses_inclusion_too_many_students.filter(conflict => conflict.is_deleted === '0').length + courses_inclusion_over_half.filter(conflict => conflict.is_deleted === '0').length;
	const num_classroom_warnings = classrooms_missing.filter(conflict => conflict.is_deleted === '0').length;
	const num_warnings = num_teacher_warnings + num_student_warnings + num_course_warnings + num_classroom_warnings;

	/// Calculate total num of each type of warnings (IGNORED)
	const num_ignored_teacher_warnings = teachers_missing.filter(conflict => conflict.is_deleted === '1').length + teachers_period_restrictions.filter(conflict => conflict.is_deleted === '1').length + teachers_too_many_sections.filter(conflict => conflict.is_deleted === '1').length + teachers_too_many_sections_in_a_row.filter(conflict => conflict.is_deleted === '1').length;
	const num_ignored_student_warnings = students_teacher_restrictions.filter(conflict => conflict.is_deleted === '1').length + students_student_restrictions.filter(conflict => conflict.is_deleted === '1').length;
	const num_ignored_course_warnings = courses_exceed_capacity.filter(conflict => conflict.is_deleted === '1').length + courses_period_restrictions.filter(conflict => conflict.is_deleted === '1').length + courses_outside_normal_room.filter(conflict => conflict.is_deleted === '1').length + courses_inclusion_too_many_students.filter(conflict => conflict.is_deleted === '1').length + courses_inclusion_over_half.filter(conflict => conflict.is_deleted === '1').length;
	const num_ignored_classroom_warnings = classrooms_missing.filter(conflict => conflict.is_deleted === '1').length;
	const num_ignored_warnings = num_ignored_teacher_warnings + num_ignored_student_warnings + num_ignored_course_warnings + num_ignored_classroom_warnings;

	//console.log({conflicts, warnings});
	
	return (
		<>
			<h4 className='report-schedule-name'>{scheduleName}</h4>
			<h1 className='report-name'>Conflicts/Warnings</h1>
			<div className='reports-main-container' style={{margin:'60px 0px 0px 0px'}}>
				<div className={`conflicts-tab medium-text ${currentPage === 'conflicts' ? 'conflicts-selected-tab' : ''}`} onClick={() => setCurrentPage('conflicts')}>Conflicts <span className='mb-conflict-counter'>{num_conflicts}</span></div>
				<div className={`warnings-tab medium-text ${currentPage === 'warnings' ? 'conflicts-selected-tab' : ''}`} onClick={() => setCurrentPage('warnings')}>Warnings <span className='mb-conflict-counter'>{num_warnings}</span></div>
				<div className={`ignored-conflicts-tab medium-text ${currentPage === 'ignored-conflicts' ? 'conflicts-selected-tab' : ''}`} onClick={() => setCurrentPage('ignored-conflicts')}>Ignored Conflicts <span className='mb-conflict-counter'>{num_ignored_conflicts}</span></div>
				<div className={`ignored-warnings-tab medium-text ${currentPage === 'ignored-warnings' ? 'conflicts-selected-tab' : ''}`} onClick={() => setCurrentPage('ignored-warnings')}>Ignored Warnings <span className='mb-conflict-counter'>{num_ignored_warnings}</span></div>
				<div className='reports-main-inner-container' style={{width:'680px'}}>
					{(currentPage === 'conflicts') ?
						(
							<>
							<div className='conflicts-type-container'>
								<h3>Teacher Conflicts</h3>
								{num_teacher_conflicts === 0 &&
									<div>There are no current teacher conflicts!</div>
								}
								{teachers_double_booked.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_periods = (section1_info) ? section1_info.course_periods : [];
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									return (
										<div className='conflict-row left-border-purple' key={`teacher-double-booked-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', teacher_last_name)}>{teacher_full_name}</span> is double booked!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faCalendarTimes}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

																return (
																	<div key={i}>{section_course_name}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
								{teachers_conflicting_periods.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									return (
										<div className='conflict-row left-border-purple' key={`teacher-conflicting-periods-${i}`} data-id={conflict_id}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', teacher_last_name)}>{teacher_full_name}</span> has a schedule with conflicting times!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faExclamationTriangle}/>
														<div>This teacher is scheduled in periods that conflict with each other.</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;
																const section_course_periods = (section_info) ? section_info.course_periods : [];

																let period_names = [];
																for(const course_period_id of section_course_periods)
																{	
																	if(scheduleType !== 'daily')
																	{
																		period_names.push(scheduleStructureData.course_periods[course_period_id].period_name);
																	}
																	else
																	{
																		const schedule_period_id = scheduleStructureData.course_periods[course_period_id]['days']['1'];
																		period_names.push(scheduleStructureData.schedule_periods[schedule_period_id].period_name);
																	}
																}
																const period_names_display = period_names.join(", ");

																return (
																	<div key={i}>{section_course_name} in {period_names_display}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
							</div>
							<div className='conflicts-type-container'>
								<h3>Student Conflicts</h3>
								{num_student_conflicts === 0 &&
									<div>There are no current student conflicts!</div>
								}
								{students_double_booked.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_students = conflict.students;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);

									const student_id = conflict_students[0];
									const course_periods = (section1_info) ? section1_info.course_periods : [];

									const student_info = students.find(student => student.student_id === student_id);
									const student_first_name = (student_info) ? student_info.first_name : null;
									const student_last_name = (student_info) ? student_info.last_name : null;
									const student_full_name = (student_first_name) ? `${student_last_name}, ${student_first_name}` : student_last_name;

									return (
										<div className='conflict-row left-border-blue' key={`student-double-booked-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => toggleEditStudentSchedule(student_id)}>{student_full_name}</span> is double booked!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faCalendarTimes}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

																return (
																	<div key={i}>{section_course_name}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
								{students_conflicting_periods.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_students = conflict.students;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const student_id = conflict_students[0];

									const student_info = students.find(student => student.student_id === student_id);
									const student_first_name = (student_info) ? student_info.first_name : null;
									const student_last_name = (student_info) ? student_info.last_name : null;
									const student_full_name = (student_first_name) ? `${student_last_name}, ${student_first_name}` : student_last_name;

									return (
										<div className='conflict-row left-border-blue' key={`student-conflicting-period-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => toggleEditStudentSchedule(student_id)}>{student_full_name}</span> has a schedule with conflicting times!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faExclamationTriangle}/>
														<div>This student is scheduled in periods that conflict with each other.</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faClock}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;
																const section_course_periods = (section_info) ? section_info.course_periods : [];

																let period_names = [];
																for(const course_period_id of section_course_periods)
																{	
																	if(scheduleType !== 'daily')
																	{
																		period_names.push(scheduleStructureData.course_periods[course_period_id].period_name);
																	}
																	else
																	{
																		const schedule_period_id = scheduleStructureData.course_periods[course_period_id]['days']['1'];
																		period_names.push(scheduleStructureData.schedule_periods[schedule_period_id].period_name);
																	}
																}
																const period_names_display = period_names.join(", ");

																return (
																	<div key={i}>{section_course_name} in {period_names_display}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
							</div>
							<div className='conflicts-type-container'>
								<h3>Classroom Conflicts</h3>
								{num_classroom_conflicts === 0 &&
									<div>There are no current classroom conflicts!</div>
								}
								{classrooms_double_booked.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_periods = (section1_info) ? section1_info.course_periods : [];
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : null;

									return (
										<div className='conflict-row left-border-yellow' key={`classroom-double-booked-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('classroom', classroom_name)}>{classroom_name}</span> is double booked!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faCalendarTimes}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

																return (
																	<div key={i}>{section_course_name}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
								{classrooms_conflicting_periods.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : null;

									return (
										<div className='conflict-row left-border-yellow' key={`classroom-conflicting-periods-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('classroom', classroom_name)}>{classroom_name}</span> has a schedule with conflicting times!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faExclamationTriangle}/>
														<div>This classroom is scheduled in periods that conflict with each other.</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faClock}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;
																const section_course_periods = (section_info) ? section_info.course_periods : [];

																let period_names = [];
																for(const course_period_id of section_course_periods)
																{	
																	if(scheduleType !== 'daily')
																	{
																		period_names.push(scheduleStructureData.course_periods[course_period_id].period_name);
																	}
																	else
																	{
																		const schedule_period_id = scheduleStructureData.course_periods[course_period_id]['days']['1'];
																		period_names.push(scheduleStructureData.schedule_periods[schedule_period_id].period_name);
																	}
																}
																const period_names_display = period_names.join(", ");

																return (
																	<div key={i}>{section_course_name} in {period_names_display}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
							</div>
							</>
						):(currentPage === 'warnings') ?
						(
							<>
							<div className='conflicts-type-container'>
								<h3>Teacher Warnings</h3>
								{num_teacher_warnings === 0 &&
									<div>There are no current teacher warnings!</div>
								}
								{teachers_missing.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section_id = conflict_sections[0];
									const section_index = sections.findIndex(section => section.section_id === section_id);
									const section_info = (section_index !== -1) ? sections[section_index] : null;
									const section_course_id = (section_info) ? section_info.course_id : null;
									const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
									const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
									const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

									const course_periods = (section_info) ? section_info.course_periods : [];
									const classroom_id = (section_info) ? section_info.classroom_id : null;
									const classroom_info = (classroom_id) ? classrooms.find(classroom => classroom.classroom_id === classroom_id) : null;

									return (
										<div className='conflict-row left-border-purple' key={`teacher-missing-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('classroom', section_course_info.name)}>{section_course_name}</span> is missing a teacher!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faChalkboard}/>
														<div>{classroom_id ? classroom_info.classroom_name : '--'}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{teachers_period_restrictions.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');
									
									const section_id = conflict_sections[0];
									const section_index = sections.findIndex(section => section.section_id === section_id);
									const section_info = (section_index !== -1) ? sections[section_index] : null;
									const section_course_id = (section_info) ? section_info.course_id : null;
									const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
									const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
									const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

									const course_periods = (section_info) ? section_info.course_periods : [];
									const teacher_id = (section_info) ? section_info.teacher_id : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									return (
										<div className='conflict-row left-border-purple' key={`teacher-period-restriction-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', teacher_last_name)}>{teacher_full_name}</span> is in a restricted period!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faBook}/>
														<div>{section_course_name}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{teachers_too_many_sections.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const total_classes = conflict_sections.length;

									return (
										<div className='conflict-row left-border-purple' data-id={conflict_id} key={`teacher-too-many-sections-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', teacher_last_name)}>{teacher_full_name}</span> is teaching too many courses!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faExclamationTriangle}/>
														<div>Teaching {total_classes} classes</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;
																const section_course_periods = (section_info) ? section_info.course_periods : [];

																let period_names = [];
																for(const course_period_id of section_course_periods)
																{	
																	if(scheduleType !== 'daily')
																	{
																		period_names.push(scheduleStructureData.course_periods[course_period_id].period_name);
																	}
																	else
																	{
																		const schedule_period_id = scheduleStructureData.course_periods[course_period_id]['days']['1'];
																		period_names.push(scheduleStructureData.schedule_periods[schedule_period_id].period_name);
																	}
																}
																const period_names_display = period_names.join(", ");

																return (
																	<div key={i}>{section_course_name} in {period_names_display}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{teachers_too_many_sections_in_a_row.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);

									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const num_in_a_row = conflict_sections.length;

									return (
										<div className='conflict-row left-border-purple' key={`teacher-too-many-in-a-row-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', teacher_last_name, conflict.day)}>{teacher_full_name}</span> has too many classes in a row!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faExclamationTriangle}/>
														<div>Teaching {num_in_a_row} classes in a row</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;
																const section_course_periods = (section_info) ? section_info.course_periods : [];

																let period_names = [];
																for(const course_period_id of section_course_periods)
																{	
																	if(scheduleType !== 'daily')
																	{
																		period_names.push(scheduleStructureData.course_periods[course_period_id].period_name);
																	}
																	else
																	{
																		const schedule_period_id = scheduleStructureData.course_periods[course_period_id]['days']['1'];
																		period_names.push(scheduleStructureData.schedule_periods[schedule_period_id].period_name);
																	}
																}
																const period_names_display = period_names.join(", ");

																return (
																	<div key={i}>{section_course_name} in {period_names_display}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
							</div>
							<div className='conflicts-type-container'>
								<h3>Student Warnings</h3>
								{num_student_warnings === 0 &&
									<div>There are no current student warnings!</div>
								}
								{students_teacher_restrictions.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_students = conflict.students;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = section1_info.course_id;
									const course_periods = (section1_info) ? section1_info.course_periods : [];
									
									const student_id = conflict_students[0];
									const student_info = students.find(student => student.student_id === student_id);
									const student_first_name = (student_info) ? student_info.first_name : null;
									const student_last_name = (student_info) ? student_info.last_name : null;
									const student_full_name = (student_first_name) ? `${student_last_name}, ${student_first_name}` : student_last_name;

									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;
									
									const course_info = (course_id) ? courses.find(course => course.course_id === course_id) : null;

									return (
										<div className='conflict-row left-border-blue' key={i}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => toggleEditStudentSchedule(student_id)}>{student_full_name}</span> is with restricted teacher!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faBook}/>
														<div>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faChalkboardTeacher}/>
														<div>{teacher_full_name}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{students_student_restrictions.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_students = conflict.students;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = (section1_info) ? section1_info.course_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];
									
									const course_info = (course_id) ? courses.find(course => course.course_id === course_id) : null;

									return (
										<div className='conflict-row left-border-blue' key={i}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'>Two restricted students are in the same class!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faBook}/>
														<div>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faUserGraduate}/>
														<div>
															{conflict_students.map((student_id, i) => {
																const student_info = students.find(student => student.student_id === student_id);
																const student_first_name = (student_info) ? student_info.first_name : null;
																const student_last_name = (student_info) ? student_info.last_name : null;
																const student_full_name = (student_first_name) ? `${student_first_name}, ${student_last_name}` : student_last_name;

																return (
																	<div className='blue-link' onClick={() => toggleEditStudentSchedule(student_id)}>{student_full_name}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
							</div>
							<div className='conflicts-type-container'>
								<h3>Course Warnings</h3>
								{num_course_warnings === 0 &&
									<div>There are no current course warnings!</div>
								}
								{courses_period_restrictions.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = (section1_info) ? section1_info.course_id : null;
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];

									const course_info = courses.find(course => course.course_id === course_id);
									const course_name = (course_info) ? `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})` : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : "--";

									return (
										<div className='conflict-row left-border-green' key={`course-period-restriction-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={teacher_id ? () => filterToData('teacher', teacher_last_name) : classroom_id ? () => filterToData('classroom', classroom_name) : ()=>{}}>{course_name}</span> is in a restricted period!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													{teacher_id &&
														<div className='conflict-info-row'>
															<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboardTeacher}/>
															<div>{teacher_full_name}</div>
														</div>
													}
													{classroom_id &&
														<div className='conflict-info-row'>
															<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboard}/>
															<div>{classroom_name}</div>
														</div>
													}
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{courses_outside_normal_room.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = (section1_info) ? section1_info.course_id : null;
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];

									const course_info = courses.find(course => course.course_id === course_id);
									const course_name = (course_info) ? `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})` : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : "--";

									return (
										<div className='conflict-row left-border-green' key={`course-outside-normal-room-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={teacher_id ? () => filterToData('teacher', teacher_last_name) : classroom_id ? () => filterToData('classroom', classroom_name) : ()=>{}}>{course_name}</span> is not in its normal classroom!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboardTeacher}/>
														<div>{teacher_id ? teacher_full_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboard}/>
														<div>{classroom_id ? classroom_info.classroom_name : '--'}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{courses_exceed_capacity.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = (section1_info) ? section1_info.course_id : null;
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];

									const course_info = courses.find(course => course.course_id === course_id);
									const course_name = (course_info) ? `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})` : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : "--";

									const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section1_id);
									const is_subsection = (subsection_index !== -1) ? true : false;

									const max_class_size = course_info.max_class_size;
									const current_class_size = (is_subsection) ? section1_info.student_list.length : section1_info.subsections.reduce((results, subsection_id) => {
										const subsection_info = sections.find(section => section.section_id === subsection_id);
										const subsection_student_list = subsection_info.student_list;
										return results += subsection_student_list.length;
									}, section1_info.student_list.length);

									return (
										<div className='conflict-row left-border-green' key={`course-exceeds-capacity-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={teacher_id ? () => filterToData('teacher', teacher_last_name) : classroom_id ? () => filterToData('classroom', classroom_name) : ()=>{}}>{course_name}</span> has too many students!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboardTeacher}/>
														<div>{teacher_id ? teacher_full_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboard}/>
														<div>{classroom_id ? classroom_info.classroom_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faUsers}/>
														<div>{current_class_size} out of {max_class_size}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{courses_inclusion_too_many_students.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = (section1_info) ? section1_info.course_id : null;
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];

									const course_info = courses.find(course => course.course_id === course_id);
									const course_name = (course_info) ? `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})` : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : "--";

									const current_num_inclusion_students = section1_info.student_list.length;

									return (
										<div className='conflict-row left-border-green' key={`course-exceeds-capacity-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={teacher_id ? () => filterToData('teacher', teacher_last_name) : classroom_id ? () => filterToData('classroom', classroom_name) : ()=>{}}>{course_name}</span> has more than 10 inclusion students!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboardTeacher}/>
														<div>{teacher_id ? teacher_full_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboard}/>
														<div>{classroom_id ? classroom_info.classroom_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faUsers}/>
														<div>Has {current_num_inclusion_students} inclusion students</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{courses_inclusion_over_half.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const subsection_info = subsections.find(subsection => subsection.subsection_id === section1_id);
									const main_section_id = (subsection_info) ? subsection_info.section_id : null;
									const section1_info = (main_section_id) ? sections.find(section => section.section_id === main_section_id) : null;
									const course_id = (section1_info) ? section1_info.course_id : null;
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];

									const course_info = courses.find(course => course.course_id === course_id);
									const course_name = (course_info) ? `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})` : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : "--";

									const student_counts = section1_info.subsections.reduce((results, subsection_id) => {
										const subsection_info = sections.find(section => section.section_id === subsection_id);
										const subsection_is_inclusion = subsection_info.is_inclusion;
										const subsection_student_list = subsection_info.student_list;
										if(subsection_is_inclusion === '1') results.inclusion_students += subsection_student_list.length;
										results.total_students += subsection_student_list.length;

										return results;
									}, {inclusion_students:0, total_students:section1_info.student_list.length});
									const percent_inclusion = Math.round(student_counts.inclusion_students/student_counts.total_students*100);

									return (
										<div className='conflict-row left-border-green' key={`course-exceeds-capacity-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={teacher_id ? () => filterToData('teacher', teacher_last_name) : classroom_id ? () => filterToData('classroom', classroom_name) : ()=>{}}>{course_name}</span> has more than 50% inclusion students</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboardTeacher}/>
														<div>{teacher_id ? teacher_full_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboard}/>
														<div>{classroom_id ? classroom_info.classroom_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faUsers}/>
														<div>{student_counts.inclusion_students} inclusion students out of {student_counts.total_students} total ({percent_inclusion}%)</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
							</div>
							<div className='conflicts-type-container'>
								<h3>Classroom Warnings</h3>
								{num_classroom_warnings === 0 &&
									<div>There are no current classroom warnings!</div>
								}
								{classrooms_missing.filter(conflict => conflict.is_deleted === '0').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');
									
									const section_id = conflict_sections[0];
									const section_index = sections.findIndex(section => section.section_id === section_id);
									const section_info = (section_index !== -1) ? sections[section_index] : null;
									const section_course_id = (section_info) ? section_info.course_id : null;
									const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
									const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
									const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

									const teacher_id = (section_info) ? section_info.teacher_id : null; 
									const course_periods = (section_info) ? section_info.course_periods : [];
									
									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									return (
										<div className='conflict-row left-border-yellow' key={`classroom-missing-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', section_course_info.name)}>{section_course_name}</span> is missing a classroom!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faChalkboardTeacher}/>
														<div>{teacher_full_name}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
							</div>
							</>
						):(currentPage === 'ignored-conflicts') ?
						(
							<>
							<div className='conflicts-type-container'>
								<h3>Teacher Conflicts</h3>
								{num_teacher_conflicts === 0 &&
									<div>There are no current teacher conflicts!</div>
								}
								{teachers_double_booked.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_periods = (section1_info) ? section1_info.course_periods : [];
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									return (
										<div className='conflict-row left-border-purple' key={`teacher-double-booked-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', teacher_last_name)}>{teacher_full_name}</span> is double booked!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faCalendarTimes}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

																return (
																	<div key={i}>{section_course_name}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
								{teachers_conflicting_periods.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									return (
										<div className='conflict-row left-border-purple' key={`teacher-conflicting-periods-${i}`} data-id={conflict_id}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', teacher_last_name)}>{teacher_full_name}</span> has a schedule with conflicting times!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faExclamationTriangle}/>
														<div>This teacher is scheduled in periods that conflict with each other.</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;
																const section_course_periods = (section_info) ? section_info.course_periods : [];

																let period_names = [];
																for(const course_period_id of section_course_periods)
																{	
																	if(scheduleType !== 'daily')
																	{
																		period_names.push(scheduleStructureData.course_periods[course_period_id].period_name);
																	}
																	else
																	{
																		const schedule_period_id = scheduleStructureData.course_periods[course_period_id]['days']['1'];
																		period_names.push(scheduleStructureData.schedule_periods[schedule_period_id].period_name);
																	}
																}
																const period_names_display = period_names.join(", ");

																return (
																	<div key={i}>{section_course_name} in {period_names_display}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
							</div>
							<div className='conflicts-type-container'>
								<h3>Student Conflicts</h3>
								{num_student_conflicts === 0 &&
									<div>There are no current student conflicts!</div>
								}
								{students_double_booked.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_students = conflict.students;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);

									const student_id = conflict_students[0];
									const course_periods = (section1_info) ? section1_info.course_periods : [];

									const student_info = students.find(student => student.student_id === student_id);
									const student_first_name = (student_info) ? student_info.first_name : null;
									const student_last_name = (student_info) ? student_info.last_name : null;
									const student_full_name = (student_first_name) ? `${student_last_name}, ${student_first_name}` : student_last_name;

									return (
										<div className='conflict-row left-border-blue' key={`student-double-booked-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => toggleEditStudentSchedule(student_id)}>{student_full_name}</span> is double booked!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faCalendarTimes}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

																return (
																	<div key={i}>{section_course_name}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
								{students_conflicting_periods.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_students = conflict.students;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const student_id = conflict_students[0];

									const student_info = students.find(student => student.student_id === student_id);
									const student_first_name = (student_info) ? student_info.first_name : null;
									const student_last_name = (student_info) ? student_info.last_name : null;
									const student_full_name = (student_first_name) ? `${student_last_name}, ${student_first_name}` : student_last_name;

									return (
										<div className='conflict-row left-border-blue' key={`student-conflicting-period-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => toggleEditStudentSchedule(student_id)}>{student_full_name}</span> has a schedule with conflicting times!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faExclamationTriangle}/>
														<div>This student is scheduled in periods that conflict with each other.</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faClock}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;
																const section_course_periods = (section_info) ? section_info.course_periods : [];

																let period_names = [];
																for(const course_period_id of section_course_periods)
																{	
																	if(scheduleType !== 'daily')
																	{
																		period_names.push(scheduleStructureData.course_periods[course_period_id].period_name);
																	}
																	else
																	{
																		const schedule_period_id = scheduleStructureData.course_periods[course_period_id]['days']['1'];
																		period_names.push(scheduleStructureData.schedule_periods[schedule_period_id].period_name);
																	}
																}
																const period_names_display = period_names.join(", ");

																return (
																	<div key={i}>{section_course_name} in {period_names_display}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
							</div>
							<div className='conflicts-type-container'>
								<h3>Classroom Conflicts</h3>
								{num_classroom_conflicts === 0 &&
									<div>There are no current classroom conflicts!</div>
								}
								{classrooms_double_booked.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_periods = (section1_info) ? section1_info.course_periods : [];
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : null;

									return (
										<div className='conflict-row left-border-yellow' key={`classroom-double-booked-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('classroom', classroom_name)}>{classroom_name}</span> is double booked!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faCalendarTimes}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

																return (
																	<div key={i}>{section_course_name}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
								{classrooms_conflicting_periods.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : null;

									return (
										<div className='conflict-row left-border-yellow' key={`classroom-conflicting-periods-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('classroom', classroom_name)}>{classroom_name}</span> has a schedule with conflicting times!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faExclamationTriangle}/>
														<div>This classroom is scheduled in periods that conflict with each other.</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faClock}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;
																const section_course_periods = (section_info) ? section_info.course_periods : [];

																let period_names = [];
																for(const course_period_id of section_course_periods)
																{	
																	if(scheduleType !== 'daily')
																	{
																		period_names.push(scheduleStructureData.course_periods[course_period_id].period_name);
																	}
																	else
																	{
																		const schedule_period_id = scheduleStructureData.course_periods[course_period_id]['days']['1'];
																		period_names.push(scheduleStructureData.schedule_periods[schedule_period_id].period_name);
																	}
																}
																const period_names_display = period_names.join(", ");

																return (
																	<div key={i}>{section_course_name} in {period_names_display}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Conflict' : 'Ignore Conflict'}</div>
											</div>
										</div>
									)
								})}
							</div>
							</>
						):(currentPage === 'ignored-warnings') ?
						(
							<>
							<div className='conflicts-type-container'>
								<h3>Teacher Warnings</h3>
								{num_teacher_warnings === 0 &&
									<div>There are no current teacher warnings!</div>
								}
								{teachers_missing.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section_id = conflict_sections[0];
									const section_index = sections.findIndex(section => section.section_id === section_id);
									const section_info = (section_index !== -1) ? sections[section_index] : null;
									const section_course_id = (section_info) ? section_info.course_id : null;
									const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
									const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
									const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

									const course_periods = (section_info) ? section_info.course_periods : [];
									const classroom_id = (section_info) ? section_info.classroom_id : null;
									const classroom_info = (classroom_id) ? classrooms.find(classroom => classroom.classroom_id === classroom_id) : null;

									return (
										<div className='conflict-row left-border-purple' key={`teacher-missing-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('classroom', section_course_info.name)}>{section_course_name}</span> is missing a teacher!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faChalkboard}/>
														<div>{classroom_id ? classroom_info.classroom_name : '--'}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{teachers_period_restrictions.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');
									
									const section_id = conflict_sections[0];
									const section_index = sections.findIndex(section => section.section_id === section_id);
									const section_info = (section_index !== -1) ? sections[section_index] : null;
									const section_course_id = (section_info) ? section_info.course_id : null;
									const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
									const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
									const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

									const course_periods = (section_info) ? section_info.course_periods : [];
									const teacher_id = (section_info) ? section_info.teacher_id : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									return (
										<div className='conflict-row left-border-purple' key={`teacher-period-restriction-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', teacher_last_name)}>{teacher_full_name}</span> is in a restricted period!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faBook}/>
														<div>{section_course_name}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{teachers_too_many_sections.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const total_classes = conflict_sections.length;

									return (
										<div className='conflict-row left-border-purple' key={`teacher-too-many-sections-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', teacher_last_name)}>{teacher_full_name}</span> is teaching too many courses!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faExclamationTriangle}/>
														<div>Teaching {total_classes} classes</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;
																const section_course_periods = (section_info) ? section_info.course_periods : [];

																let period_names = [];
																for(const course_period_id of section_course_periods)
																{	
																	if(scheduleType !== 'daily')
																	{
																		period_names.push(scheduleStructureData.course_periods[course_period_id].period_name);
																	}
																	else
																	{
																		const schedule_period_id = scheduleStructureData.course_periods[course_period_id]['days']['1'];
																		period_names.push(scheduleStructureData.schedule_periods[schedule_period_id].period_name);
																	}
																}
																const period_names_display = period_names.join(", ");

																return (
																	<div key={i}>{section_course_name} in {period_names_display}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{teachers_too_many_sections_in_a_row.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);

									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const num_in_a_row = conflict_sections.length;

									return (
										<div className='conflict-row left-border-purple' key={`teacher-too-many-in-a-row-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', teacher_last_name, conflict.day)}>{teacher_full_name}</span> has too many classes in a row!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faExclamationTriangle}/>
														<div>Teaching {num_in_a_row} classes in a row</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon purple' icon={faClock}/>
														<div>
															{conflict_sections.map((section_id, i) => {
																const section_index = sections.findIndex(section => section.section_id === section_id);
																const section_info = (section_index !== -1) ? sections[section_index] : null;
																const section_course_id = (section_info) ? section_info.course_id : null;
																const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
																const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
																const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;
																const section_course_periods = (section_info) ? section_info.course_periods : [];

																let period_names = [];
																for(const course_period_id of section_course_periods)
																{	
																	if(scheduleType !== 'daily')
																	{
																		period_names.push(scheduleStructureData.course_periods[course_period_id].period_name);
																	}
																	else
																	{
																		const schedule_period_id = scheduleStructureData.course_periods[course_period_id]['days']['1'];
																		period_names.push(scheduleStructureData.schedule_periods[schedule_period_id].period_name);
																	}
																}
																const period_names_display = period_names.join(", ");

																return (
																	<div key={i}>{section_course_name} in {period_names_display}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
							</div>
							<div className='conflicts-type-container'>
								<h3>Student Warnings</h3>
								{num_student_warnings === 0 &&
									<div>There are no current student warnings!</div>
								}
								{students_teacher_restrictions.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_students = conflict.students;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = section1_info.course_id;
									const course_periods = (section1_info) ? section1_info.course_periods : [];
									
									const student_id = conflict_students[0];
									const student_info = students.find(student => student.student_id === student_id);
									const student_first_name = (student_info) ? student_info.first_name : null;
									const student_last_name = (student_info) ? student_info.last_name : null;
									const student_full_name = (student_first_name) ? `${student_last_name}, ${student_first_name}` : student_last_name;

									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;
									
									const course_info = (course_id) ? courses.find(course => course.course_id === course_id) : null;

									return (
										<div className='conflict-row left-border-blue' key={i}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => toggleEditStudentSchedule(student_id)}>{student_full_name}</span> is with restricted teacher!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faBook}/>
														<div>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faChalkboardTeacher}/>
														<div>{teacher_full_name}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{students_student_restrictions.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_students = conflict.students;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = (section1_info) ? section1_info.course_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];
									
									const course_info = (course_id) ? courses.find(course => course.course_id === course_id) : null;

									return (
										<div className='conflict-row left-border-blue' key={i}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'>Two restricted students are in the same class!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faBook}/>
														<div>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon blue' icon={faUserGraduate}/>
														<div>
															{conflict_students.map((student_id, i) => {
																const student_info = students.find(student => student.student_id === student_id);
																const student_first_name = (student_info) ? student_info.first_name : null;
																const student_last_name = (student_info) ? student_info.last_name : null;
																const student_full_name = (student_first_name) ? `${student_first_name}, ${student_last_name}` : student_last_name;

																return (
																	<div className='blue-link' onClick={() => toggleEditStudentSchedule(student_id)}>{student_full_name}</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
							</div>
							<div className='conflicts-type-container'>
								<h3>Course Warnings</h3>
								{num_course_warnings === 0 &&
									<div>There are no current course warnings!</div>
								}
								{courses_period_restrictions.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = (section1_info) ? section1_info.course_id : null;
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];

									const course_info = courses.find(course => course.course_id === course_id);
									const course_name = (course_info) ? `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})` : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : "--";

									return (
										<div className='conflict-row left-border-green' key={`course-period-restriction-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={teacher_id ? () => filterToData('teacher', teacher_last_name) : classroom_id ? () => filterToData('classroom', classroom_name) : ()=>{}}>{course_name}</span> is in a restricted period!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													{teacher_id &&
														<div className='conflict-info-row'>
															<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboardTeacher}/>
															<div>{teacher_full_name}</div>
														</div>
													}
													{classroom_id &&
														<div className='conflict-info-row'>
															<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboard}/>
															<div>{classroom_name}</div>
														</div>
													}
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{courses_outside_normal_room.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = (section1_info) ? section1_info.course_id : null;
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];

									const course_info = courses.find(course => course.course_id === course_id);
									const course_name = (course_info) ? `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})` : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : "--";

									return (
										<div className='conflict-row left-border-green' key={`course-outside-normal-room-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={teacher_id ? () => filterToData('teacher', teacher_last_name) : classroom_id ? () => filterToData('classroom', classroom_name) : ()=>{}}>{course_name}</span> is not in its normal classroom!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboardTeacher}/>
														<div>{teacher_id ? teacher_full_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboard}/>
														<div>{classroom_id ? classroom_info.classroom_name : '--'}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{courses_exceed_capacity.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = (section1_info) ? section1_info.course_id : null;
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];

									const course_info = courses.find(course => course.course_id === course_id);
									const course_name = (course_info) ? `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})` : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : "--";

									const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section1_id);
									const is_subsection = (subsection_index !== -1) ? true : false;

									const max_class_size = course_info.max_class_size;
									const current_class_size = (is_subsection) ? section1_info.student_list.length : section1_info.subsections.reduce((results, subsection_id) => {
										const subsection_info = sections.find(section => section.section_id === subsection_id);
										const subsection_student_list = subsection_info.student_list;
										return results += subsection_student_list.length;
									}, section1_info.student_list.length);

									return (
										<div className='conflict-row left-border-green' key={`course-exceeds-capacity-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={teacher_id ? () => filterToData('teacher', teacher_last_name) : classroom_id ? () => filterToData('classroom', classroom_name) : ()=>{}}>{course_name}</span> has too many students!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboardTeacher}/>
														<div>{teacher_id ? teacher_full_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboard}/>
														<div>{classroom_id ? classroom_info.classroom_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faUsers}/>
														<div>{current_class_size} out of {max_class_size}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{courses_inclusion_too_many_students.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');
									
									const section1_id = conflict_sections[0];
									const section1_info = sections.find(section => section.section_id === section1_id);
									const course_id = (section1_info) ? section1_info.course_id : null;
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];
									const current_num_inclusion_students = (section1_info) ? section1_info.student_list : [];

									const course_info = courses.find(course => course.course_id === course_id);
									const course_name = (course_info) ? `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})` : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : "--";

									return (
										<div className='conflict-row left-border-green' key={`course-exceeds-capacity-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={teacher_id ? () => filterToData('teacher', teacher_last_name) : classroom_id ? () => filterToData('classroom', classroom_name) : ()=>{}}>{course_name}</span> has more than 10 inclusion students!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboardTeacher}/>
														<div>{teacher_id ? teacher_full_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboard}/>
														<div>{classroom_id ? classroom_info.classroom_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faUsers}/>
														<div>Has {current_num_inclusion_students} inclusion students</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
								{courses_inclusion_over_half.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');

									const section1_id = conflict_sections[0];
									const subsection_info = subsections.find(subsection => subsection.subsection_id === section1_id);
									const main_section_id = (subsection_info) ? subsection_info.section_id : null;
									const section1_info = sections.find(section => section.section_id === main_section_id);
									const course_id = (section1_info) ? section1_info.course_id : null;
									const teacher_id = (section1_info) ? section1_info.teacher_id : null;
									const classroom_id = (section1_info) ? section1_info.classroom_id : null;
									const course_periods = (section1_info) ? section1_info.course_periods : [];

									const course_info = courses.find(course => course.course_id === course_id);
									const course_name = (course_info) ? `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})` : null;

									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
									const classroom_name = (classroom_info) ? classroom_info.classroom_name : "--";

									const student_counts = section1_info.subsections.reduce((results, subsection_id) => {
										const subsection_info = sections.find(section => section.section_id === subsection_id);
										const subsection_is_inclusion = subsection_info.is_inclusion;
										const subsection_student_list = subsection_info.student_list;
										if(subsection_is_inclusion === '1') results.inclusion_students += subsection_student_list.length;
										results.total_students += subsection_student_list.length;

										return results;
									}, {inclusion_students:0, total_students:section1_info.student_list.length});
									const percent_inclusion = Math.round(student_counts.inclusion_students/student_counts.total_students*100);

									return (
										<div className='conflict-row left-border-green' key={`course-exceeds-capacity-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={teacher_id ? () => filterToData('teacher', teacher_last_name) : classroom_id ? () => filterToData('classroom', classroom_name) : ()=>{}}>{course_name}</span> has more than 50% inclusion students</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboardTeacher}/>
														<div>{teacher_id ? teacher_full_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faChalkboard}/>
														<div>{classroom_id ? classroom_info.classroom_name : '--'}</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon green' icon={faUsers}/>
														<div>{student_counts.inclusion_students} inclusion students out of {student_counts.total_students} total ({percent_inclusion}%)</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
							</div>
							<div className='conflicts-type-container'>
								<h3>Classroom Warnings</h3>
								{num_classroom_warnings === 0 &&
									<div>There are no current classroom warnings!</div>
								}
								{classrooms_missing.filter(conflict => conflict.is_deleted === '1').map((conflict, i) => {
									const conflict_id = conflict.conflict_id;
									const conflict_sections = conflict.sections;
									const conflict_is_deleted = (conflict.is_deleted === '1');
									
									const section_id = conflict_sections[0];
									const section_index = sections.findIndex(section => section.section_id === section_id);
									const section_info = (section_index !== -1) ? sections[section_index] : null;
									const section_course_id = (section_info) ? section_info.course_id : null;
									const section_course_index = (section_course_id) ? courses.findIndex(course => course.course_id === section_course_id) : null;
									const section_course_info = (section_course_index !== -1) ? courses[section_course_index] : null;
									const section_course_name = (section_course_info) ? `${capitalizeFirstLetters(section_course_info.name)} (${section_course_info.course_code})` : null;

									const teacher_id = (section_info) ? section_info.teacher_id : null; 
									const course_periods = (section_info) ? section_info.course_periods : [];
									
									const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
									const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
									const teacher_last_name = (teacher_info) ? teacher_info.name : null;
									const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name;

									return (
										<div className='conflict-row left-border-yellow' key={`classroom-missing-${i}`}>
											<div className={`conflict-inner-row ${conflict_is_deleted && 'opacity-25'}`}>
												<h4 className='conflict-heading'><span className='blue-link' onClick={() => filterToData('teacher', section_course_info.name)}>{section_course_name}</span> is missing a classroom!</h4>
												<div className='conflict-info-container'>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faClock}/>
														<div>
														{course_periods.map(conflict_course_period_id => {
															let period_name = null;
															if(scheduleType !== 'daily')
															{
																period_name = scheduleStructureData.course_periods[conflict_course_period_id].period_name;
															}
															else
															{
																const conflict_schedule_period_id = scheduleStructureData.course_periods[conflict_course_period_id]['days']['1'];
																period_name = scheduleStructureData.schedule_periods[conflict_schedule_period_id].period_name;
															}

															return (
																<div key={`${i}-${conflict_course_period_id}`}>{period_name}</div>
															)
														})}
														</div>
													</div>
													<div className='conflict-info-row'>
														<FontAwesomeIcon className='conflict-info-row-icon yellow' icon={faChalkboardTeacher}/>
														<div>{teacher_full_name}</div>
													</div>
												</div>
											</div>
											<div className='conflict-ignore-conflict'>
												<div></div>
												<div className='conflict-ignore-conflict-btn' onClick={() => toggleIgnoreConflict(conflict_id)}>{conflict_is_deleted ? 'Show Warning' : 'Ignore Warning'}</div>
											</div>
										</div>
									)
								})}
							</div>
							</>
						):null
					}
				</div>
			</div>
		</>
	);
}