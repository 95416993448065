import React, { useState , useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faEdit, faCheck, faTimes, faLock, faPlusCircle, faPlus, faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

import { saveScheduleStructure } from '../../../js/SaveFunctions';
import { getData, isEmpty, sortArrayOfObjects, createNewKeyForObject, cloneObj, timesAreOverlapping } from '../../../js/Helpers';

import Button from '../../Button';

import '../../../css/hsms/structure_setup.css';
import '../../../css/hsms/schedule_structure.css';

export default function ScheduleStructure({subscreenRestricted, subscreenLocked, schoolInfo, scheduleInfo, handleMoveToNextScreen}) {
	const [scheduleStructureLoading, setScheduleStructureLoading] = useState(true);

	const [currentDay, setCurrentDay] = useState(1);
	const [errorMessage, setErrorMessage] = useState(null);
	
	const colors = useRef(['orange', 'yellow', 'green', 'blue', 'dark-blue-grey', 'pastel-orange', 'pastel-yellow', 'turquoise', 'pastel-light-blue', 'dark-brown', 'pastel-light-purple', 'bright-yellow', 'dark-orange', 'bright-green', 'bright-blue', 'pastel-light-green', 'pastel-light-turquoise', 'dark-purple', 'pastel-light-orange', 'pastel-brown', 'dark-blue', 'pastel-dark-purple']);

	const dayNames = useRef({
		'1': {
			'daily': 'Day 1',
			'block': 'Day 1',
			'ab' : 'A Day',
			'ab_skinny': 'A Day'
		},
		'2': {
			'daily': 'Day 2',
			'ab' : 'B Day',
			'ab_skinny': 'B Day'
		},
		'3': {
			'daily': 'Day 3',
			'ab_skinny': 'Skinny'
		},
		'4': {
			'daily': 'Day 4',
		},
		'5': {
			'daily': 'Day 5',
		}
	});

	const [coursePeriods, setCoursePeriods] = useState({});
	const [schedulePeriods, setSchedulePeriods] = useState({});
	const [scheduleStructure, setScheduleStructure] = useState({'1':[]});
	const [lunches, setLunches] = useState({});
	
	////////////////////////////////////////////////
	/////////// UPDATE FUNCTIONS ///////////////////
	////////////////////////////////////////////////
	const handleFocus = (event) => event.target.select();

	const changeDay = (day) => {
		if(day in scheduleStructure) setCurrentDay(day);
	}

	///// ADD NEW DAY FUNCTION /////
	const addNewDay = () => {
		const new_day = createNewKeyForObject(scheduleStructure);

		if(scheduleInfo.schedule_type === 'custom')
		{
			addNewSchedulePeriod(new_day);
		}
		else if(scheduleInfo.schedule_type === 'block')
		{
			const first_day_schedule_periods = Object.values(scheduleStructure)[0].reduce((results, schedule_structure_row) => {
				const schedule_period_id = schedule_structure_row.schedule_period_id;
				schedulePeriods[schedule_period_id].days.push(new_day);

				const schedule_structure_row_clone = cloneObj(schedule_structure_row);
				schedule_structure_row_clone.course_period_id = null;
				results.push(schedule_structure_row_clone);
				return results;
			},[]);

			scheduleStructure[new_day] = first_day_schedule_periods;
			setScheduleStructure({...scheduleStructure});
			setSchedulePeriods({...schedulePeriods});

			//////////////////////
			/// UPDATE BACKEND ///
			//////////////////////
			const schedule_structure_json = JSON.stringify(scheduleStructure);
	
			saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'schedule_structure', update_type:'update', schedule_structure:schedule_structure_json});
		}
	}

	const removeDay = (e, day) => {
		e.stopPropagation();

		if(day == currentDay) setCurrentDay(1);

		// Remove day from schedule structure
		delete scheduleStructure[day];

		// Remove day from schedule periods
		for(const schedule_period_id in schedulePeriods)
		{
			const schedule_period_info = schedulePeriods[schedule_period_id];
			const schedule_period_days = schedule_period_info.days;
			const new_schedule_period_days = schedule_period_days.filter(cp_day => cp_day !== day);
			
			schedulePeriods[schedule_period_id].days = new_schedule_period_days;
		}

		setSchedulePeriods({...schedulePeriods});
		setScheduleStructure({...scheduleStructure});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'day', update_type:'delete', current_day:day});
	}

	//////////////////////////////////////////
	//////////////////////////////////////////
	///// EDIT SCHEDULE PERIOD FUNCTIONS /////
	//////////////////////////////////////////
	//////////////////////////////////////////
	/// ADD NEW SCHEDULE PERIOD TIME ///
	const addNewSchedulePeriod = async (current_day) => {
		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		// Get new schedule period id
		const new_schedule_period_id = createNewKeyForObject(schedulePeriods);

		// Figure out the period order
		const max_period_order = (current_day in scheduleStructure) ? scheduleStructure[current_day].reduce((result, rotation_info) => {
			const current_period_order = parseInt(rotation_info.period_order);
			return current_period_order > result ? current_period_order : result;
		}, 0) : 0;

		const new_period_order = parseInt(max_period_order) + 1;

		// Figure out what days are the same as the current one
		const first_schedule_period_id_on_day = ((current_day in scheduleStructure) && scheduleStructure[current_day].length > 0) ? scheduleStructure[current_day][0].schedule_period_id : null;
		const days_this_schedule_period_runs = (first_schedule_period_id_on_day && (first_schedule_period_id_on_day in schedulePeriods)) ? schedulePeriods[first_schedule_period_id_on_day].days : [current_day];

		schedulePeriods[new_schedule_period_id] = {
			schedule_period_id: `${new_schedule_period_id}`,
			period_name: 'Untitled Period',
			start_time: '00:00:00',
			end_time: '00:00:00',
			currently_editing: false,
			days:days_this_schedule_period_runs,
			include_in_match:'1'
		};

		// If daily schedule then adding a new schedule period also means creating a default new course period for that schedule period
		let new_course_period_id = null;
		const schedule_type = scheduleInfo.schedule_type;
		if(schedule_type === 'daily') new_course_period_id = await addNewCoursePeriod();

		for(const day of days_this_schedule_period_runs)
		{
			if(!(day in scheduleStructure)) scheduleStructure[day] = [];
			scheduleStructure[day].push({'schedule_period_id':new_schedule_period_id, 'course_period_id':new_course_period_id, period_order:`${new_period_order}`});
		}

		setSchedulePeriods({...schedulePeriods});
		setScheduleStructure({...scheduleStructure});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		const schedule_periods_json = JSON.stringify(schedulePeriods);
		const schedule_structure_json = JSON.stringify(scheduleStructure);

		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'schedule_period', update_type:'add', current_schedule_period_id:new_schedule_period_id, schedule_periods:schedule_periods_json, schedule_structure:schedule_structure_json}).then(returned_data => {
			// Update schedule periods
			const returned_new_schedule_period_id = returned_data.new_schedule_period_id;
			schedulePeriods[new_schedule_period_id].schedule_period_id = returned_new_schedule_period_id;

			// Update object name
			schedulePeriods[returned_new_schedule_period_id] = cloneObj(schedulePeriods[new_schedule_period_id]);
			if(returned_new_schedule_period_id != new_schedule_period_id) delete schedulePeriods[new_schedule_period_id];

			setSchedulePeriods({...schedulePeriods});

			// Update schedule structure
			for(const day of days_this_schedule_period_runs)
			{
				const new_day_schedule_periods = scheduleStructure[day].reduce((results, schedule_structure_row) => {
					const schedule_period_id = schedule_structure_row.schedule_period_id;
					if(schedule_period_id === new_schedule_period_id) schedule_structure_row.schedule_period_id = returned_new_schedule_period_id;
					results.push(schedule_structure_row);
					return results;
				}, []);

				scheduleStructure[day] = new_day_schedule_periods;
			}
			setScheduleStructure({...scheduleStructure});
		});
	}

	/// SAVE SCHEDULE PERIOD TIME ///
	const onTimeChange = (time_type, schedule_period_id, time_value) => {
		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		schedulePeriods[schedule_period_id][time_type] = time_value;
		setSchedulePeriods({...schedulePeriods});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		const schedule_periods_json = JSON.stringify(schedulePeriods);
		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'schedule_period', update_type:'update', current_schedule_period_id:schedule_period_id, schedule_periods:schedule_periods_json});
	}
	
	const openEditSchedulePeriod = (schedule_period_id) => {
		schedulePeriods[schedule_period_id]['currently_editing'] = true;
		setSchedulePeriods({...schedulePeriods});
	}
	
	/// SAVE SCHEDULE PERIOD NAME ///
	const saveSchedulePeriodName = (e) => {
		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		if(e.key !== 'Enter' && e.type !== 'click') return;
		
		const target = $(e.target);
		const parentDiv = $(e.target).closest('.ess-period-info-outer-container');
		const schedule_period_id = parentDiv.attr('data-scheduleperiodid');
		const period_name = target.closest('.ess-period-name-container').find('.schedule-setup-period-name-input').val();
		
		if(period_name === '') return;
		
		schedulePeriods[schedule_period_id]['period_name'] = period_name;
		schedulePeriods[schedule_period_id]['currently_editing'] = false;
		
		setSchedulePeriods({...schedulePeriods});
		
		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		const schedule_periods_json = JSON.stringify(schedulePeriods);

		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'schedule_period', update_type:'update', current_schedule_period_id:schedule_period_id, schedule_periods:schedule_periods_json});
	}
	
	/// CANCEL EDIT SCHEDULE PERIOD NAME ///
	const cancelEditSchedulePeriodName = (e) => {
		const parentDiv = $(e.target).closest('.ess-period-info-outer-container');
		const schedule_period_id = parentDiv.attr('data-scheduleperiodid');
		
		schedulePeriods[schedule_period_id]['currently_editing'] = false;
		
		setSchedulePeriods({...schedulePeriods});
	}

	/// REMOVE SCHEDULE PERIOD ///
	const removeSchedulePeriod = (schedule_period_id) => {
		const schedule_periods_json = JSON.stringify(schedulePeriods);
		const schedule_structure_json = JSON.stringify(scheduleStructure);

		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		const new_schedule_structure = Object.keys(scheduleStructure).reduce((results, day) => {
			const day_schedule_periods = scheduleStructure[day];

			let period_index = 1;
			const new_day_schedule_periods = day_schedule_periods.reduce((results, schedule_structure_row) => {
				const current_schedule_period_id = schedule_structure_row.schedule_period_id;
				if(current_schedule_period_id === schedule_period_id) return results;

				schedule_structure_row.period_order = `${period_index}`;
				period_index++;

				results.push(schedule_structure_row);
				return results;
			}, []);
			results[day] = new_day_schedule_periods;
			return results;
		}, {});

		setScheduleStructure(new_schedule_structure);

		delete schedulePeriods[schedule_period_id];
		setSchedulePeriods({...schedulePeriods});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'schedule_period', update_type:'delete', current_schedule_period_id:schedule_period_id, schedule_periods:schedule_periods_json, schedule_structure:schedule_structure_json});
	}

	/// UPDATE SCHEDULE PERIOD INCLUDE IN MATCH ///
	const updateIncludeInMatch = (schedule_period_id, include_in_match) => {
		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		schedulePeriods[schedule_period_id].include_in_match = include_in_match;
		setSchedulePeriods({...schedulePeriods});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		const schedule_periods_json = JSON.stringify(schedulePeriods);

		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'schedule_period', update_type:'update', current_schedule_period_id:schedule_period_id, schedule_periods:schedule_periods_json});
	}
	
	/////////////////////////////////////////
	/////////////////////////////////////////
	////// EDIT COURSE PERIOD FUNCTIONS /////
	/////////////////////////////////////////
	/////////////////////////////////////////
	/// ADD NEW COURSE PERIOD ///
	const addNewCoursePeriod = async () => {
		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		// Get new course period id
		const new_course_period_id = createNewKeyForObject(coursePeriods);
		const color_index = (Object.keys(coursePeriods).length >= colors.current.length) ? Object.keys(coursePeriods).length % colors.current.length : Object.keys(coursePeriods).length;

		coursePeriods[new_course_period_id] = {
			course_period_id: `${new_course_period_id}`,
			period_name: '--',
			currently_editing: false,
			color:colors.current[color_index]
		};

		setCoursePeriods({...coursePeriods});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		const course_periods_json = JSON.stringify(coursePeriods);
		const schedule_structure_json = JSON.stringify(scheduleStructure);

		return saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'course_period', update_type:'add', current_course_period_id:new_course_period_id, course_periods:course_periods_json, schedule_structure:schedule_structure_json}).then(returned_data => {
			// Update course periods
			const returned_new_course_period_id = returned_data.new_course_period_id;
			coursePeriods[new_course_period_id].course_period_id = returned_new_course_period_id;

			// Update object name
			coursePeriods[returned_new_course_period_id] = cloneObj(coursePeriods[new_course_period_id]);
			if(new_course_period_id != returned_new_course_period_id) delete coursePeriods[new_course_period_id];

			setCoursePeriods({...coursePeriods});

			return returned_new_course_period_id;
		});
	}

	/// OPEN EDIT COURSE PERIOD ///
	const openEditCoursePeriod = (e) => {
		const parentDiv = $(e.target).closest('.course-period-draggable');
		const course_period_id = parentDiv.attr('data-courseperiodid');
		
		coursePeriods[course_period_id]['currently_editing'] = true;
		
		setCoursePeriods({...coursePeriods});
	}
	
	/// SAVE COURSE PERIOD NAME ///
	const saveCoursePeriodName = (e) => {
		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		if(e.key !== 'Enter' && e.type !== 'click') return;
		
		const target = $(e.target);
		const parentDiv = target.closest('.course-period-draggable');
		const course_period_id = parentDiv.attr('data-courseperiodid');
		const period_name = target.closest('.schedule-setup-course-period-name-input-container').find('.schedule-setup-course-period-name-input').val();
		
		if(period_name === '') return;

		coursePeriods[course_period_id].period_name = period_name;
		coursePeriods[course_period_id].currently_editing = false;

		setCoursePeriods({...coursePeriods});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		const course_periods_json = JSON.stringify(coursePeriods);

		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'course_period', update_type:'update', current_course_period_id:course_period_id, course_periods:course_periods_json});
	}
	
	/// CANCEL EDIT COURSE PERIOD NAME ///
	const cancelEditCoursePeriodName = (e) => {
		const parentDiv = $(e.target).closest('.course-period-draggable');
		const course_period_id = parentDiv.attr('data-courseperiodid');

		coursePeriods[course_period_id].currently_editing = false;
		
		setCoursePeriods({...coursePeriods});
	}

	const removeCoursePeriod = (course_period_id) => {
		const course_periods_json = JSON.stringify(coursePeriods);
		const schedule_structure_json = JSON.stringify(scheduleStructure);

		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		const new_schedule_structure = Object.keys(scheduleStructure).reduce((results, day) => {
			const day_schedule_periods = scheduleStructure[day];

			const new_day_schedule_periods = day_schedule_periods.reduce((results, schedule_structure_row) => {
				const current_course_period_id = schedule_structure_row.course_period_id;
				if(current_course_period_id === course_period_id) schedule_structure_row.course_period_id = null;
				results.push(schedule_structure_row);
				return results;
			}, []);
			results[day] = new_day_schedule_periods;
			return results;
		}, {});

		setScheduleStructure(new_schedule_structure);

		delete coursePeriods[course_period_id];
		setCoursePeriods({...coursePeriods});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'course_period', update_type:'delete', current_course_period_id:course_period_id, course_periods:course_periods_json, schedule_structure:schedule_structure_json});
	}

	//////////////////////////////////
	//////////////////////////////////
	//// UPDATE LUNCHES FUNCTIONS ////
	//////////////////////////////////
	//////////////////////////////////
	/// ADD NEW LUNCH ///
	const addNewLunch = () => {
		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		// Figure out what days are the same as the current one
		const first_schedule_period_id_on_day = scheduleStructure[currentDay][0].schedule_period_id;
		const days_this_schedule_period_runs = schedulePeriods[first_schedule_period_id_on_day].days;

		const new_lunch_id = createNewKeyForObject(lunches);
		lunches[new_lunch_id] = {lunch_id:new_lunch_id, lunch_name:'Untitled Lunch', start_time:'00:00:00', end_time:'00:00:00', days:days_this_schedule_period_runs, currently_editing:false};
		setLunches({...lunches});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		const lunches_json = JSON.stringify(lunches);

		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'lunch', update_type:'add', current_lunch_id:new_lunch_id, lunches:lunches_json}).then(returned_data => {
			// Update course periods
			const returned_new_lunch_id = returned_data.new_lunch_id;
			lunches[new_lunch_id].lunch_id = returned_new_lunch_id;

			// Update object name
			lunches[returned_new_lunch_id] = cloneObj(lunches[new_lunch_id]);
			if(returned_new_lunch_id != new_lunch_id) delete lunches[new_lunch_id];

			setLunches({...lunches});
		});
	}

	/// OPEN EDIT LUNCHES ///
	const openEditLunch = (lunch_id) => {
		lunches[lunch_id].currently_editing= true;
		setLunches({...lunches});
	}
	
	/// SAVE LUNCH NAME ///
	const saveLunchName = (e) => {
		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		if(e.key !== 'Enter' && e.type !== 'click') return;
		
		const target = $(e.target);
		const parentDiv = target.closest('.ess-lunch-name-container');
		const lunch_id = parentDiv.attr('data-lunchid');
		const lunch_name = parentDiv.find('.ess-lunch-name-input').val();
		
		if(lunch_name === '') return;

		lunches[lunch_id].lunch_name = lunch_name;
		lunches[lunch_id].currently_editing = false;

		setLunches({...lunches});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		const lunches_json = JSON.stringify(lunches);

		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'lunch', update_type:'update', current_lunch_id:lunch_id, lunches:lunches_json});
	}
	
	/// CANCEL EDIT LUNCH NAME ///
	const cancelEditLunchName = (lunch_id) => {
		lunches[lunch_id].currently_editing = false;
		setLunches({...lunches});
	}

	/// CHANGE LUNCH TIME ///
	const onLunchTimeChange = (time_type, lunch_id, time_value) => {
		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		lunches[lunch_id][time_type] = time_value;
		setLunches({...lunches});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		const lunches_json = JSON.stringify(lunches);

		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'lunch', update_type:'update', current_lunch_id:lunch_id, lunches:lunches_json});
	} 

	/// REMOVE LUNCH ///
	const removeLunch = (lunch_id) => {
		const lunches_json = JSON.stringify(lunches);

		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		delete lunches[lunch_id];
		setLunches({...lunches});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'lunch', update_type:'delete', current_lunch_id:lunch_id, lunches:lunches_json});
	}

	///////////////////////////////////////////
	///////////////////////////////////////////
	///  UPDATE SCHEDULE STRUCTURE ROTATION ///
	///////////////////////////////////////////
	///////////////////////////////////////////
	const updateScheduleStructureCoursePeriod = (schedule_period_id, new_course_period_id) => {
		///////////////////////
		/// UPDATE FRONTEND ///
		///////////////////////
		const schedule_structure_index = scheduleStructure[currentDay].findIndex(schedule_structure_row => schedule_structure_row.schedule_period_id === schedule_period_id);
		if(schedule_structure_index !== -1) scheduleStructure[currentDay][schedule_structure_index].course_period_id = new_course_period_id;

		setScheduleStructure({...scheduleStructure});

		//////////////////////
		/// UPDATE BACKEND ///
		//////////////////////
		const schedule_structure_json = JSON.stringify(scheduleStructure);

		saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'schedule_structure', update_type:'update', schedule_structure:schedule_structure_json});
	}

	////////////////////////////////////////
	////////////////////////////////////////
	///// SORT AND DRAG/DROP FUNCTION //////
	////////////////////////////////////////
	////////////////////////////////////////
	const initializeSortable = () => {
		$("#ess-sortable-schedule-container").sortable({
			helper: "clone",
			axis: "y",
			forcePlaceholderSize: true,
			items: '.ess-period-parent-container',
			placeholder: "sortable-placeholder",
			tolerance: "pointer",
			update: function (event, ui) {
				///////////////////////
				/// UPDATE FRONTEND ///
				///////////////////////
				const first_schedule_period_id = scheduleStructure[currentDay][0].schedule_period_id;
				const days_to_change = schedulePeriods[first_schedule_period_id].days;

				let new_schedule_structure = {};

				for(const day of days_to_change)
				{
					new_schedule_structure[day] = [];

					$('.ess-period-info-outer-container').each(function(period_index){
						const $this = $(this);
						const rotation_index = $this.attr('data-rotationindex');
						const new_period_order = parseInt(period_index) + 1;

						const schedule_structure_row = scheduleStructure[currentDay][rotation_index];
						schedule_structure_row.period_order = `${new_period_order}`;

						new_schedule_structure[day].push(schedule_structure_row);
					});
				}
				
				setScheduleStructure(new_schedule_structure);

				//////////////////////
				/// UPDATE BACKEND ///
				//////////////////////
				const schedule_structure_json = JSON.stringify(new_schedule_structure);

				saveScheduleStructure({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'schedule_structure', update_type:'update', schedule_structure:schedule_structure_json});
			}
		});
			
		$("#ess-sortable-schedule-container").sortable("refresh");

		$(".course-period-draggable").draggable({
			helper: "clone",
			snap: ".schedule-structure-droppable",
			revert: "invalid",
			zIndex: 9999,
		});
			
		$(".course-period-droppable").droppable({
			drop: function (event, ui) {
				const draggedDiv = ui.draggable;
				let droppedDiv = $(this);
				
				if(draggedDiv.hasClass('ess-period-parent-container')) return;
				
				const schedule_period_id = droppedDiv.closest('.ess-period-inner-container').attr('data-scheduleperiodid');
				const course_period_id = draggedDiv.attr("data-courseperiodid");
			
				$(ui.helper).remove();
				
				droppedDiv.attr('class', "ess-droppable-inner-container course-period-droppable ui-droppable");
				
				////////////////////////
				//// UPDATE FRONTEND ///
				////////////////////////
				const schedule_structure_index = scheduleStructure[currentDay].findIndex(schedule_structure_row => schedule_structure_row.schedule_period_id === schedule_period_id);
				if(schedule_structure_index !== -1) scheduleStructure[currentDay][schedule_structure_index].course_period_id = course_period_id;
				setScheduleStructure({...scheduleStructure});
				
				////////////////////////
				//// UPDATE BACKEND ///
				////////////////////////
				updateScheduleStructureCoursePeriod(schedule_period_id, course_period_id);
			},
		});
	}
	
	///// SAVE FUNCTIONS ////
	const submitStructure = async () => {
		let error_message = null;
		setErrorMessage(error_message);

		const schedule_type = scheduleInfo.schedule_type;
		
		$(".time-select-input").removeClass('error');
		$(".course-period-dropdown").removeClass('error');
		
		// Check schedule periods for times being set/accurate
		for(const schedule_period_id in schedulePeriods)
		{
			const schedule_period_info = schedulePeriods[schedule_period_id];
			
			const { start_time, end_time } = schedule_period_info;
			
			const start_time_date = new Date("1970-01-01 " + start_time + ":00 Z");
			const end_time_date = new Date("1970-01-01 " + end_time + ":00 Z");
			
			// Make sure start time and end times are all okay
			if(!start_time || !end_time || start_time === '00:00' || start_time === '00:00:00' || end_time === '00:00' || end_time === '00:00:00')
			{
				error_message = "Make sure to enter a start and end time for all time slots!";
				
				setErrorMessage(error_message);
				$(`.ess-period-inner-container[data-scheduleperiodid='${schedule_period_id}']`).find(".time-select-input").addClass('error');
				
				return false;
				
			}
			else if(start_time_date > end_time_date || start_time_date.getTime() === end_time_date.getTime())
			{
				error_message = "Start times need to be before end times! Make sure to check them carefully.";
				
				setErrorMessage(error_message);
				$(`.ess-period-inner-container[data-scheduleperiodid='${schedule_period_id}']`).find(".time-select-input").addClass('error');
				
				return false;
			}
		}

		// Check schedule structure for course periods set for all schedule periods
		for(const day in scheduleStructure)
		{
			const day_schedule_periods = scheduleStructure[day];

			// Iterate day schedule periods to see if course periods are set/have a name
			for(const schedule_structure_row of day_schedule_periods)
			{
				const course_period_id = schedule_structure_row.course_period_id;
				const schedule_period_id = schedule_structure_row.schedule_period_id;
				const schedule_period_info = schedulePeriods[schedule_period_id];
				const schedule_period_name = schedule_period_info.period_name;

				if(!course_period_id)
				{

					error_message = `Missing a rotating period in ${schedule_period_name} on Day ${day}`;
					setErrorMessage(error_message);
					$(`.ess-droppable-inner-container[data-scheduleperiodid='${schedule_period_id}']`).addClass('error');
					return false;
				}
				
				const course_period_info = coursePeriods[course_period_id];
				const course_period_name = course_period_info.period_name;

				if(schedule_type !== "daily" && (!course_period_name || course_period_name === "--"))
				{
					error_message = `Missing a name for your rotating period(s) in ${schedule_period_name} on Day ${day}`;
					setErrorMessage(error_message);
					return false;
				}
			}
		}
		
		if(error_message) return false;
		
		handleMoveToNextScreen();
	}
	
	/////////////////////////////
	///// DO ON STATE CHANGE ////
	////////////////////////////
	// Run every render
	useEffect(() => {
		initializeSortable();
	});
	
	////////////////////////////////////////////////
	//////////////////// ON LOAD ///////////////////
	////////////////////////////////////////////////
	useEffect(() => {
		const createScheduleStructure = (all_schedule_data) => {
			const lunch_info = all_schedule_data[0];
			const schedule_structure_data = all_schedule_data[1];
	
			const schedule_structure = schedule_structure_data.schedule_structure;
			const schedule_periods = schedule_structure_data.schedule_periods;
			const course_periods = schedule_structure_data.course_periods;
			const temp_lunches = lunch_info.lunches;
	
			let schedule_periods_json = {};
			let course_periods_json = {};
			let lunch_json = {};
			
			// If schedule structure has been set, then create layout based on existing data
			if(!isEmpty(schedule_structure))
			{	
				// If course periods exist, create the course periods object
				if(!isEmpty(course_periods))
				{
					// Start saving course periods from the database
					let index = 0;
					for(const course_period_id in course_periods)
					{
						const period_name = course_periods[course_period_id]['period_name'];
						
						course_periods_json[course_period_id] = {course_period_id:course_period_id, period_name:period_name, currently_editing:false, color:colors.current[index]};
						
						index++;
					}
				}
				
				// If schedule periods exist, create schedule periods object
				if(!isEmpty(schedule_periods))
				{
					// Start saving schedule periods from the database
					for(const schedule_period_id in schedule_periods)
					{
						const {period_name, start_time, end_time, days, include_in_match} = schedule_periods[schedule_period_id];
						const currently_editing = false;
						
						schedule_periods_json[schedule_period_id] = {schedule_period_id:schedule_period_id, period_name:period_name, start_time:start_time, end_time:end_time, currently_editing:currently_editing, days:days, include_in_match:include_in_match};
					}
				}
	
				if(!isEmpty(temp_lunches)) 
				{
					// Start saving lunches from the database
					for(const lunch_id in temp_lunches)
					{
						const {lunch_name, start_time, end_time, days} = temp_lunches[lunch_id];
						const currently_editing = false;
						
						lunch_json[lunch_id] = {lunch_id:lunch_id, lunch_name:lunch_name, start_time:start_time, end_time:end_time, currently_editing:currently_editing, days:days};
					}
					setLunches(lunch_json);
				}

				setScheduleStructure(schedule_structure);
			}
			else
			{
				schedule_periods_json['1'] = [];
			}
	
			setCoursePeriods(course_periods_json);
			setSchedulePeriods(schedule_periods_json);
			setScheduleStructureLoading(false);
		}

		const getNewData = async () => {
			const get_lunch = getData('lunchInfo', '/get-lunch-info', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true);
			
			const get_schedule_structure = getData('scheduleStructure', '/get-schedule-structure', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true);
	
			const all_schedule_data = await Promise.all([get_lunch, get_schedule_structure]);
			createScheduleStructure(all_schedule_data);
		}

		getNewData();
	}, [schoolInfo.school_id, schoolInfo.current_schedule_version_id]);
	
	////////////////////////////////////////////////
	//////////////////// DISPLAY ///////////////////
	////////////////////////////////////////////////
	const schedule_type = scheduleInfo.schedule_type;
	const num_days_in_cycle_array = Object.keys(scheduleStructure);

	// Figure out the course periods to display for dragging
	const course_period_array = Object.keys(coursePeriods).map(course_period_id => {
		return coursePeriods[course_period_id];
	});
	const sorted_course_periods = sortArrayOfObjects(course_period_array, 'period_name', 'text', 'asc');

	// Figure out what lunches to show for this day
	const lunches_to_show = Object.keys(lunches).reduce((results, lunch_id) => {
		const lunch_info = lunches[lunch_id];
		const lunch_days = lunch_info.days;

		lunch_info.lunch_id = lunch_id;

		if(lunch_days.includes(`${currentDay}`)) results.push(lunch_info);
		return results;
	}, []);
	const lunches_to_show_sorted = sortArrayOfObjects(lunches_to_show, 'start_time', 'text', 'asc');

	// Calculate lunch grid
	const num_schedule_periods = (!isEmpty(scheduleStructure)) ? scheduleStructure[currentDay].length : Object.keys(schedulePeriods);
	const grid_rows = num_schedule_periods * 95 - 60;

	// Figure out what data is editable on this day
	let edittable_num_days = false;
	let edittable_course_periods = false;

	if(schedule_type === 'block')
	{
		edittable_num_days = true;
		edittable_course_periods = true;
	}
	else if(schedule_type === 'ab')
	{
		edittable_course_periods = true;
	}
	else if(schedule_type === 'ab_skinny')
	{
		edittable_course_periods = true;
	}
	else if (schedule_type === 'custom')
	{
		edittable_num_days = true;
		edittable_course_periods = true;
	}

	let logged_displayed_lunches = [];

	//console.log({currentDay, schedulePeriods, coursePeriods, scheduleStructure, lunches_to_show_sorted});

	return (
		<div className='schedule-structure-screen' id="create_schedule_structure_container">
			{scheduleStructureLoading ? 
				(
					<div className='center-text'>
						<img src={require("../../../images/balls.gif")} alt='loading...' style={{height:'40px'}}/>
					</div>
				):(subscreenRestricted) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>No Access</h1>
						<div>You do not have access to this screen</div>	
					</div>
				):(subscreenLocked) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>Temporarily Locked</h1>
						<div>This screen is locked until all other prior steps are completed</div>	
					</div>
				): 
				(
					<>
					<h1 className='progress-main-header'>Schedule Structure</h1>
					<p className='progress-subheader'>Let's set up the rest of your schedule structure!</p>
					<div id='ess-container'>
						{scheduleStructureLoading ? 
							(
								<div className='center-text'>
									<img src={require('../../../images/balls.gif')} alt='loading' style={{height:'40px'}}/>
								</div>
							) : 
							(
								<>
								<div id='ess-header-container'>
									{num_days_in_cycle_array.map((option, i) => {
										const index = i + 1;
										const last_day_bool = (num_days_in_cycle_array.length === index);
										const day_name = ((index in dayNames.current) && (schedule_type in dayNames.current[index])) ? dayNames.current[index][schedule_type] : `Day ${index}`; 
										return (
											<div className={`ess-header-day ${currentDay === index && 'ess-header-day-selected'}`} key={i} onClick={() => changeDay(index)}>
												<div className='ess-header-day-name'>{day_name}</div>
												{(edittable_num_days && last_day_bool && num_days_in_cycle_array.length > 1) &&
													<div className='ess-remove-header-day' onClick={(e) => removeDay(e, option)}><FontAwesomeIcon icon={faTimes} className='purple-link'/></div>
												}
											</div>
										)
									})}
									{edittable_num_days &&
										<div className='purple-link medium-text' onClick={addNewDay}><FontAwesomeIcon icon={faPlus} style={{margin:'0px 3px 0px 20px'}}/> Add Day</div>
									}
								</div>
								<div id='ess-full-schedule-container'>
									<div>
										<div id='ess-sortable-schedule-container'>
											{scheduleStructure[currentDay].map((rotation_info, rotation_index) => {
												const {schedule_period_id, course_period_id} = rotation_info;
												const schedule_period_info = schedulePeriods[schedule_period_id];
												const course_period_info = (course_period_id) ? coursePeriods[course_period_id] : {period_name:null, color:'white'};
												
												// Skip schedule periods that don't exist anymore
												if(!schedule_period_info) return null;

												const { period_name:schedule_period_name, start_time, end_time, currently_editing, include_in_match } = schedule_period_info;
												const { period_name:course_period_name, color=null } = course_period_info;

												const background_class = (color) ? color : 'white';
												const color_class = (course_period_id) ? 'white-text' : 'dark-gray-text';

												const lunches_that_overlap = lunches_to_show_sorted.reduce((results, lunch_info) => {
													const lunch_id = lunch_info.lunch_id;
													const lunch_start_time = lunch_info.start_time;
													const lunch_end_time = lunch_info.end_time;
													const show_lunch = timesAreOverlapping(start_time, end_time, lunch_start_time, lunch_end_time) && !logged_displayed_lunches.includes(lunch_id);

													if(show_lunch)
													{
														results.push(lunch_info);
														logged_displayed_lunches.push(lunch_id);	
													}

													return results;
												}, []);

												const new_include_in_match = (include_in_match === '0') ? '1' : '0';
												
												return (
													<div className='ess-period-parent-container grabbable' key={`before-${schedule_period_id}`} data-scheduleperiodid={schedule_period_id}>
														<div className='ess-drag-dots-container'>
															<FontAwesomeIcon className='ess-drag-dots' icon={faEllipsisV}/>
														</div>
														<div className='ess-period-inner-container-parent'>
															<div className='ess-period-inner-container' data-scheduleperiodid={schedule_period_id}>
																<div className='ess-period-info-outer-container' data-scheduleperiodid={schedule_period_id} data-rotationindex={rotation_index}>
																	<div className='ess-period-info-inner-container'>
																		<div>
																			<div className='ess-period-name-container'>
																			{currently_editing ?
																				(
																					<>
																					<input className='schedule-setup-period-name-input' type='text' defaultValue={schedule_period_name} onKeyDown={saveSchedulePeriodName} autoFocus={true} onFocus={handleFocus}/>
																					<FontAwesomeIcon className='ess-cancel-edit-schedule-period' icon={faTimes} onClick={cancelEditSchedulePeriodName}/>
																					<FontAwesomeIcon className='ess-confirm-edit-schedule-period' icon={faCheck} onClick={saveSchedulePeriodName}/>
																					</>
																				):
																				(
																					<>
																					<div className='ess-period-info-label' onDoubleClick={() => openEditSchedulePeriod(schedule_period_id)}>{schedule_period_name}</div>
																					<FontAwesomeIcon className='schedule-setup-edit-schedule-period-name purple-link' icon={faEdit} onClick={() => openEditSchedulePeriod(schedule_period_id)}/>
																					</>
																				)
																			}
																			</div>
																			<div className='ess-time-select-container'>
																				<div className='time-select' key={`starttime-${schedule_period_id}`}>
																					<input className="time-select-input time-select-start-time" type="time" defaultValue={start_time} onChange={(e) => onTimeChange('start_time', schedule_period_id, e.target.value)}/>
																				</div>
																				<div>to</div>
																				<div className='time-select' key={`endtime-${schedule_period_id}`}>
																					<input className="time-select-input time-select-end-time" type="time" defaultValue={end_time} onChange={(e) => onTimeChange('end_time', schedule_period_id, e.target.value)} />
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className='ess-dropdown-outer-container'>
																	{course_period_id ? 
																	(
																		<div className={`ess-droppable-inner-container course-period-droppable ${background_class} ${color_class}`} data-day={currentDay} data-scheduleperiodid={schedule_period_id} data-courseperiodid={course_period_id} data-courseperiodname={course_period_name}>
																			<div>{(schedule_type !== 'daily') ? course_period_name : rotation_index + 1}</div>
																			<div className='ess-remove-course-period opaque-link'>
																				{(schedule_type !== 'daily') && 
																					<FontAwesomeIcon icon={faTimes} onClick={() => updateScheduleStructureCoursePeriod(schedule_period_id, null)}/>
																				}
																			</div>
																		</div>
																	):
																	(
																		<div className={`ess-droppable-inner-container course-period-droppable ess-droppable-no-course-period`} data-day={currentDay} data-scheduleperiodid={schedule_period_id} data-courseperiodid='' data-courseperiodname=''>
																			<div>[Drop a rotating period here]</div>
																		</div>
																	)}
																</div>
															</div>
															<div className='ess-remove-schedule-period'>
																<FontAwesomeIcon icon={faTimes} className='purple-link' onClick={() => removeSchedulePeriod(schedule_period_id)}/>
															</div>
															<div className='ess-include-in-match-checkmark' onClick={() => updateIncludeInMatch(schedule_period_id, new_include_in_match)}>
																{(include_in_match === '0') ? 
																	(
																		<FontAwesomeIcon className='fas-checkbox-unchecked dark-gray-text' icon={faSquare}/>
																	) : 
																	(
																		<FontAwesomeIcon className='fas-checkbox-checked purple-link' icon={faCheckSquare}/>
																	)
																}
																<div>Include in Match?</div>
															</div>
															{lunches_that_overlap.map(lunch_info => {
																const lunch_id = lunch_info.lunch_id;
																const lunch_name = lunch_info.lunch_name;
																
																return (
																	<div className='ess-schedule-lunch dark-purple-gray' key={`schedule-lunch-${lunch_id}`}>{lunch_name}</div>
																)
															})}
														</div>
													</div>
												)
											})}
										</div>
									</div>
									<div id='ess-lunches-container'>
										<h4 className='dark-blue-text' style={{height:'20px', margin:'20px 0px 20px 0px'}}>LUNCHES</h4>
										<div id='ess-lunch-grid' style={{gridTemplateRows: `repeat(${grid_rows}, 1px)`}}>
											{lunches_to_show.map((lunch_info, i) => {
												const lunch_id = lunch_info.lunch_id;
												const lunch_name = lunch_info.lunch_name;
												const lunch_start_time = lunch_info.start_time;
												const lunch_end_time = lunch_info.end_time;
												const currently_editing = lunch_info.currently_editing;

												return (
													<div className='ess-day-lunch-box dark-purple-gray' key={i}>
														<div className='ess-lunch-name-container' data-lunchid={lunch_id}>
														{currently_editing ?
															(
																<>
																<input className='ess-lunch-name-input' type='text' defaultValue={lunch_name} onKeyDown={saveLunchName} autoFocus={true} onFocus={handleFocus}/>
																<FontAwesomeIcon className='ess-cancel-edit-lunch-name' icon={faTimes} onClick={() => cancelEditLunchName(lunch_id)}/>
																<FontAwesomeIcon className='ess-confirm-edit-lunch-name' icon={faCheck} onClick={saveLunchName}/>
																</>
															):
															(
																<>
																<div className='white-text' onDoubleClick={() => openEditLunch(lunch_id)}>{lunch_name}</div>
																<FontAwesomeIcon className='schedule-setup-edit-schedule-period-name opaque-link' icon={faEdit} onClick={() => openEditLunch(lunch_id)}/>
																</>
															)
														}
														</div>
														<div className='ess-time-select-container'>
															<div className='time-select' key={`starttime-${lunch_id}`}>
																<input className="time-select-input time-select-start-time" type="time" defaultValue={lunch_start_time} onChange={(e) => onLunchTimeChange('start_time', lunch_id, e.target.value)}/>
															</div>
															<div>to</div>
															<div className='time-select' key={`endtime-${lunch_id}`}>
																<input className="time-select-input time-select-end-time" type="time" defaultValue={lunch_end_time} onChange={(e) => onLunchTimeChange('end_time', lunch_id, e.target.value)} />
															</div>
															<div className='ess-remove-lunch'>
																<FontAwesomeIcon icon={faTimes} className='opaque-link' onClick={() => removeLunch(lunch_id)}/>
															</div>
														</div>
													</div>
												)
											})}
										</div>
									</div>
									{edittable_course_periods ?
									(
										<div>
											<h4 className='dark-blue-text' style={{minWidth:'250px', height:'20px', margin:'30px 0px 20px 0px'}}>ROTATING PERIODS</h4>
											<div className='ess-draggable-course-periods-parent'>
												{sorted_course_periods.map(course_period_info => {
													const { course_period_id, period_name, currently_editing, color } = course_period_info;
													
													return (
														<div className={`grabbable course-period-draggable ${color}`} data-courseperiodid={course_period_id} data-courseperiodname={period_name} data-color={color} key={course_period_id}>
															{!currently_editing &&
																<>
																<div className='schedule-setup-course-period-dots'>
																	<FontAwesomeIcon className='ess-drag-dots white-text' icon={faEllipsisV}/>
																</div>
																<div className='schedule-setup-course-period-name' onDoubleClick={openEditCoursePeriod}>	
																	<div className='schedule-setup-course-period-name-text' onDoubleClick={openEditCoursePeriod}>{period_name}</div>
																	<FontAwesomeIcon className='schedule-setup-edit-course-period-name opaque-link' icon={faEdit} onClick={openEditCoursePeriod}/>
																	<FontAwesomeIcon className='schedule-setup-remove-course-period-name opaque-link' icon={faTimes} onClick={() => removeCoursePeriod(course_period_id)}/>
																</div>
																</>
															}
															{currently_editing &&
																<div className="schedule-setup-course-period-name-input-container">
																	<input className="schedule-setup-course-period-name-input" type="text" defaultValue={period_name} onKeyDown={saveCoursePeriodName} autoFocus={true} onFocus={handleFocus}/>
																	<div className='ess-edit-icon-container'>
																		<FontAwesomeIcon className='ess-edit-icon' icon={faTimes} onClick={cancelEditCoursePeriodName}/>
																		<FontAwesomeIcon className='ess-edit-icon' icon={faCheck} onClick={saveCoursePeriodName}/>
																	</div>
																</div>
															}
														</div> 
													)
												})}
											</div>
										</div>
									):
									(
										<div></div>
									)}
									<div className='purple-link text-align-center align-center' onClick={() => addNewSchedulePeriod(currentDay)}>
										<div><FontAwesomeIcon icon={faPlusCircle} style={{fontSize:'36px', margin:'20px 0px 5px 0px'}}/></div>
										<div className='small-text'>Add a Period</div>
									</div>
									<div className='purple-link text-align-center align-center' onClick={addNewLunch}>
										<div><FontAwesomeIcon icon={faPlusCircle} style={{fontSize:'36px', margin:'20px 0px 5px 0px'}}/></div>
										<div className='small-text'>Add a Lunch</div>
									</div>
									{edittable_course_periods ?
									(
										<div className='purple-link text-align-center align-center' onClick={addNewCoursePeriod}>
											<div><FontAwesomeIcon icon={faPlusCircle} style={{fontSize:'36px', margin:'20px 0px 5px 0px'}}/></div>
											<div className='small-text'>Add a Rotating Period</div>
										</div>
									):
									(
										<div></div>
									)}
								</div>
								</>
							)
						}
						<div className="error-message-no-box" style={{margin:'30px', fontSize:'15px'}}>{errorMessage}</div>
						<div className='fixed-bottom-save-container'>
							<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Submit Schedule Structure' passedLoading={errorMessage ? false : true} onClickFunction={submitStructure} />
						</div>
					</div>
					</>
				)
			}
		</div>
	);
}