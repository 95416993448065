import React, { useState , useEffect , useRef } from 'react';
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import Fuse from 'fuse.js';

import DepartmentProgressScreen from '../department_progress_screen';
import EditClassroomData from '../../modals/edit_classroom';
import Dropdown from '../../../Dropdown';
import Button from '../../../Button';

import { useOutsideClick, getData, calculateConflicts } from '../../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUserCircle, faHourglassHalf, faCheckCircle, faCheck, faExclamationCircle, faSquare, faCheckSquare, faTimes, faSearch, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default function CleanClassroomDetails({isLoading, subscreenRestricted, subscreenLocked, subscreen, subscreenResponsibleRoleID, supervisors, openSupervisorsList, closeSupervisorsList, addSupervisorToDepartment, removeSupervisorFromDepartment, users, userIsSupervisor, userIsAdmin, departments, setDepartments, departmentID, sortedDepartments, changeDepartment, userInfo, schoolInfo, schoolType, scheduleInfo, teachers, courses, classrooms, setClassrooms, sections, addNewClassroomOpen, setAddNewClassroomOpen,scheduleStructureDataLoading, scheduleStructureData, subscreenSelect, submitDepartment, reviewing, changeDepartmentResource, setRolePages, showNextButton, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo}) {
	const ref = useRef();
	
	const [classroomTypeOptions, setClassroomTypeOptions] = useState([]);
	const [classroomResults, setClassroomResults] = useState([...classrooms]);

	const [addClassroomOpen, setAddClassroomOpen] = useState(false);
	const [changesSavedMessage, setChangesSavedMessage] = useState(false);
	
	/////////////////////////////
	///// UTILITY FUNCTIONS /////
	/////////////////////////////
	useOutsideClick(ref, () => {
		closeSupervisorsList();
	});
	
	const addSearch = (e) => {
		const search_value = e.target.value;
		const fuse = new Fuse(classrooms, {
			keys: ['classroom_name'],
			threshold: .6
		})
		
		const results = fuse.search(search_value);
		const classroom_results = results.map(result => result.item).splice(0,5);
		
		setClassroomResults(classroom_results);
	}
	
	////////////////////////////
	///// UPDATE FUNCTIONS /////
	////////////////////////////
	const updateClassroomDetails = async (classroom_id, key, new_value) => {
		const classroom_index = classrooms.findIndex(classroom => classroom.classroom_id === classroom_id);
		const classroom_info = classrooms[classroom_index];
		
		classroom_info[key] = new_value;
		
		const data = {school_id:schoolInfo.school_id, department_id:departmentID, schedule_version_id:schoolInfo.current_schedule_version_id, ...classroom_info};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/clean-data/update-classroom-details';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			await API.post(apiName, url, myInit);
			
			///////////////////////
			///// UPDATE JSON /////
			///////////////////////
			const department_index = departments.findIndex(department => department.department_id === departmentID);

			if(userIsAdmin) 
			{
				if(department_index !== -1)
				{
					departments[department_index].subscreens[subscreen].submitted = false;
					departments[department_index].subscreens[subscreen].confirmed = false;
				}

				// Reset overall progress to update the Progress screen
				getData('schoolProgress', '/get-school-progress', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
					const role_pages = user_progress_data.role_pages;
					
					setRolePages(role_pages);
				});
			}

			if(userIsAdmin && departments[department_index].subscreens[subscreen].confirmed) setChangesSavedMessage(true);
			
			// If key is classroom_type_id then change classroom_type_text in classrooms too
			if(key === 'classroom_type_id')
			{
				const classroom_type_index = scheduleStructureData.classroom_types.findIndex(classroom_type => classroom_type.classroom_type_id === new_value);
				if(classroom_type_index !== -1)
				{
					const classroom_type = scheduleStructureData.classroom_types[classroom_type_index].classroom_type;
					classroom_info.classroom_type = classroom_type;
				}
			}
					
			setClassrooms([...classrooms]);
			setDepartments([...departments]);

			////////////////////////
			/// Update conflicts ///
			////////////////////////
			const affected_sections = sections.reduce((results, section) => {
				const section_classroom_id = section.classroom_id;
				if(classroom_id && classroom_info.classroom_id === section_classroom_id) results.push(section.section_id);
				return results;
			}, []);
			const conflicts_to_check = ['classroom_double_booked'];

			if(affected_sections.length > 0) calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:affected_sections, conflicts_to_check:conflicts_to_check, update_all:false});
		} catch(e)
		{
			console.log(e.response);
		}
	}

	const updateClassroom = (changeType, dataType, data_id, old_department_id, new_department_id) => {
		changeDepartmentResource(changeType, dataType, data_id, old_department_id, new_department_id, () => {
			if(changeType === 'add' && userIsSupervisor) 
			{
				const department_index = departments.findIndex(department => department.department_id === departmentID);

				if(department_index !== -1)
				{
					departments[department_index].subscreens[subscreen].submitted = false;
					departments[department_index].subscreens[subscreen].confirmed = false;
				}
				
				// Update cleaning screen progress (for check marks)
				departments[department_index].subscreens['classroom_details'].submitted = false;
				departments[department_index].subscreens['classroom_details'].confirmed = false;

				departments[department_index].subscreens['course_classrooms'].submitted = false;
				departments[department_index].subscreens['course_classrooms'].confirmed = false;

				departments[department_index].subscreens['teacher_classrooms'].submitted = false;
				departments[department_index].subscreens['teacher_classrooms'].confirmed = false;

				setDepartments([...departments]);

				// Reset overall progress to update the Progress screen
				getData('schoolProgress', '/get-school-progress', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
					const role_pages = user_progress_data.role_pages;
					
					setRolePages(role_pages);
				});
			}
		});
	}

	//////////////////////////////
	///// DO ON STATE CHANGE /////
	//////////////////////////////
	useEffect(() => {
		setAddClassroomOpen(false);
		setClassroomResults([]);
	}, [departmentID]);

	useEffect(() => {
		if(!departmentID || !userInfo || !users || !departments) return;

		const department_index = departments.findIndex(department => department.department_id === departmentID);
		const department_info = (department_index !== -1) ? departments[department_index] : null;
		const department_supervisors = (department_info) ? department_info.supervisors : [];

		const available_users = users.filter(user => (user.role_id === '1' || user.role_id === '2') || department_supervisors.findIndex(supervisor => supervisor.user_id === user.user_id) !== -1);

		setScreenAllowsUniversalSidebar(true);
		setUniversalSidebarLoadingInfo({sidebar_name:"Classroom Details Notes", sidebar_type:'notes', subscreen:'classroom_details', department_id:departmentID, user_id:userInfo.user_id, available_users:available_users, help_link_path:'/app/hsms/documentation/clean_data/clean_department_data'});
	}, [departmentID, userInfo, users, departments, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo]);

	useEffect(() => {
		if(!scheduleStructureDataLoading) 
		{
			const classroom_types = scheduleStructureData.classroom_types.reduce((result, classroom_type) => {
				const classroom_type_option = {value: classroom_type.classroom_type_id, display:classroom_type.classroom_type};
				
				result.push(classroom_type_option);
				
				return result;
			}, []);
			
			setClassroomTypeOptions([...classroom_types]);
		}
	}, [scheduleStructureDataLoading, scheduleStructureData]);
	
	const row_style = {gridTemplateColumns:`300px repeat(2, 1fr) 40px`, gridColumnGap: '5px'};
	
	const maxCapacityOptions = [];
	for (var i = 0; i < 1000; i++) {
	  maxCapacityOptions.push({value:`${i}`, display:i});
	}
	
	const department_index = departments.findIndex(department => department.department_id === departmentID);
	const department_info = departments[department_index];
	const subscreen_info = (department_info) ? department_info.subscreens[subscreen] : null;
	
	const current_screen_submitted = (subscreen_info) ? subscreen_info.submitted : false;
	const current_screen_confirmed = (subscreen_info) ? subscreen_info.confirmed : false;
	const current_screen_unlocked= (subscreen_info) ? subscreen_info.unlocked : false;
	
	const show_current_screen = (current_screen_unlocked && (reviewing || userIsSupervisor || userIsAdmin));
	const screen_complete_for_user = ((current_screen_confirmed) || (userIsSupervisor && !userIsAdmin && current_screen_submitted));
	
	const department_classrooms = classrooms.filter(classroom => classroom.departments.includes(departmentID));
	
	return (
		<>
		<h1 className='progress-main-header'>Classroom Details</h1>
		<p className='progress-subheader'>Tell us a little more about your classrooms.</p>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(!departmentID) ? 
		(
			<DepartmentProgressScreen subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} subscreenSelect={subscreenSelect} userInfo={userInfo} userIsAdmin={userIsAdmin} userIsSupervisor={userIsSupervisor} />
		):(!userIsSupervisor) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to change this department</div>	
			</div>
		):(departmentID) ?
		(
			<>
			<div id='clean-data-content'>
				<div>
					<FontAwesomeIcon icon={faChevronLeft} className='progress-back-to-departments-btn' onClick={()=>changeDepartment(null)}/>
				</div>
				<div className='clean-data-content-inner-content'>
					<div className='clean-data-submit-btn-container'>
						{!isLoading &&
							<>
							{(reviewing && screen_complete_for_user) &&
								<>
								{changesSavedMessage && 
									<div className='all-changes-saved-message' style={{margin:'15px 0px 0px 0px'}}>
										<FontAwesomeIcon className='green-text' icon={faCheckCircle}/>
										<div>
											<h5>Changes Saved</h5>
											<div className='small-text'>All your changes have been saved</div>
										</div>
										<FontAwesomeIcon className='x-remove' icon={faTimes} onClick={() => setChangesSavedMessage(false)}/>
									</div>
								}
								</>
							}
							{(!screen_complete_for_user && show_current_screen) &&
								<Button key={departmentID} classes={['mark-as-complete-btn']} selected={false} text={`${!current_screen_submitted ? 'Mark As Complete' : 'Confirm Department'}`} icon={faCheck} iconPosition='left' onClickFunction={() => submitDepartment(departmentID)} />
							}
							</>
						}
					</div>
					<div className='clean-data-content-header-container'>
						<h1 className='clean-data-content-header'>{department_info && department_info.department} Department</h1>
						{current_screen_confirmed ?
							(
								<div className='clean-data-status green-text'><div>Complete</div><FontAwesomeIcon icon={faCheckCircle}/></div>
							): (current_screen_submitted) ?
							(
								<div className='clean-data-status bright-yellow-text'><div>Pending Confirmation</div><FontAwesomeIcon icon={faCheckCircle}/></div>
							): (null)
						}
					</div>
					<div className='department-supervisor-parent-container'>
						<>
						{(department_info && department_info.supervisors.length === 0) &&
							<>
							<div className='department-supervisor-inner-container'>
								<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
								<div className='department-supervisor-name'>No Supervisor Assigned</div>
							</div>
							</>
						}
						{department_info && department_info.supervisors.map((supervisor, index) => {
							return (
								<div className='department-supervisor-outer-container' key={index}>
									<div className='department-supervisor-inner-container'>
										{supervisor.img_url &&
											<img className='department-supervisor-img' src={require(`../../../../images/users/${supervisor.img_url}`)} alt='user' />
										}
										{!supervisor.img_url &&
											<FontAwesomeIcon className='department-supervisor-user-icon gray-text' icon={faUserCircle}/>
										}
										<div className='department-supervisor-name'>{supervisor.last_name}, {supervisor.first_name}</div>
										{userIsAdmin &&
											<FontAwesomeIcon className='gray-text-hover' icon={faTimes} onClick={() => removeSupervisorFromDepartment(supervisor.user_id)}/>
										}
									</div>
								</div>
							)
						})}
						{userIsAdmin &&
							<>
							<div className={`clean-data-add-supervisor-link small-text click-restricted ${(department_info && department_info.supervisors.length === 0) ? 'blue-link' : 'gray-to-blue-link'}`} onClick={() => openSupervisorsList(departmentID)}>Add a Supervisor</div>
							<div className='supervisor-options-container'>
								{(department_info && department_info.supervisors_open) &&
									<div className='data-options-container' ref={ref}>
										{supervisors.map((supervisor_info, index) => {
											
											return (
												<div className='data-options-option assign-resourses-supervisor-option' key={index} onClick={() => addSupervisorToDepartment(supervisor_info)}>
													{supervisor_info.img_url &&
														<img className='department-supervisor-img' src={require(`../../../../images/users/${supervisor_info.img_url}`)} alt='supervisor' />
													}
													{!supervisor_info.img_url &&
														<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
													}
													<div className='department-supervisor-name'>{supervisor_info.last_name}, {supervisor_info.first_name}</div>
												</div>
											)	
										})}
										<Link className='data-options-option blue-text' to={{pathname:`/app/${schoolType}/settings/users`, redirect:`/app/${schoolType}/progress/clean_data/classroom_details`}} style={{borderTop:'1px solid #efefef'}} onClick={() => closeSupervisorsList(departmentID)}>Add new Supervisor</Link>
									</div>
								}
							</div>
							</>
						}
						</>
					</div>
					<div className='clean-data-container'>
						{isLoading ?
							(
								<div className="center-text">
									<img className='clean-data-loader' src={require(`../../../../images/balls.gif`)} alt='loading' />
								</div>
							):
							(
								<>
								{(!current_screen_unlocked) ?
									(
										<div className='general-screen-message'>
											<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
											<h1>Locked</h1>
											<div>This department has previous steps which have not been completed. Please review previous steps and make sure they are completed and saved. This page should open automatically once all previous steps have been finished.</div>
										</div>
									):(userIsAdmin && current_screen_unlocked && !show_current_screen && !current_screen_submitted && !userIsSupervisor) ?
									(
										<div className='general-screen-message'>
											<FontAwesomeIcon className='turquoise-text' style={{fontSize:'60px'}} icon={faHourglassHalf}/>
											<h1>Pending...</h1>
											<div>Waiting on confirmation from department supervisor</div>
										</div>
									):(show_current_screen) ?
									(
										<>
										{department_classrooms.length === 0 ?
											(
												<div className='clean-data-no-data-container'>
													<FontAwesomeIcon className='clean-data-no-data-icon dark-blue-text' icon={faExclamationCircle}/>
													<div>No classrooms found</div>
													<p>Please confirm that this is correct by submitting the screen</p>
												</div>
											) : 
											(
												<>
												<div className='fixed-heading-on-scroll'>
													<div className='clean-data-header-row' style={{gridTemplateColumns:'300px 1fr 40px', margin:'0px 0px 5px 0px'}}>
														<div></div>
														<div className='dark-blue' style={{textAlign:'center', padding:'5px', borderRadius:'5px'}}>Classroom Details</div>
													</div>
													<div className='clean-data-header-row' style={row_style}>
														<div></div>
														<div>
															<div className="clean-data-heading small-text">Classroom Type</div>
														</div>
														<div>
															<div className="clean-data-heading small-text">Allows Multiple Sections</div>
														</div>
													</div>
													<div className='clean-data-header-row' style={row_style}>
														<div></div>
														<div>
															<div className="small-text center-text dark-blue-text bold-text">Adding a classroom type is optional</div>
														</div>
														<div></div>
													</div>
													<div></div>
												</div>
												{department_classrooms.map((classroom_info, i) => {
													const classroom_id = classroom_info.classroom_id;
													const multi_class = classroom_info.multi_class;
													const dual_dept_bool = ([...new Set(classroom_info.departments)].length > 1) ? true : false;
													
													return (
														<div key={i}>
															<div className='clean-data-row' style={row_style} data-classroomid={classroom_id}>
																<div className='clean-data-col'>
																	<div>Room {classroom_info.classroom_name} {dual_dept_bool && <span className='orange-text small-text'>Dual Depts.</span>}</div>
																</div>
																<div className='clean-data-col max-num-sections-col'>
																	<Dropdown data={classroomTypeOptions} currentValue={classroom_info.classroom_type_id} handleChange={(new_value) => updateClassroomDetails(classroom_id, 'classroom_type_id', new_value)} />
																</div>
																{multi_class === '0' ?
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-unchecked' icon={faSquare} onClick={() => updateClassroomDetails(classroom_id, 'multi_class', '1')}/>
																	) : 
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateClassroomDetails(classroom_id, 'multi_class', '0')}/>
																	)
																	
																}
																<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => updateClassroom('remove', 'classroom', classroom_id, departmentID, null)} style={{justifySelf:'center'}}/>
															</div>
															{multi_class === '1' &&
																<div className='clean-data-max-capacity-row'>
																	<div></div>
																	<div className='clean-data-max-capacity-container'>
																		<div className='small-text'>Max Capacity</div>
																		<div className='small-text dark-gray-text' style={{margin:'0px 0px 5px 0px'}}>Set the max number of students allowed in this room in any given period</div>
																		<div className='clean-data-col max-class-size-col'>
																			<Dropdown data={maxCapacityOptions} currentValue={classroom_info.max_capacity} handleChange={(new_value) => updateClassroomDetails(classroom_id, 'max_capacity', new_value)} />
																		</div>
																	</div>
																</div>
															}
														</div>
													)
												})}
												</>
											)
										}
										<div className='clean-data-add-new-data blue-link assign-resources-supervisor-confirm-add-new' onClick={() => setAddClassroomOpen(!addClassroomOpen)}>Add A Classroom</div>
										{addClassroomOpen &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='teacher-search-input' className='search-add-adding-input' onChange={(e) => addSearch(e)} placeholder='Search for course'/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => setAddClassroomOpen(!addClassroomOpen)}/>
												</div>
												{classroomResults.length !== 0 &&
													<div className='search-add-search-results'>
														<div className='search-add-did-you-mean'>Did you mean:</div>
														<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 1fr 1fr 50px'}}>
															<div className='search-add-label'>Classroom</div>
															<div className='search-add-label'>Classroom Type</div>
															<div className='search-add-label'>Department(s)</div>
															<div></div>
														</div>
														{classroomResults.map((classroom, i) => {
															const classroom_departments = classroom.departments;
															
															return (
																<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 1fr 1fr 50px'}}>
																	<div>{classroom.classroom_name}</div>
																	<div className='dark-gray-text small-text'>{classroom.classroom_type}</div>
																	<div>
																		{classroom_departments.length == 0 &&
																			<div>--</div>
																		}
																		{classroom_departments.map((department_id, i) => {
																			const department_info = departments.find(department => department.department_id === department_id);
																			const department_name = (department_info) ? department_info.department : null;

																			return (
																				<div key={i}>{department_name}</div>
																			)
																		})}
																	</div>
																	<div className='search-add-add-btn' onClick={() => updateClassroom('add', 'classroom', classroom.classroom_id, null, departmentID)}>Add</div>
																</div>
															)
														})}
														<div className='assign-resources-add-new-data-text dark-blue-text'>If you don&rsquo;t see the classroom you are looking for, you can also <span className='blue-link' onClick={() => setAddNewClassroomOpen(true)}>Add A New Classroom</span></div>
													</div>
												}
											</div>
										}
										{addNewClassroomOpen &&
											<EditClassroomData schoolInfo={schoolInfo} dataID={null} toggleEditData={()=>setAddNewClassroomOpen(false)} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} classrooms={classrooms} courses={courses} teachers={teachers} sections={sections} departments={departments} setDatabaseData={setClassrooms} defaultDepartmentID={departmentID}/>
										}
										</>
									):null
								}
								</>
							)
						}
					</div>
				</div>
			</div>
			</>
		):null}
		{showNextButton &&
			<div className='fixed-bottom-save-container'>
				<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Go to Next Step: Course Classrooms' onClickFunction={handleMoveToNextScreen} />
			</div>
		}
		</>
	);
}