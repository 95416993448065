import React, { useState , useEffect, useRef, useCallback } from 'react';
import swal from '@sweetalert/with-react';
import { API } from "aws-amplify";

import { filterArrayOfObjects, calculateAffectedSectionsForPlacements, saveSectionPlacement, sortArrayOfObjects, deleteSection, recalculateMatchPercent, calculateConflicts } from '../../../js/Helpers';

import SortToggle from '../../SortToggle';
import ListFilter from '../../ListFilter';
import EditSection from '../modals/edit_section';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit, faPlus, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

export default function DatabaseDataSections({ schoolInfo, databaseData, setDatabaseData, scheduleStructureData, scheduleInfo, courses, setCourses, teachers, setTeachers, classrooms, students, setStudents, departments, sections, setSections, subsections, setSubsections, labels, setLabels, lunchInfo, dataIsLoading}) {
	const [isLoading, setIsLoading] = useState(true);
	const [handleChangeComplete, setHandleChangeComplete] = useState(false);

	const [filteredResults, setFilteredResults] = useState([]);
	const [displayResults, setDisplayResults] = useState([]);
	const [isSearching, setIsSearching] = useState(false);

	const [showEditDataModal, setShowEditDataModal] = useState(false);
	const [currentDataID, setCurrentDataID] = useState(null);

	const [sortedBy, setSortedBy] = useState('course_name');
	const [sortAscDesc, setSortAscDesc] = useState('asc');
	const [dataSorted, setDataSorted] = useState(false);

	const filteredResultsRef = useRef(filteredResults);
	filteredResultsRef.current = filteredResults;
	
	const handleIsSearching = (e) => {
		if(e.keyCode !== 8)
		{
			setIsLoading(false);
			setIsSearching(true);
		}
		else
		{
			setIsLoading(true);
			setIsSearching(false);
		}
	}

	const removeSpansAndAddExtraData = useCallback((passed_data) => {
		let logged_spans = [];
		const new_database_data = passed_data.reduce((results, section) => {
			const section_type = section.section_type;

			if(section_type !== '1') return results; // remove preps and duties
			
			const section_course_id = section.course_id;
			const section_teacher_id = section.teacher_id;
			const section_span_id = section.span_id;

			const course_index = courses.findIndex(course => course.course_id === section_course_id);
			const course_info = (course_index !== -1) ? courses[course_index] : null;

			const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === section_teacher_id);
			const teacher_info = (teacher_index !== -1) ? teachers[teacher_index] : null;

			section.course_name = (course_info) ? course_info.name : null;
			section.course_code = (course_info) ? course_info.course_code : null;
			section.teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
			section.teacher_last_name = (teacher_info) ? teacher_info.name : null;

			if(logged_spans.includes(section_span_id)) return results;
			if(section_span_id) logged_spans.push(section_span_id);

			results.push(section);
			return results;
		},[]);

		return new_database_data;
	},[courses, teachers])
	
	const handleChange = useCallback(() => {
		if(filteredResultsRef.current.length === 0 && dataIsLoading) return;
		
		const search_value = document.getElementsByClassName("school-data-search-bar")[0].value;
		const new_database_data = removeSpansAndAddExtraData(filteredResultsRef.current);
		if(search_value.length > 1)
		{
			const filtered_results = filterArrayOfObjects(new_database_data, search_value, ['course_name', 'course_code', 'teacher_first_name', 'teacher_last_name']);
			setDisplayResults([...filtered_results]);
		}
		else
		{
			setDisplayResults([...new_database_data]);
		}
		
		setIsLoading(false);
		setIsSearching(false);
		setHandleChangeComplete(true);
	},[filteredResultsRef, dataIsLoading, removeSpansAndAddExtraData])
	
	const handleSortClick = (sortedData, sorted_by, sort_asc_desc) => {
		setSortedBy(sorted_by);
		setSortAscDesc(sort_asc_desc);
		const new_database_data = removeSpansAndAddExtraData(sortedData);

		setDisplayResults([...new_database_data]);
	}

	const handleFilterClick = (filteredData) => {
		const new_database_data = removeSpansAndAddExtraData(filteredData);

		setFilteredResults([...new_database_data]);
	}
	
	const toggleEditData = (dataID) => {
		if(dataID)
		{
			const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === dataID);
			if(subsection_index !== -1)
			{
				const section_info = subsections[subsection_index];
				dataID = section_info.section_id;
			}
		}

		setDataSorted(false);
		setCurrentDataID(dataID);
		setShowEditDataModal(!showEditDataModal);
	}

	const toggleLockSection = async (section_id, locked) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, section_id:section_id, locked:locked};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/update-lock-section';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			const response = await API.post(apiName, url, myInit);
			const affected_sections = response.data.affected_sections;
			
			affected_sections.forEach(section_id => {
				const section_index = sections.findIndex(section => section.section_id === section_id);
				const database_section_index = databaseData.findIndex(section => section.section_id === section_id);
				const filtered_results_index = filteredResults.findIndex(section => section.section_id === section_id);

				if(section_index !== -1) sections[section_index].locked = locked;
				if(database_section_index !== -1) databaseData[database_section_index].locked = locked;
				if(filtered_results_index !== -1) filteredResults[filtered_results_index].locked = locked;
			});
			
			const new_database_data = removeSpansAndAddExtraData(databaseData);
			const new_filtered_results_data = removeSpansAndAddExtraData(filteredResults);

			setDataSorted(false);
			setDatabaseData([...new_database_data]);
			setFilteredResults([...new_filtered_results_data]);
			setSections([...sections]);
		} catch(e)
		{
			console.log(e.response);
		}
	}
	
	const handeDeleteData = async (data_id) => {
		let delete_data_bool = true;
		
		const data_index = databaseData.findIndex(section => section.section_id === data_id);
		const data_info = databaseData[data_index];
		
		const options =  {
			title: "Are you sure?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div>
					<div>
						<p>Do you really want to delete Section {(data_info && ('section_number' in data_info)) && data_info.section_number} of {(data_info && ('course_name' in data_info)) && data_info.course_name} ({(data_info && ('course_code' in data_info)) && data_info.course_code})?</p>
						<p className='red-text'>This will <strong>permanently</strong> remove them from the database.</p>
					</div>
				</div>
			)
		}
	
		delete_data_bool = await swal(options);
		
		if(delete_data_bool)
		{
			// Update DATA
			const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, section_id:data_id};
			
			deleteSection(data).then(response_data => {
				const deleted_sections = response_data.deleted_sections;
				
				// Delete all deleted sections, and remove student matches
				deleted_sections.forEach(section_id => {
					const database_section_index = databaseData.findIndex(section => section.section_id === section_id);
					const filtered_results_index = filteredResults.findIndex(section => section.section_id === section_id);
					const section_index = sections.findIndex(section => section.section_id === section_id)
					
					if(database_section_index !== -1) databaseData.splice(database_section_index, 1);
					if(filtered_results_index !== -1) filteredResults.splice(filtered_results_index, 1);
					if(section_index !== -1) sections.splice(section_index, 1);
				});

				const new_database_data = removeSpansAndAddExtraData(databaseData);
				const new_filtered_results_data = removeSpansAndAddExtraData(filteredResults);

				setDataSorted(false);
				setDatabaseData([...new_database_data]);
				setFilteredResults([...new_filtered_results_data]);
				setSections([...sections]);

				const conflicts_to_check = ['teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_missing', 'teacher_period_restriction', 'teacher_too_many_sections', 'teacher_too_many_sections_in_a_row', 'student_student_restriction', 'teacher_student_restriction', 'course_period_restriction', 'course_exceeds_capacity', 'course_outside_normal_room', 'classroom_missing', 'inclusion_too_many_students', 'inclusion_over_half'];
				calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:deleted_sections, conflicts_to_check:conflicts_to_check, update_all:false});
				
				recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id});
			});
		}
	}
	
	useEffect(() => {
		if(!showEditDataModal) setCurrentDataID(null);

		const search_value = document.getElementsByClassName("school-data-search-bar")[0].value;
		if(search_value.length > 0) handleChange();
	}, [showEditDataModal, handleChange]);

	useEffect(() => {
		handleChange();
	}, [filteredResults, handleChange]);

	useEffect(() => {
		const new_database_data = removeSpansAndAddExtraData(databaseData);

		if(!handleChangeComplete) setFilteredResults([...new_database_data]);
	}, [databaseData, courses, dataIsLoading, handleChangeComplete, removeSpansAndAddExtraData]);

	useEffect(() => {
		if(dataSorted || displayResults.length <= 0) return;

		const sortData = (sort_type = null) => {
			let sorted_data = sortArrayOfObjects(displayResults, sortedBy, sort_type, sortAscDesc);

			setDataSorted(true);
			setDisplayResults([...sorted_data]);
		}

		sortData();
	}, [displayResults, dataSorted, sortedBy, sortAscDesc]);
	
	const row_style = {gridTemplateColumns:' 45px 120px 1fr 100px 200px 100px 100px 100px 70px'};

	//console.log(displayResults);
	//console.log({databaseData});
	
	return (
		<>
		{showEditDataModal &&
			<EditSection schoolInfo={schoolInfo} currentView={null} currentDay={'1'} currentViewType={null} scheduleType={scheduleInfo.schedule_type} sectionType='1' sectionID={currentDataID} dataID={null} toggleEditSection={toggleEditData} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} lunchInfo={lunchInfo} classrooms={classrooms} courses={courses} setCourses={setCourses} teachers={teachers} setTeachers={setTeachers} students={students} setStudents={setStudents} sections={sections} setSections={setSections} subsections={subsections} labels={labels} setLabels={setLabels} conflicts={[]} setConflicts={()=>{}} setConflictsLoading={()=>{}} setMatchPercent={()=>{}} setMatchPercentLoading={()=>{}} toggleEditStudentSchedule={()=>{}} allow_hotlinks={false} filteredResults={filteredResults} setFilteredResults={setFilteredResults}/>
		}
		<div className='school-data-content-screen'>
			<h1 className='school-data-main-heading capitalize'>Sections</h1>
			<div className='school-data-search-bar-container'>
				<input className='school-data-search-bar' placeholder="Search for a section by course code, course name, or teacher" onChange={handleChange} onKeyDown={(e) => handleIsSearching(e)} />
				<FontAwesomeIcon className='school-data-search-bar-icon' icon={faSearch}/>
			</div>
			<div className='school-data-database-display'>
				<div className='school-data-database-row school-data-database-display-header fixed-heading-on-scroll' style={row_style}>
					<div></div>
					<div className='school-data-database-header-col'>Course Code <SortToggle handleOnClick={handleSortClick} sortKey='course_code' passedData={displayResults}/></div>
					<div className='school-data-database-header-col'>Course <SortToggle handleOnClick={handleSortClick} sortKey='course_name' passedData={displayResults}/></div>
					<div className='text-align-center'>Section #</div>
					<div className='school-data-database-header-col'>Department(s) <ListFilter handleOnClick={handleFilterClick} passedData={databaseData} passedDataFilterKey='departments' filterOptions={departments} filterKey='department_id' filterDisplayKey ='department'/></div>
					<div>Period(s)</div>
					<div>Classroom</div>
					<div className='text-align-center'># Students</div>
					<div className='school-data-database-add-new-container' onClick={() => toggleEditData(null)}>
						<div className='school-data-database-add-new-btn'>
							<FontAwesomeIcon className='white-text' icon={faPlus}/>
						</div>
					</div>
				</div>
				{(!handleChangeComplete && isLoading) ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
					</div>
				): isSearching ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/searching.gif')} alt='searching...' style={{height:'80px'}}/>
					</div>
				):
				(
					<>
					{displayResults.length === 0 &&
						<div className='school-data-database-message-container'>
							<h4>Sorry, no results were found!</h4>
						</div>
					}
					{Object.keys(scheduleStructureData).length > 0 &&
						<>
						{displayResults.map(data_info => {
							const section_id = data_info.section_id;
							let course_periods = data_info.course_periods;
							const teacher_id = data_info.teacher_id;
							const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
							const classroom_id = data_info.classroom_id;
							const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
							const secondary_teachers = data_info.secondary_teachers;
							const data_departments = ('departments' in data_info) ? data_info.departments : [];
							const span_id = data_info.span_id;
							const is_inclusion = data_info.is_inclusion;
							const is_locked = data_info.locked;
							const is_subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_id);
							const is_subsection = is_subsection_index !== -1;
							const text_color = (is_inclusion === '1') ? 'orange-text' : (is_subsection ? 'blue-text' : '');

							// Collect course periods of any spans
							if(span_id)
							{
								const spanned_sections = sections.filter(section => section.span_id === span_id && section.section_id !== section_id);
								for(const spanned_section of spanned_sections)
								{
									const spanned_section_course_periods = spanned_section.course_periods;

									for(const spanned_section_course_period_id of spanned_section_course_periods)
									{
										if(!course_periods.includes(spanned_section_course_period_id)) course_periods.push(spanned_section_course_period_id);
									}
								}
							}
							course_periods.sort();

							// Get main section id (could be different if this is subsection)
							// This will be used to toggle locking
							let main_section_id = section_id;
							if(is_subsection)
							{
								const subsection_info = subsections[is_subsection_index];
								main_section_id = subsection_info.section_id;
							}

							return (
								<div className='school-data-database-row' style={row_style} key={section_id} data-sectionid={section_id}>
									{is_locked === '1' ?
										(
											<FontAwesomeIcon className='cursor-pointer bright-yellow-text' icon={faLock} onClick={() => toggleLockSection(main_section_id, '0')}/> 
										):
										(
											<FontAwesomeIcon className='cursor-pointer gray-text' icon={faUnlock} onClick={() => toggleLockSection(main_section_id, '1')}/>
										)
									}
									<div>
										<div>{data_info.course_code}</div>
									</div>
									<div>
										<div className='small-text'>
											{(is_inclusion === '1' && is_subsection) ?
												(
													<span className='orange-text'>Inclusion Subsection</span>
												):(is_inclusion === '1') ?
												(
													<span className='orange-text'>Inclusion</span>
												):(is_subsection) &&
												(
													<span className='blue-text'>Subsection</span>
												)
											}
										</div>
										<div className={text_color}>{data_info.course_name}</div>
										{teacher_info &&
											<div className={`medium-text ${text_color}`}>{teacher_info.name}, {teacher_info.first_name}</div>
										}
										{secondary_teachers.map((secondary_teacher_id, i) => {
											const secondary_teacher_info = teachers.find(teacher => teacher.teacher_id === secondary_teacher_id);
											const secondary_teacher_full_name = (teacher_info) ? `${secondary_teacher_info.name}, ${secondary_teacher_info.first_name}` : null;

											return (
												<div className={`medium-text ${text_color}`} key={i}>{secondary_teacher_full_name}</div>
											)
										})}
									</div>
									<div className='text-align-center'>{data_info.section_number}</div>
									<div>
										{data_departments.length === 0 ?
											(
												<div className='school-data-department medium-text'>No department</div>
											):
											(
												<>
												{data_departments.map((department_id, index) => {
													const department_index = departments.findIndex(department => department.department_id === department_id);
													const department = (department_index !== -1) ? departments[department_index].department : 'No department';
													
													return (
														<div className='school-data-department medium-text' key={index}>{department}</div>
													)
												})}
												</>
											)
										}
									</div>
									<div className='medium-text'>
										{course_periods.length === 0 ?
											(
												<>--</>
											):
											(
												<>
												{course_periods.map(course_period_id => {
													if(!course_period_id) return null;
													
													const period_id = (scheduleInfo.schedule_type === 'daily') ? scheduleStructureData.course_periods[course_period_id].days['1'] : course_period_id;
													const period_name = (scheduleInfo.schedule_type === 'daily') ? scheduleStructureData.schedule_periods[period_id].period_name : scheduleStructureData.course_periods[period_id].period_name;

													return (
														<div key={`${section_id}-${course_period_id}`}>{period_name}</div>
													)
												})}
												</>
											)
										}
									</div>
									<div className={`medium-text`}>
										{classroom_info ?
										(
											<React.Fragment>{classroom_info.classroom_name}</React.Fragment>
										):
										(
											<React.Fragment>--</React.Fragment>
										)}
									</div>
									<div className='text-align-center'>{data_info.student_list.length}</div>
									{is_locked !== '1' &&
										<div className='school-data-database-row-btn-container'>
											<FontAwesomeIcon className='gray-to-dark-blue-link' icon={faEdit} onClick={() => toggleEditData(section_id)}/>
											<FontAwesomeIcon className='gray-to-red-link' icon={faTrashAlt} onClick={() => handeDeleteData(section_id)}/>
										</div>
									}
								</div>
							)
						})}
						</>
					}
					</>
				)}
			</div>
		</div>
		</>
	);
}