import React, { useEffect, useState , useRef } from 'react';
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import Fuse from 'fuse.js';

import DepartmentProgressScreen from '../department_progress_screen';
import EditCourseData from '../../modals/edit_course';
import Button from '../../../Button';

import { isEmpty, capitalizeFirstLetters, useOutsideClick, getData, calculateConflicts } from '../../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUserCircle, faSquare, faCheckSquare, faHourglassHalf, faCheckCircle, faCheck, faExclamationCircle, faTimes, faSearch, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default function CleanCourseAvailability({isLoading, subscreenRestricted, subscreenLocked, subscreen, subscreenResponsibleRoleID, supervisors, openSupervisorsList, closeSupervisorsList, addSupervisorToDepartment, removeSupervisorFromDepartment, users, userIsSupervisor, userIsAdmin, departments, setDepartments, departmentID, sortedDepartments, changeDepartment, userInfo, schoolInfo, schoolType, scheduleInfo, coursesLoading, courses, setCourses, teachers, classrooms, students, setStudents, sections, labels, scheduleStructureData, scheduleType, periods, addNewCourseOpen, setAddNewCourseOpen, subscreenSelect, submitDepartment, reviewing, toggleReviewDepartment, changeDepartmentResource, setRolePages, showNextButton, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo}) {
	const ref = useRef();
	
	const [departmentCourses, setDepartmentCourses] = useState([]);
	const [selectedPeriods, setSelectedPeriods] = useState([]);
	const [addCourseOpen, setAddCourseOpen] = useState(false);
	const [courseResults, setCourseResults] = useState([...courses]);
	const [changesSavedMessage, setChangesSavedMessage] = useState(false);
	
	const addSearch = (e) => {
		const search_value = e.target.value;
		const fuse = new Fuse(courses, {
			keys: ['name', 'course_code'],
			threshold: .6
		})
		
		const results = fuse.search(search_value);
		const course_results = results.map(result => result.item).splice(0,5);
		
		setCourseResults(course_results);
	}

	const toggleSelectAllColumn = (period_id) => {
		const course_period_id = (scheduleInfo.schedule_type === 'daily') ? scheduleStructureData.schedule_structure['1'].find(schedule_period => schedule_period.schedule_period_id === period_id).course_period_id : period_id;
		const period_selected_index = selectedPeriods.findIndex(period => period === period_id);
		let delete_restriction;

		if(period_selected_index === -1)
		{
			selectedPeriods.push(period_id);
			delete_restriction = '0';
		}
		else
		{
			selectedPeriods.splice(period_selected_index, 1);
			delete_restriction = '1';
		}

		setSelectedPeriods([...selectedPeriods]);
		
		let course_ids = [];

		departmentCourses.forEach(course_info => {
			course_ids.push(course_info.course_id);
		});
		
		updateCourseAvailability(course_ids, delete_restriction, [course_period_id]);
	}

	const toggleSelectAllRow = (course_id) => {
		const course_info = courses.find(course => course.course_id === course_id);
		const period_restrictions = course_info.period_restrictions;
		const delete_restriction = (period_restrictions.length === 0) ? '0' : '1';

		const course_period_ids = periods.map(period => {
			const period_id = period.period_id;
			const course_period_id = (scheduleInfo.schedule_type === 'daily') ? scheduleStructureData.schedule_structure['1'].find(schedule_period => schedule_period.schedule_period_id === period_id).course_period_id : period_id;
			return course_period_id;
		});
		
		if(delete_restriction === '1') setSelectedPeriods([]);

		updateCourseAvailability([course_id], delete_restriction, course_period_ids);
	}
	
	useOutsideClick(ref, () => {
		closeSupervisorsList();
	});
	
	////////////////////////////
	///// UPDATE FUNCTIONS /////
	////////////////////////////
	const updateCourseAvailability = async (course_ids, delete_restriction, course_period_ids) => {
		///////////////////////
		///// UPDATE JSON /////
		///////////////////////
		const department_index = departments.findIndex(department => department.department_id === departmentID);

		if(userIsSupervisor) 
		{
			if(department_index !== -1)
			{
				departments[department_index].subscreens[subscreen].submitted = false;
				departments[department_index].subscreens[subscreen].confirmed = false;
			}

			getData('schoolProgress', '/get-school-progress', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
				const role_pages = user_progress_data.role_pages;
				
				setRolePages(role_pages);
			});
		}

		if(userIsAdmin && departments[department_index].subscreens[subscreen].confirmed) setChangesSavedMessage(true);
		
		course_ids.forEach(course_id => {
			const course_index = courses.findIndex(course => course.course_id === course_id);
			const course_info = courses[course_index];
			
			const period_restrictions = course_info.period_restrictions;
			
			if(delete_restriction === '1')
			{
				const new_period_restrictions = period_restrictions.filter(period => !course_period_ids.includes(period));
				course_info.period_restrictions = new_period_restrictions;
			}
			else if(delete_restriction === '0')
			{
				course_period_ids.forEach(course_period_id => {
					// Check if course already has period restriction
					// If no, add period
					const period_index = period_restrictions.findIndex(period => period === course_period_id);
					if(period_index === -1) period_restrictions.push(course_period_id);
				});
			}
		});
		
		setCourses([...courses]);
		setDepartments([...departments]);

		//////////////////////////
		///// UPDATE BACKEND /////
		//////////////////////////
		const data = {school_id: schoolInfo.school_id, department_id:departmentID, course_ids:course_ids, delete_restriction:delete_restriction, course_period_ids:course_period_ids, schedule_version_id:schoolInfo.current_schedule_version_id};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/clean-data/update-course-availability';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			API.post(apiName, url, myInit);

			////////////////////////
			/// Update conflicts ///
			////////////////////////
			const affected_sections = sections.reduce((results, section) => {
				const section_course_id = section.course_id;
				if(section_course_id && course_ids.includes(section_course_id)) results.push(section.section_id);
				return results;
			},[]);
			const conflicts_to_check = ['course_period_restriction'];

			if(affected_sections.length > 0) calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:affected_sections, conflicts_to_check:conflicts_to_check, update_all:false});
		} catch(e)
		{
			console.log(e.response);
		}
	}

	const updateCourse = (changeType, dataType, data_id, old_department_id, new_department_id) => {
		changeDepartmentResource(changeType, dataType, data_id, old_department_id, new_department_id, () => {
			if(changeType == 'add' && userIsSupervisor) 
			{
				const department_index = departments.findIndex(department => department.department_id === departmentID);

				if(department_index !== -1)
				{
					departments[department_index].subscreens[subscreen].submitted = false;
					departments[department_index].subscreens[subscreen].confirmed = false;
				}
				
				// Update cleaning screen progress (for check marks)
				departments[department_index].subscreens['course_availability'].submitted = false;
				departments[department_index].subscreens['course_availability'].confirmed = false;

				departments[department_index].subscreens['course_caps'].submitted = false;
				departments[department_index].subscreens['course_caps'].confirmed = false;

				departments[department_index].subscreens['course_options'].submitted = false;
				departments[department_index].subscreens['course_options'].confirmed = false;

				departments[department_index].subscreens['course_other_options'].submitted = false;
				departments[department_index].subscreens['course_other_options'].confirmed = false;

				departments[department_index].subscreens['course_teachers'].submitted = false;
				departments[department_index].subscreens['course_teachers'].confirmed = false;

				departments[department_index].subscreens['course_classrooms'].submitted = false;
				departments[department_index].subscreens['course_classrooms'].confirmed = false;

				setDepartments([...departments]);

				// Reset overall progress to update the Progress screen
				getData('schoolProgress', '/get-school-progress', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
					const role_pages = user_progress_data.role_pages;
					
					setRolePages(role_pages);
				});
			}
		});
	}

	//////////////////////////////
	//// USE EFFECT FUNCTIONS ////
	//////////////////////////////
	useEffect(() => {
		if(!departmentID || !userInfo || !users || !departments) return;

		const department_index = departments.findIndex(department => department.department_id === departmentID);
		const department_info = (department_index !== -1) ? departments[department_index] : null;
		const department_supervisors = (department_info) ? department_info.supervisors : [];

		const available_users = users.filter(user => (user.role_id === '1' || user.role_id === '2') || department_supervisors.findIndex(supervisor => supervisor.user_id === user.user_id) !== -1);
		
		setScreenAllowsUniversalSidebar(true);
		setUniversalSidebarLoadingInfo({sidebar_name:"Course Availability Notes", sidebar_type:'notes', subscreen:'course_availability', department_id:departmentID, user_id:userInfo.user_id, available_users:available_users, help_link_path:'/app/hsms/documentation/clean_data/clean_department_data'});
	}, [departmentID, userInfo, users, departments, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo]);

	useEffect(() => {
		if(!coursesLoading)
		{
			const department_courses = courses.filter(course => course.departments.includes(departmentID));
			setDepartmentCourses([...department_courses]);
		}
	}, [departmentID, coursesLoading, courses]);

	useEffect(() => {
		if(departmentCourses.length > 0 && selectedPeriods.length === 0)
		{
			let selected_periods = [];
			periods.forEach(period_info => {
				const period_id = period_info.period_id;
				const course_period_id = (scheduleInfo.schedule_type === 'daily') ? scheduleStructureData.schedule_structure['1'].find(schedule_period => schedule_period.schedule_period_id === period_id).course_period_id : period_id;
	
				const period_restricted = courses.findIndex(course => departmentCourses.findIndex(department_course => department_course.course_id === course.course_id) !== -1 && course.period_restrictions.includes(course_period_id)) !== -1;

				if(period_restricted && !selected_periods.includes(period_id)) selected_periods.push(period_id);
			});

			setSelectedPeriods(selected_periods);
		}
	}, [departmentCourses, courses, periods, scheduleInfo.schedule_type, scheduleStructureData.schedule_structure, setSelectedPeriods]);
	
	const num_periods = (!isEmpty(periods)) ? Object.keys(periods).length : 0;
	const row_style = {gridTemplateColumns:`200px repeat(${num_periods}, 1fr) 40px`};
	
	const department_index = departments.findIndex(department => department.department_id === departmentID);
	const department_info = departments[department_index];
	const subscreen_info = (department_info) ? department_info.subscreens[subscreen] : null;
	
	const current_screen_submitted = (subscreen_info) ? subscreen_info.submitted : false;
	const current_screen_confirmed = (subscreen_info) ? subscreen_info.confirmed : false;
	const current_screen_unlocked= (subscreen_info) ? subscreen_info.unlocked : false;
	
	const show_current_screen = (current_screen_unlocked && (reviewing || userIsSupervisor  || userIsAdmin));
	const screen_complete_for_user = ((current_screen_confirmed) || (userIsSupervisor && !userIsAdmin && current_screen_submitted));
	
	return (
		<>
		<h1 className='progress-main-header'>Course Availability</h1>
		<p className='progress-subheader'>Set any constraints on what periods courses can run.</p>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(!departmentID) ? 
		(
			<DepartmentProgressScreen subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} subscreenSelect={subscreenSelect} userInfo={userInfo} userIsAdmin={userIsAdmin} userIsSupervisor={userIsSupervisor} />
		):(!userIsSupervisor) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to change this department</div>	
			</div>
		):(departmentID) ?
		(
			<>
			<div id='clean-data-content'>
				<div>
					<FontAwesomeIcon icon={faChevronLeft} className='progress-back-to-departments-btn' onClick={()=>changeDepartment(null)}/>
				</div>
				<div className='clean-data-content-inner-content'>
					<div className='clean-data-submit-btn-container'>
						{!isLoading &&
							<>
							{(reviewing && screen_complete_for_user) &&
								<>
								{changesSavedMessage && 
									<div className='all-changes-saved-message' style={{margin:'15px 0px 0px 0px'}}>
										<FontAwesomeIcon className='green-text' icon={faCheckCircle}/>
										<div>
											<h5>Changes Saved</h5>
											<div className='small-text'>All your changes have been saved</div>
										</div>
										<FontAwesomeIcon className='x-remove' icon={faTimes} onClick={() => setChangesSavedMessage(false)}/>
									</div>
								}
								</>
							}
							{(!screen_complete_for_user && show_current_screen) &&
								<Button key={departmentID} classes={['mark-as-complete-btn']} selected={false} text={`${!current_screen_submitted ? 'Mark As Complete' : 'Confirm Department'}`} icon={faCheck} iconPosition='left' onClickFunction={() => submitDepartment(departmentID)} />
							}
							</>
						}
					</div>
					<div className='clean-data-content-header-container'>
						<h1 className='clean-data-content-header'>{department_info && department_info.department} Department</h1>
						{current_screen_confirmed ?
							(
								<div className='clean-data-status green-text'><div>Complete</div><FontAwesomeIcon icon={faCheckCircle}/></div>
							): (current_screen_submitted) ?
							(
								<div className='clean-data-status bright-yellow-text'><div>Pending Confirmation</div><FontAwesomeIcon icon={faCheckCircle}/></div>
							): (null)
						}
					</div>
					<div className='department-supervisor-parent-container'>
						<>
						{department_info && department_info.supervisors.length === 0 &&
							<>
							<div className='department-supervisor-inner-container'>
								<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
								<div className='department-supervisor-name'>No Supervisor Assigned</div>
							</div>
							</>
						}
						{department_info && department_info.supervisors.map((supervisor, index) => {
							return (
								<div className='department-supervisor-outer-container' key={index}>
									<div className='department-supervisor-inner-container'>
										{supervisor.img_url &&
											<img className='department-supervisor-img' src={require(`../../../../images/users/${supervisor.img_url}`)} alt='user' />
										}
										{!supervisor.img_url &&
											<FontAwesomeIcon className='department-supervisor-user-icon gray-text' icon={faUserCircle}/>
										}
										<div className='department-supervisor-name'>{supervisor.last_name}, {supervisor.first_name}</div>
										{userIsAdmin &&
											<FontAwesomeIcon className='gray-text-hover' icon={faTimes} onClick={() => removeSupervisorFromDepartment(supervisor.user_id)}/>
										}
									</div>
								</div>
							)
						})}
						{userIsAdmin &&
							<>
							<div className={`clean-data-add-supervisor-link small-text click-restricted ${(department_info && department_info.supervisors.length === 0) ? 'blue-link' : 'gray-to-blue-link'}`} onClick={() => openSupervisorsList(departmentID)}>Add a Supervisor</div>
							<div className='supervisor-options-container'>
								{(department_info && department_info.supervisors_open) &&
									<div className='data-options-container' ref={ref}>
										{supervisors.map((supervisor_info, index) => {
											
											return (
												<div className='data-options-option assign-resourses-supervisor-option' key={index} onClick={() => addSupervisorToDepartment(supervisor_info)}>
													{supervisor_info.img_url &&
														<img className='department-supervisor-img' src={require(`../../../../images/users/${supervisor_info.img_url}`)} alt='supervisor' />
													}
													{!supervisor_info.img_url &&
														<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
													}
													<div className='department-supervisor-name'>{supervisor_info.last_name}, {supervisor_info.first_name}</div>
												</div>
											)	
										})}
										<Link className='data-options-option blue-text' to={{pathname:`/app/${schoolType}/settings/users`, redirect:`/app/${schoolType}/progress/clean_data/course_availability`}} style={{borderTop:'1px solid #efefef'}} onClick={() => closeSupervisorsList(departmentID)}>Add new Supervisor</Link>
									</div>
								}
							</div>
							</>
						}
						</>
					</div>
					<div className='clean-data-container'>
						{isLoading ?
							(
								<div className="center-text">
									<img className='clean-data-loader' src={require(`../../../../images/balls.gif`)} alt='loading' />
								</div>
							):
							(
								<>
								{(!current_screen_unlocked) ?
									(
										<div className='general-screen-message'>
											<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
											<h1>Locked</h1>
											<div>This department has previous steps which have not been completed. Please review previous steps and make sure they are completed and saved. This page should open automatically once all previous steps have been finished.</div>
										</div>
									):(userIsAdmin && current_screen_unlocked && !show_current_screen && !current_screen_submitted && !userIsSupervisor) ?
									(
										<div className='general-screen-message'>
											<FontAwesomeIcon className='turquoise-text' style={{fontSize:'60px'}} icon={faHourglassHalf}/>
											<h1>Pending...</h1>
											<div>Waiting on confirmation from department supervisor</div>
											<p className='blue-link' onClick={toggleReviewDepartment}>Review course availability yourself</p>	
										</div>
									): (show_current_screen) ?
									(
										<>
										{departmentCourses.length === 0 ?
											(
												<div className='clean-data-no-data-container'>
													<FontAwesomeIcon className='clean-data-no-data-icon dark-blue-text' icon={faExclamationCircle}/>
													<div>No courses found</div>
													<p>Please confirm that this is correct by submitting the screen</p>
												</div>
											):
											(
												<>
												<div className='fixed-heading-on-scroll'>
													<div className='clean-data-header-row' style={{gridTemplateColumns:'200px 1fr 40px', margin:'0px 0px 5px 0px'}}>
														<div></div>
														<div className='dark-blue' style={{textAlign:'center', padding:'5px', borderRadius:'5px'}}>Available Periods</div>
														<div></div>
													</div>
													<div className='clean-data-header-row' style={row_style}>
														<div className='clean-data-col'></div>
														{periods.map(period_info => {
															const period_id = period_info.period_id;
															
															return (
																<div className="clean-data-col clean-data-period" key={`header-${period_id}`}>
																	<div className="clean-data-heading extra-small-text">{period_info.period_name}</div>
																</div>
															)
														})}
													</div>
												</div>
												<div className='clean-data-select-row' style={row_style}>
													<div className='clean-data-col'></div>
													{Object.keys(periods).map(index => {
														const period_info = periods[index];
														const period_id = period_info.period_id;
														const select_state = selectedPeriods.includes(period_id) ? 'Select' : 'Unselect';
														
														if(!period_info) return null;
														
														return (
															<div className="clean-data-col extra-small-text select-all text-align-center" key={index} onClick={(e) => toggleSelectAllColumn(period_id)}><span className='capitalize'>{select_state}</span> All</div>
														)
													})}
												</div>
												{departmentCourses.map((course_info, i) => {
													const course_id = course_info.course_id;
													const period_restrictions = course_info.period_restrictions;
													
													return (
														<div className='clean-data-row' style={row_style} key={i}>
															<div>
																<div>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
																{period_restrictions.length > 0 ?
																	(
																		<div className='small-text approve-link' onClick={() => toggleSelectAllRow(course_id)}>Select All</div>
																	):
																	(
																		<div className='small-text cancel-link' onClick={() => toggleSelectAllRow(course_id)}>Unselect All</div>
																	)
																}
															</div>
															{periods.map(period_info => {
																const period_id = period_info.period_id;
																const course_period_id = (scheduleType === 'daily') ?  scheduleStructureData.schedule_structure[1].find(schedule_period => schedule_period.schedule_period_id === period_id).course_period_id : period_id;
																
																const period_restricted = period_restrictions.includes(course_period_id);
																
																return (
																	<div key={`course-period-${period_id}`} className='period-availability-checkmark'>
																		{period_restricted ? 
																			(
																				<FontAwesomeIcon className='fas-checkbox-unchecked' icon={faSquare} onClick={() => updateCourseAvailability([course_id], '1', [course_period_id])}/>
																			) : 
																			(
																				<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateCourseAvailability([course_id], '0', [course_period_id])}/>
																			)
																		}
																	</div>
																)
															})}
															<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => updateCourse('remove', 'course', course_id, departmentID, null)} style={{justifySelf:'center'}}/>
														</div>
													)
												})}
												</>
											)
										}
										<div className='clean-data-add-new-data blue-link assign-resources-supervisor-confirm-add-new' onClick={() => setAddCourseOpen(!addCourseOpen)}>Add A Course</div>
										{addCourseOpen &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='teacher-search-input' className='search-add-adding-input' onChange={(e) => addSearch(e)} placeholder='Search for course'/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => setAddCourseOpen(!addCourseOpen)}/>
												</div>
												{courseResults.length !== 0 &&
													<div className='search-add-search-results'>
														<div className='search-add-did-you-mean'>Did you mean:</div>
														{courseResults.map((course, i) => {
															return (
																<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr auto'}}>
																	<div>{capitalizeFirstLetters(course.name)} ({course.course_code})</div>
																	<div>
																		<div className='search-add-add-btn' onClick={() => updateCourse('add', 'course', course.course_id, null, departmentID)}>Add</div>
																	</div>
																</div>
															)
														})}
														<div className='clean-data-add-new-data-text dark-blue-text'>If you don&rsquo;t see the course you are looking for, you should first add them <span className='blue-link' onClick={() => setAddNewCourseOpen(true)}>Add A New Course</span></div>
													</div>
												}
											</div>
										}
										{addNewCourseOpen &&
											<EditCourseData schoolInfo={schoolInfo} dataID={null} toggleEditData={() => setAddNewCourseOpen(false)} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} courses={courses} students={students} classrooms={classrooms} teachers={teachers} departments={departments} setDatabaseData={setCourses} setStudents={setStudents} sections={sections} labels={labels} defaultDepartmentID={departmentID}/>
										}
										</>
									):null
								}
								</>
							)
						}
					</div>
				</div>
			</div>
			</>
		) :null}	
		{showNextButton &&
			<div className='fixed-bottom-save-container'>
				<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Go to Next Step: Course Caps' onClickFunction={handleMoveToNextScreen} />
			</div>
		}
		</>
	);
}