import React, { useState , useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import Dropdown from '../../Dropdown';
import Button from '../../Button';

import { sortArrayOfObjects, createUniqueObjectID, capitalizeFirstLetters, useOutsideClick } from '../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faQuestionCircle, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

export default function CreateLabels({ isLoading, setIsLoading, subscreenRestricted, subscreenLocked, schoolInfo, scheduleInfo, departments, userInfo, coursesLoading, courses, teachersLoading, teachers, sectionsLoading, sections, setSections, subsections, labels, setLabels, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo }) {
	const ref = useRef();
	
	const [selectedDepartmentID, setSelectedDepartmentID] = useState(null);

	const [showTeamDescription, setShowTeamDescription] = useState(false);
	const [showLabelDescription, setShowLabelDescription] = useState(false);
	
	const [showAddNewSection, setShowAddNewSection] = useState(false);
	const [sectionSearchResults, setSectionSearchResults] = useState([]);
	
	const addLabel = (is_team) => {
		const newID = createUniqueObjectID(5);
		is_team = (is_team) ? '1' : '0';
		
		labels.push({new:true, label_id:newID, label:null, is_team:is_team});
		setLabels([...labels]);
	}
	
	const updateLabel = async (e, label_id) => {
		const label_index = labels.findIndex(label => label.label_id === label_id);
		const label_info = labels[label_index];
		
		const label_input = e.target.closest('.create-labels-editing-label').querySelector('.create-labels-input');
		const label = label_input.value;
		
		if(!label)
		{
			label_input.classList.add('error');
			return;
		}
		
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, update_type:'add', label_id:label_id, label:label, is_team:label_info.is_team};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/update-label';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			const response = await API.post(apiName, url, myInit);
			const new_label_id = response.data.label_id;
			
			labels[label_index]['label_id'] = new_label_id;
			labels[label_index]['label'] = label;
			
			delete labels[label_index]['new'];
			
			setLabels([...labels]);
		} catch(e)
		{
			console.log(e.response);
		}
	}
	
	const removeLabel = async (label_id) => {
		const label_index = labels.findIndex(label => label.label_id === label_id);
		const label_info = labels[label_index];
		const is_new = ('new' in label_info) ? true : false;
		
		if(is_new)
		{
			labels.splice(label_index, 1);
			setLabels([...labels]);
		}
		else 
		{
			const label_type = (label_info.is_team === '1') ? 'team' : 'label';
			
			const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, update_type:'remove', label_id:label_id, label:label_info.label, is_team:label_info.is_team};
			
			const options =  {
				title: "Are you sure?",
				text: `Do you really want to delete the "${label_info.label}" ${label_type}? This will remove this ${label_type} from all sections and students`,
				icon: "warning",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'red-btn'
					},
				}
			}
			
			const delete_label = await swal(options);
			
			if(delete_label)
			{
				const apiName = process.env.REACT_APP_ENDPOINT_NAME;
				const url = '/admin/update-label';
			    const myInit = { // OPTIONAL
			        response: true,
			        body: JSON.stringify(data),
			    };
			   
				try {
					await API.post(apiName, url, myInit);
					
					//Find all sections that have this label and remove it
					const sections_with_this_label = sections.filter(section => section.labels.includes(label_id));
					
					sections_with_this_label.forEach(section_with_label_info => {
						const section_id = section_with_label_info.section_id;
						const section_index = sections.findIndex(section => section.section_id === section_id);
						const section_info = sections[section_index];
						
						const section_labels = section_info.labels;
						const label_index = section_labels.findIndex(label => label === label_id);
						
						section_info.labels.splice(label_index, 1);
					});
					
					setSections([...sections]);
					
					// Remove label from JSON
					labels.splice(label_index, 1);
					setLabels([...labels]);
				} catch(e)
				{
					console.log(e.response);
					return;
				}
			}	
		}
	}
	
	const fuzzySearch = (e) => {
		const search_value = e.target.value;
		
		//////////////////////////////
		///// GET COURSE RESULTS /////
		//////////////////////////////
		const fuse = new Fuse(courses, {
			keys: ['course_code', 'name'],
			threshold: .6
		});
		
		const results = fuse.search(search_value);
		const course_results = results.map(result => result.item);
		
		let section_results = [];
		course_results.forEach(course_info => {
			const course_id = course_info.course_id;
			
			let logged_span_ids = [];
			const sections_with_course_id = sections.reduce((result, section) => {
				const section_type = section.section_type;
				if(section_type !== '1') return result;

				const section_course_id = section.course_id;
				const is_subsection = (subsections.findIndex(subsection => subsection.subsection_id === section.section_id) !== -1) ? true : false;
				const span_id = section.span_id;
				const included_in_department = (!selectedDepartmentID || (selectedDepartmentID && section.departments.includes(selectedDepartmentID))) ? true : false;

				if(course_id === section_course_id && included_in_department && !is_subsection && !logged_span_ids.includes(span_id)) result.push(section);
				if(span_id) logged_span_ids.push(span_id);

				return result;
			}, []);
			
			section_results = [...section_results, ...sections_with_course_id];
		});

		const reduced_results = section_results.slice(0, 30);
		
		setSectionSearchResults([...reduced_results]);
		setSections([...sections]);
	}
	
	const toggleAddNewSection = () => {
		setSectionSearchResults([...sections]);
		setShowAddNewSection(!showAddNewSection);
	}
	
	const selectSection= (section_id) => {
		const section_index = sections.findIndex(section => section.section_id === section_id);
		
		// Add new subscreen to course section subsections
		sections[section_index]['has_label'] = true;
		
		setSections([...sections]);
		//toggleAddNewSection();
	}
	
	const addSectionLabel = async (section_id, label_id) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, update_type:'add', section_id:section_id, label_id:label_id};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/update-section-label';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			await API.post(apiName, url, myInit);
			
			const section_index = sections.findIndex(section => section.section_id === section_id);
			const section_info = sections[section_index];		
			const section_span_id = section_info.span_id;
			const section_labels = section_info.labels;

			const label_index = section_labels.findIndex(label => label === label_id);
			if(label_index === -1) section_labels.push(label_id);
			section_info['label_options_open'] = false;
			if('has_label' in section_info) delete section_info['has_label'];

			if(section_span_id)
			{
				const lab_sections = sections.filter(section => section.span_id === section_span_id && section.section_id !== section_id);

				for(const lab_section of lab_sections)
				{
					const lab_section_id = lab_section.section_id;
					const lab_section_index = sections.findIndex(section => section.section_id === lab_section_id);
					const lab_section_info = (lab_section_index !== -1)  ? sections[lab_section_index] : null;
					const lab_section_labels = (lab_section_info) ? lab_section_info.labels : null;
		
					const lab_label_index = (lab_section_labels) ? lab_section_labels.findIndex(label => label === label_id) : null;
					if(lab_section_index !== -1 && lab_label_index === -1) sections[lab_section_index].labels.push(label_id);
				}
			}
			
			setSections([...sections]);
		} catch(e)
		{
			console.log(e.response);
		}
	}
	
	const removeSectionLabel = async (section_id, label_id) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, update_type:'remove', section_id:section_id, label_id:label_id};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/update-section-label';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			await API.post(apiName, url, myInit);
			
			const section_index = sections.findIndex(section => section.section_id === section_id);
			const section_info = sections[section_index];		
			const section_span_id = section_info.span_id;
			const section_labels = section_info.labels;

			const label_index = section_labels.findIndex(label => label === label_id);
			if(label_index !== -1) section_labels.splice(label_index, 1);
			section_info['label_options_open'] = false;
			if('has_label' in section_info) delete section_info['has_label'];

			if(section_span_id)
			{
				const lab_sections = sections.filter(section => section.span_id === section_span_id && section.section_id !== section_id);

				for(const lab_section of lab_sections)
				{
					const lab_section_id = lab_section.section_id;
					const lab_section_index = sections.findIndex(section => section.section_id === lab_section_id);
					const lab_section_info = (lab_section_index !== -1)  ? sections[lab_section_index] : null;
					const lab_section_labels = (lab_section_info) ? lab_section_info.labels : null;
		
					const lab_label_index = (lab_section_labels) ? lab_section_labels.findIndex(label => label === label_id) : null;
					if(lab_section_index !== -1 && lab_label_index !== -1) sections[lab_section_index].labels.splice(lab_label_index, 1);
				}
			}
			
			setSections([...sections]);
		} catch(e)
		{
			console.log(e.response);
		}
	}
	
	const toggleLabelOptionsOpen = (section_id) => {
		const section_index = sections.findIndex(section => section.section_id === section_id);
		const section_info = sections[section_index];
		
		section_info['label_options_open'] = !section_info['label_options_open'];
		setSections([...sections]);
	}
	
	const closeLabelOptions = (section_id) => {
		const section_index = sections.findIndex(section => section.section_id === section_id);
		const section_info = sections[section_index];
		
		section_info['label_options_open'] = false;
		setSections([...sections]);
	}
	
	useOutsideClick(ref, () => {
		const section_id = ref.current.closest('.subsection-review-section-container').getAttribute('data-sectionid');
		
		closeLabelOptions(section_id);
	});
	
	///////////////////////////
	///// ON STATE CHANGE /////
	///////////////////////////
	useEffect(() => {
		if(showAddNewSection)
		{
			var myElement = document.getElementById('subsection-review-search');
			var topPos = myElement.offsetTop;
			
			document.getElementById('main-content').scrollTop = topPos;
		}
	}, [showAddNewSection, sectionSearchResults]);
	
	useEffect(() => {
		if(!teachersLoading && !coursesLoading && !sectionsLoading) setIsLoading(false);
	}, [teachersLoading, coursesLoading, sectionsLoading, setIsLoading]);

	useEffect(() => {
		if(!userInfo || subscreenRestricted || subscreenLocked) return;

		setScreenAllowsUniversalSidebar(true);
		setUniversalSidebarLoadingInfo({sidebar_name:"Create Labels Notes", sidebar_type:'notes', subscreen:'create_labels', department_id:null, user_id:userInfo.user_id, help_link_path:'/app/hsms/documentation/section_creation/create_labels'});
	}, [userInfo, subscreenRestricted, subscreenLocked, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo]);
	
	const teams = labels.filter(label => label.is_team === '1');
	const sorted_teams = sortArrayOfObjects(teams, 'label', 'text', 'asc');
	
	const section_labels = labels.filter(label => label.is_team === '0');
	const sorted_labels = sortArrayOfObjects(section_labels, 'label', 'text', 'asc');
	const sections_with_labels = sections.filter(section => ('labels' in section) && (section.labels.length !== 0 || section.has_label));
	
	// Organize sections with labels into departments
	let departmentOptions = [{value:null, display:'All Departments'}];
	
	const department_sections_with_labels = (isLoading) ? [] : departments.reduce((result, department_info) => {
		const department_id = department_info.department_id;
		
		// Get sections (with labels) that are in this department
		const sections_with_labels_in_department = sections_with_labels.filter(section => section.departments.includes(department_id));
		
		if(sections_with_labels_in_department.length !== 0)
		{
			if(!selectedDepartmentID || selectedDepartmentID === department_id)
			{
				// Add course name to sections (for sort)
				const logged_span_ids = [];
				const sections_with_labels_in_department_with_course_names = sections_with_labels_in_department.reduce((result, section) => {
					const course_id = section.course_id;
					const course_index = courses.findIndex(course => course.course_id === course_id);
					const course_name = (course_index !== -1) ? courses[course_index].name : null;
					const span_id = section.span_id;
					
					if(logged_span_ids.includes(span_id)) return result;
					if(span_id) logged_span_ids.push(span_id);
					
					const new_section = {...section, name: course_name};
					result.push(new_section);
					
					return result;
				}, []);
				
				// Sort sections by course name
				const sorted_sections_with_labels_in_department = sortArrayOfObjects(sections_with_labels_in_department_with_course_names, 'name', 'text', 'asc');
				
				// Push new sorted sections
				result.push({department_id:department_id, sections:sorted_sections_with_labels_in_department});
			}
			
			const department = departments.find(department => department.department_id === department_id).department;
			
			// Add department as an option in dropdown
			departmentOptions.push({value:department_id, display:department});
		}
		
		return result;
	}, []);
	
	const current_user_is_admin = (userInfo.role_id === '1'|| userInfo.role_id === '2' || userInfo.role_id === '5');

	let logged_sections = [];
	let logged_search_result_sections = [];
	
	return (
		<>
		<h1 className='progress-main-header'>Create Labels</h1>
		<p className='progress-subheader'>You can create groups/teams that aren't covered by course codes.</p>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(current_user_is_admin) ?
		(
			<div id='create-labels-dashboard'>
				<div id='create-labels-create-new-label-container'>
					<div className='create-labels-label-container'>
						<div className='create-labels-label-container-header-container'>
							<h2>Teams</h2>
							<FontAwesomeIcon className='blue-link' icon={faQuestionCircle} onClick={() => setShowTeamDescription(!showTeamDescription)}/>
							<div className='blue-link full-width text-align-right' onClick={() => addLabel(true)}>Add Team</div>
						</div>
						{showTeamDescription && <p className='medium-text'>Create teams to help group students together</p>}
						{sorted_teams.map((label, index) => (
							<div key={label.label_id}>
								{label.label ?
									(
										<div className='create-labels-label'>
											<div className='create-labels-icon pastel-blue'></div>
											<div>{label.label}</div>
											<FontAwesomeIcon className='cancel-link' icon={faTimes} onClick={() => removeLabel(label.label_id)}/>
										</div>
									) :
									(
										<div className='create-labels-editing-label'>
											<div className='create-labels-icon pastel-blue'></div>
											<input className='create-labels-input' placeholder='Add label name...' onKeyPress={(e) => {if(e.key === 'Enter') updateLabel(e, label.label_id)}} autoFocus={true}/>
											<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => removeLabel(label.label_id)}/>
											<FontAwesomeIcon className='gray-to-blue-link' icon={faCheck} onClick={(e) => updateLabel(e, label.label_id)}/>
										</div>
									)
								}
							</div>
						))}
					</div>
					<div className='create-labels-label-container'>
						<div className='create-labels-label-container-header-container'>
							<h2>Labels</h2>
							<FontAwesomeIcon className='blue-link' icon={faQuestionCircle} onClick={() => setShowLabelDescription(!showLabelDescription)}/>
							<div className='blue-link full-width text-align-right' onClick={() => addLabel(false)}>Add Label</div>
						</div>
						{showLabelDescription && <p className='medium-text'>Use labels to tag sections that can only allow certain students but do not have their own unique course codes (e.g. bilingual sections)</p>}
						{sorted_labels.map((label, index) => (
							<div key={label.label_id}>
								{label.label ?
									(
										<div className='create-labels-label'>
											<div className='create-labels-icon pastel-orange'></div>
											<div>{label.label}</div>
											<FontAwesomeIcon className='cancel-link' icon={faTimes} onClick={() => removeLabel(label.label_id)}/>
										</div>
									) :
									(
										<div className='create-labels-editing-label'>
											<div className='create-labels-icon pastel-orange'></div>
											<input className='create-labels-input' placeholder='Add label name...' onKeyPress={(e) => {if(e.key === 'Enter') updateLabel(e, label.label_id)}} autoFocus={true}/>
											<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => removeLabel(label.label_id)}/>
											<FontAwesomeIcon className='gray-to-blue-link' icon={faCheck} onClick={(e) => updateLabel(e, label.label_id)}/>
										</div>
									)
								}
							</div>
						))}
					</div>
				</div>
				<div id='create-labels-add-sections-container'>
					<h1>Sections with Teams/Labels</h1>
					{isLoading ? 
						(
							<div className='section-creation-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'40px'}}/>
							</div>
						) :
						(
							<>
							<div id='create-labels-choose-department-dropdown'>
								<Dropdown data={departmentOptions} currentValue={selectedDepartmentID} handleChange={(new_value) => setSelectedDepartmentID(new_value)} />
							</div>
							<div id='create-labels-sections-container'>
								{department_sections_with_labels.map((department_info, index) => {
									const department_id = department_info.department_id;
									const department = departments.find(department => department.department_id === department_id).department;
									
									const department_sections = department_info.sections;
									
									return (
										<div key={index}>
											<h1 className='create-labels-department-heading'>{department}</h1>
											<div className='create-labels-sections-inner-container'>
												{department_sections.map((section_info, i) => {
													let section_id = section_info.section_id;
													
													// Check if this is subsection
													const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_id);
													if(subsection_index !== -1)
													{
														const subsection_info = subsections[subsection_index];

														section_id = subsection_info.section_id;
														section_info = sections.find(section => section.section_id === section_id);
													}
													
													// Skip if this section has already been seen
													if(logged_sections.includes(section_id)) return null;
													logged_sections.push(section_id);
													
													const section_subsections = section_info.subsections;
													
													const section_course_id = section_info.course_id;
													const section_course_info = courses.find(course => course.course_id === section_course_id);
													const section_course_name = (section_course_info) ? capitalizeFirstLetters(section_course_info.name) : null;
													const section_course_code = (section_course_info) ? section_course_info.course_code : null;
													const section_semester = (section_course_info) ? section_course_info.semester : null;
														
													const section_teacher_id = section_info.teacher_id;
													const section_teacher_info = teachers.find(teacher => teacher.teacher_id === section_teacher_id);
													const section_teacher_name = (section_teacher_info) ? `${section_teacher_info.name}, ${section_teacher_info.first_name}` : null;
													
													let section_all_labels = section_info.labels;
													section_all_labels = section_all_labels.map(label_id => {
														const label_info = labels.find(label => label && ('label_id' in label) && label.label_id === label_id);
														
														return label_info;
													});
													
													const current_section_teams = section_all_labels.filter(label => label && ('is_team' in label) && label.is_team === '1');
													const sorted_current_section_teams = sortArrayOfObjects(current_section_teams, 'label', 'text', 'asc');
													
													const current_section_labels = section_all_labels.filter(label => label && ('is_team' in label) && label.is_team === '0');
													const sorted_current_section_labels = sortArrayOfObjects(current_section_labels, 'label', 'text', 'asc');
													
													// Add open toggle status if section doesn't have it already
													if(!('label_options_open' in section_info)) section_info['label_options_open'] = false;
													
													return (
														<div className='subsection-review-section-container' key={section_id} data-sectionid={section_id}>
															<div className='subsection-review-section-header dark-blue'>
																<div>Section ({section_semester})</div>
															</div>
															<div className='subsection-review-section-content'>
																<div className='subsection-review-edit-label'>Course</div>
																<div className='subsection-review-data tooltip' data-tooltip-text={`${section_course_name} (${section_course_code})`}>
																	<div className='ellipsis'>{section_course_name} ({section_course_code})</div>
																</div>
																<div className='subsection-review-edit-label'>Teacher</div>
																<div className='subsection-review-data tooltip' data-tooltip-text={`${section_teacher_name}`}>
																	<div className='ellipsis'>{section_teacher_name}</div>
																</div>
																{sorted_current_section_teams.map((label, index) => (
																	<div className='create-labels-section-label' key={label.label_id}>
																		<div className='create-labels-icon pastel-blue'></div>
																		<div>{label.label}</div>
																		<FontAwesomeIcon className='cancel-link' icon={faTimes} onClick={() => removeSectionLabel(section_id, label.label_id)}/> 
																	</div>
																))}
																{sorted_current_section_labels.map((label, index) => (
																	<div className='create-labels-section-label' key={label.label_id}>
																		<div className='create-labels-icon pastel-orange'></div>
																		<div>{label.label}</div>
																		<FontAwesomeIcon className='cancel-link' icon={faTimes} onClick={() => removeSectionLabel(section_id, label.label_id)}/> 
																	</div>
																))}
																<div className='add-label-to-section orange-link small-text click-restricted' onClick={() => toggleLabelOptionsOpen(section_id)}>Add Team/Label</div>
																<div className='create-labels-label-options-container'>
																	{section_info.label_options_open &&
																		<div className='data-options-container' ref={ref}>
																			<div className='data-options-heading'>Teams</div>
																			{sorted_teams.length === 0 &&
																				<div className='create-labels-no-label-options'>No teams added yet...</div>
																			}
																			{sorted_teams.map((label, index) => (
																				<div className='data-options-option create-labels-label-option' key={index} onClick={() => addSectionLabel(section_id, label.label_id)}>
																					<div className='create-labels-icon pastel-blue'></div>
																					<div>{label.label}</div>
																				</div>
																			))}
																			<div className='data-options-heading'>Labels</div>
																			{sorted_labels.length === 0 &&
																				<div className='create-labels-no-label-options'>No labels added yet...</div>
																			}
																			{sorted_labels.map((label, index) => (
																				<div className='data-options-option create-labels-label-option' key={index} onClick={() => addSectionLabel(section_id, label.label_id)}>
																					<div className='create-labels-icon pastel-orange'></div>
																					<div>{label.label}</div>
																				</div>
																			))}
																		</div>
																	}
																</div>
																{section_subsections.map((subsection_id, i) => {
																	// SUBSECTION INFO
																	const subsection_info = subsections.find(section => section.subsection_id === subsection_id);
																	
																	const subsection_section_info = sections.find(section => section.section_id === subsection_id);
																	
																	const subsection_course_id = subsection_info.course_id;
																	const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
																	const subsection_course_name = (subsection_course_id) ? capitalizeFirstLetters(subsection_course_info.name) : null;
																	const subsection_course_code = (subsection_course_id) ? subsection_course_info.course_code : null;
																	
																	const subsection_teacher_id = subsection_info.teacher_id;
																	const subsection_teacher_info = teachers.find(teacher => teacher.teacher_id === subsection_teacher_id);
																	const subsection_teacher_name = (subsection_teacher_id) ? `${subsection_teacher_info.name}, ${subsection_teacher_info.first_name}` : null;

																	const subsection_is_inclusion = subsection_section_info.is_inclusion;
																	let is_placeholder_inclusion = false;
																	if(scheduleInfo.separate_ICR_codes === '0' && section_course_id === subsection_course_id && subsection_is_inclusion === '1') is_placeholder_inclusion = true;
																	
																	let subsection_all_labels = subsection_section_info.labels;
																	subsection_all_labels = subsection_all_labels.map(label_id => {
																		const label_info = labels.find(label => label.label_id === label_id);
																		
																		return label_info;
																	});
																	
																	const current_subsection_teams = subsection_all_labels.filter(label => label.is_team === '1');
																	const sorted_current_subsection_teams = sortArrayOfObjects(current_subsection_teams, 'label', 'text', 'asc');
																	
																	const current_subsection_labels = subsection_all_labels.filter(label => label.is_team === '0');
																	const sorted_current_subsection_labels = sortArrayOfObjects(current_subsection_labels, 'label', 'text', 'asc');
																	
																	// Add open toggle status if section doesn't have it already
																	if(!('label_options_open' in subsection_info)) subsection_info['label_options_open'] = false;
																	
																	return (
																		<div className='subsection-review-section-container' key={subsection_id} data-sectionid={subsection_id}>
																			<div className={`subsection-review-section-header ${is_placeholder_inclusion ? 'yellow' : 'blue'}`}>
																				<div>{is_placeholder_inclusion && 'Inclusion'} Subsection</div>
																			</div>
																			<div className='subsection-review-section-content'>
																				{!is_placeholder_inclusion &&
																					<>
																					<div className='subsection-review-edit-label'>Course</div>
																					<div className='subsection-review-data tooltip' data-tooltip-text={`${subsection_course_name} (${subsection_course_code})`}>
																						<div className='ellipsis'>{subsection_course_name} ({subsection_course_code})</div>
																					</div>
																					</>
																				}
																				<div className='subsection-review-edit-label'>Teacher</div>
																				<div className='subsection-review-data tooltip' data-tooltip-text={`${subsection_teacher_name}`}>
																					<div className='ellipsis'>{subsection_teacher_name}</div>
																				</div>
																				{sorted_current_subsection_teams.map((label, index) => (
																					<div className='create-labels-section-label' key={label.label_id}>
																						<div className='create-labels-icon pastel-blue'></div>
																						<div>{label.label}</div>
																						<FontAwesomeIcon className='cancel-link' icon={faTimes} onClick={() => removeSectionLabel(subsection_id, label.label_id)}/> 
																					</div>
																				))}
																				{sorted_current_subsection_labels.map((label, index) => (
																					<div className='create-labels-section-label' key={label.label_id}>
																						<div className='create-labels-icon pastel-orange'></div>
																						<div>{label.label}</div>
																						{(!is_placeholder_inclusion || label.label !== 'Inclusion') &&
																							<FontAwesomeIcon className='cancel-link' icon={faTimes} onClick={() => removeSectionLabel(subsection_id, label.label_id)}/>
																						} 
																					</div>
																				))}
																				<div className='add-label-to-section orange-link small-text click-restricted' onClick={() => toggleLabelOptionsOpen(subsection_id)}>Add Team/Label</div>
																				<div className='create-labels-label-options-container'>
																					{subsection_section_info.label_options_open &&
																						<div className='data-options-container' ref={ref}>
																							<div className='data-options-heading'>Teams</div>
																							{sorted_teams.length === 0 &&
																								<div className='create-labels-no-label-options'>No teams added yet!</div>
																							}
																							{sorted_teams.map((label, index) => (
																								<div className='data-options-option create-labels-label-option' key={index} onClick={() => addSectionLabel(subsection_id, label.label_id)}>
																									<div className='create-labels-icon pastel-blue'></div>
																									<div>{label.label}</div>
																								</div>
																							))}
																							<div className='data-options-heading'>Labels</div>
																							{sorted_labels.length === 0 &&
																								<div className='create-labels-no-label-options'>No labels added yet!</div>
																							}
																							{sorted_labels.map((label, index) => (
																								<div className='data-options-option create-labels-label-option' key={index} onClick={() => addSectionLabel(subsection_id, label.label_id)}>
																									<div className='create-labels-icon pastel-orange'></div>
																									<div>{label.label}</div>
																								</div>
																							))}
																						</div>
																					}
																				</div>
																			</div>
																		</div>
																	)
																})}
															</div>
														</div>
													)
												})}
											</div>
										</div>
									)
								})}
							</div>
							<div className='blue-link' onClick={toggleAddNewSection}>Add Label to Existing Section</div>
							{showAddNewSection &&
								<div id='subsection-review-search' className='subsection-review-search-sections-container'>
									<div className='subsection-review-search-header'><img src={require('../../../images/search_section.png')} alt='search section' style={{height:'50px'}}/>Search For Sections</div>
									<p>Search to add a subsection to an existing section</p>
									<div className='subsection-review-close-search' onClick={toggleAddNewSection}><FontAwesomeIcon className='subsection-review-close-search-icon' icon={faTimes}/></div>
									<input className='subsection-review-search-bar' placeholder='Search through existing sections...' onChange={(e) => fuzzySearch(e, 'sections')} autoFocus={true}/>
									{sectionSearchResults.length !== 0 &&
										<div className='subsection-review-search-results'>
											{sectionSearchResults.map((section_info, i) => {
												let section_id = section_info.section_id;
													
												// Check if this is subsection
												const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_id);
												if(subsection_index !== -1)
												{
													const subsection_info = subsections[subsection_index];
													
													section_id = subsection_info.section_id;
													section_info = sections.find(section => section.section_id === section_id);
												}
												
												// Skip if this section has already been seen
												if(logged_search_result_sections.includes(section_id)) return null;
												logged_search_result_sections.push(section_id);
												
												const section_course_id = section_info.course_id;
												const section_course_info = courses.find(course => course.course_id === section_course_id);
												const section_course_name = (section_course_id) ? capitalizeFirstLetters(section_course_info.name) : null;
												const section_course_code = (section_course_id) ? section_course_info.course_code : null;
												const section_number = ('section_number' in section_info) ? section_info.section_number : null;
												
												const section_teacher_id = section_info.teacher_id;
												const section_teacher_info = teachers.find(teacher => teacher.teacher_id === section_teacher_id);
												const section_teacher_name = (section_teacher_id) ? `${section_teacher_info.name}, ${section_teacher_info.first_name}` : null;
												const section_secondary_teachers = section_info.secondary_teachers;
												
												const section_subsections = section_info.subsections;
												let section_all_labels = section_info.labels;
													
												section_all_labels = section_all_labels.map(label_id => {
													const label_info = labels.find(label => label.label_id === label_id);
													
													return label_info;
												});
												
												const current_section_teams = section_all_labels.filter(label => label.is_team === '1');
												const sorted_current_section_teams = sortArrayOfObjects(current_section_teams, 'label', 'text', 'asc');
												
												const current_section_labels = section_all_labels.filter(label => label.is_team === '0');
												const sorted_current_section_labels = sortArrayOfObjects(current_section_labels, 'label', 'text', 'asc');
												
												return (
													<div className='subsection-review-section-container' key={i} data-ref={section_id}>
														<div className='subsection-review-section-header dark-blue'>
															<div>Section ID: {section_number}</div>
														</div>
														<div className='subsection-review-section-content'>
															<div className='subsection-review-edit-label'>Course</div>
															<div className='subsection-review-data tooltip' data-tooltip-text={`${section_course_name} (${section_course_code})`}>
																<div className='ellipsis'>{section_course_name} ({section_course_code})</div>
															</div>
															<div className='subsection-review-edit-label'>Teacher</div>
															<div className='subsection-review-data tooltip' data-tooltip-text={`${section_teacher_name}`}>
																<div className='ellipsis'>{section_teacher_name}</div>
															</div>
															{section_secondary_teachers.map((secondary_teacher_id, i) => {
																const secondary_teacher_info = teachers.find(teacher => teacher.teacher_id === secondary_teacher_id);
																const secondary_teacher_name = (secondary_teacher_info) ? `${secondary_teacher_info.name}, ${secondary_teacher_info.first_name}` : null;

																return (
																	<div className='subsection-review-data tooltip' data-tooltip-text={`${secondary_teacher_name}`} key={i}>
																		<div className='ellipsis'>{secondary_teacher_name}</div>
																	</div>
																)
															})}
															{sorted_current_section_teams.map((label, index) => (
																<div className='create-labels-section-label' key={label.label_id}>
																	<div className='create-labels-icon pastel-blue'></div>
																	<div>{label.label}</div>
																</div>
															))}
															{sorted_current_section_labels.map((label, index) => (
																<div className='create-labels-section-label' key={label.label_id}>
																	<div className='create-labels-icon pastel-orange'></div>
																	<div>{label.label}</div>
																</div>
															))}
															{section_subsections.map((subsection_id, i) => {
																// SUBSECTION INFO
																const subsection_info = subsections.find(section => section.subsection_id === subsection_id);
																
																const subsection_section_info = sections.find(section => section.section_id === subsection_id);
																
																const subsection_course_id = subsection_info.course_id;
																const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
																const subsection_course_name = (subsection_course_id) ? capitalizeFirstLetters(subsection_course_info.name) : null;
																const subsection_course_code = (subsection_course_id) ? subsection_course_info.course_code : null;
																
																const subsection_teacher_id = subsection_info.teacher_id;
																const subsection_teacher_info = teachers.find(teacher => teacher.teacher_id === subsection_teacher_id);
																const subsection_teacher_name = (subsection_teacher_id) ? `${subsection_teacher_info.name}, ${subsection_teacher_info.first_name}` : null;
																const subsection_secondary_teachers = section_info.secondary_teachers;

																const subsection_is_inclusion = subsection_section_info.is_inclusion;
																let is_placeholder_inclusion = false;
																if(scheduleInfo.separate_ICR_codes === '0' && section_course_id === subsection_course_id && subsection_is_inclusion === '1') is_placeholder_inclusion = true;
																
																let subsection_all_labels = subsection_section_info.labels;
																subsection_all_labels = subsection_all_labels.map(label_id => {
																	const label_info = labels.find(label => label.label_id === label_id);
																	
																	return label_info;
																});
																
																const current_subsection_teams = subsection_all_labels.filter(label => label.is_team === '1');
																const sorted_current_subsection_teams = sortArrayOfObjects(current_subsection_teams, 'label', 'text', 'asc');
																
																const current_subsection_labels = subsection_all_labels.filter(label => label.is_team === '0');
																const sorted_current_subsection_labels = sortArrayOfObjects(current_subsection_labels, 'label', 'text', 'asc');
																
																return (
																	<div className='subsection-review-section-container' key={i}>
																		<div className={`subsection-review-section-header ${is_placeholder_inclusion ? 'yellow' : 'blue'}`}>
																			<div>{is_placeholder_inclusion && 'Inclusion'} Subsection</div>
																		</div>
																		<div className='subsection-review-section-content'>
																			{!is_placeholder_inclusion &&
																				<>
																				<div className='subsection-review-edit-label'>Course</div>
																				<div className='subsection-review-data tooltip' data-tooltip-text={`${subsection_course_name} (${subsection_course_code})`}>
																					<div className='ellipsis'>{subsection_course_name} ({subsection_course_code})</div>
																				</div>
																				</>
																			}
																			<div className='subsection-review-edit-label'>Teacher</div>
																			<div className='subsection-review-data tooltip' data-tooltip-text={`${subsection_teacher_name}`}>
																				<div className='ellipsis'>{subsection_teacher_name}</div>
																			</div>
																			{subsection_secondary_teachers.map((secondary_teacher_id, i) => {
																				const secondary_teacher_info = teachers.find(teacher => teacher.teacher_id === secondary_teacher_id);
																				const secondary_teacher_name = (secondary_teacher_info) ? `${secondary_teacher_info.name}, ${secondary_teacher_info.first_name}` : null;

																				return (
																					<div className='subsection-review-data tooltip' data-tooltip-text={`${secondary_teacher_name}`} key={i}>
																						<div className='ellipsis'>{secondary_teacher_name}</div>
																					</div>
																				)
																			})}
																			{sorted_current_subsection_teams.map((label, index) => (
																				<div className='create-labels-section-label' key={label.label_id}>
																					<div className='create-labels-icon pastel-blue'></div>
																					<div>{label.label}</div>
																				</div>
																			))}
																			{sorted_current_subsection_labels.map((label, index) => (
																				<div className='create-labels-section-label' key={label.label_id}>
																					<div className='create-labels-icon pastel-orange'></div>
																					<div>{label.label}</div>
																				</div>
																			))}
																		</div>
																	</div>
																)
															})}
															<div className='btn blue-btn subsection-review-select-section' onClick={() => selectSection(section_id)}>Select</div>
														</div>
													</div>
												)
											})}
										</div>
									}
								</div>
							}
							</>
						)
					}
				</div>
			</div>
		):(userInfo && !current_user_is_admin) ?
		(
			<div className='locked-screen-notification-container'>
				<FontAwesomeIcon className='blue-text' icon={faLock} style={{fontSize:'50px'}}/>
				<h1>Locked</h1>
				<p>This step is only accessible to the master scheduler. You should be notified when future steps are unlocked!</p>
				<Link to='/progress' className='blue-text'>Go Back</Link>
			</div>
		): null}
		{(!subscreenLocked && !subscreenRestricted && current_user_is_admin) &&
			<div className='fixed-bottom-save-container'>
				<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Go to Next Step: Student Labels' onClickFunction={handleMoveToNextScreen} />
			</div>
		}
		</>
	);
}